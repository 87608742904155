import * as request from 'superagent'
import { getApi } from '../utils/api/Api'
import { ParserParams } from './api-constants'
import { getAuthorizationHeaders } from './auth-api'

export const getParserParameter = async (bank?: string, subtype?: string) => {
  return request
    .get(getApi(ParserParams.getParserParams))
    .query({ bank: bank })
    .query({ subtype: subtype })
    .set(await getAuthorizationHeaders())

    .then(response => {
      const { data } = response.body
      return data
    })
    .catch(e => {
      throw e
    })
}
