import { getApi } from '../utils/api/Api'
import {cleanInputFile} from '../utils/FileUtils'
import { tkheaders } from './api-constants'
import { getAuthorizationHeaders } from './auth-api'

const axios = require('axios')
const { toaster } = require('evergreen-ui')
export const tkheadersBoundsCore = async (
  bank_name: string,
  config_input: any,
  input_pdf: any,
  page: string,
  bound_len: string,
  scale_factor: string,
  split_point: string,
  bounds: any,
  header_index: string,
  header_origin: any,
  params: any,
  include_logs?:boolean
) => {
  var bodyFormData = new FormData()

  bodyFormData.append('bank_name', bank_name)
  bodyFormData.append('page', page)
  bodyFormData.append('bounds_len', bound_len)
  bodyFormData.append('scale_factor', scale_factor)
  bodyFormData.append('split_point', split_point)
  bodyFormData.append('bounds', JSON.stringify(bounds))
  bodyFormData.append('header_index', header_index)
  bodyFormData.append('header_origin', JSON.stringify(header_origin))
  bodyFormData.append('config_input', config_input)
  bodyFormData.append('input_pdf', input_pdf)
  if(Object.keys(params).length > 0) {
    bodyFormData.append('params', JSON.stringify(params))
  }
  if (include_logs){
    bodyFormData.append('include_logs', JSON.stringify(include_logs))
  }

  return axios({
    method: 'post',
    url: getApi(tkheaders.headerBounds),
    headers: {
      'Content-Type': 'multipart/form-data',
      ...await getAuthorizationHeaders()
    },
    data: bodyFormData,
    responseType: 'blob',
  })
    .then((res: any) => {
      var fileName = 'TkHeader'
      const file = decodeURI(res.headers['content-disposition'])
      const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
      const matches = filenameRegex.exec(file)
      if (matches != null && matches[1]) {
        fileName = matches[1].replace(/['"]/g, '')
      }
      const blob = new Blob([res.data], { type: res.headers['content-type'] })
      return { fileBlob: blob, fileName: fileName }
    })
    .catch((e: any) => {
      const error =
        e.response && e.response.data ? JSON.parse(e.response.data) : 'Unable to download file'
      toaster.danger(error['error'])
    })
}

export const tkheadersBoundsDownload = async (
  bank_name: string,
  config_input: any,
  input_pdf: any,
  page: string,
  bound_len: string,
  scale_factor: string,
  split_point: string,
  bounds: any,
  header_index: string,
  header_origin: any,
  params: any,
  include_logs?:boolean
) => {
  return tkheadersBoundsCore(bank_name, config_input, input_pdf, page, bound_len, scale_factor,split_point, bounds, header_index, header_origin, params, include_logs).then((fileInfo) => {
    const fileBlob = fileInfo.fileBlob
    const fileName = fileInfo.fileName
    const link = document.createElement('a')
    link.href = window.URL.createObjectURL(fileBlob)
    link.download = cleanInputFile(fileName)
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
    return true
  })

}

axios.interceptors.response.use(
  (response: any) => {
    return response
  },
  (error: any) => {
    if (
      error.request.responseType === 'blob' &&
      error.response.data instanceof Blob &&
      error.response.data.type &&
      error.response.data.type.toLowerCase().indexOf('json') != -1
    ) {
      return new Promise((resolve, reject) => {
        let reader = new FileReader()
        reader.onload = () => {
          error.response.data = reader.result
          resolve(Promise.reject(error))
        }

        reader.onerror = () => {
          reject(error)
        }

        reader.readAsText(error.response.data)
      })
    }

    return Promise.reject(error)
  },
)
