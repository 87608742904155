import { combineReducers } from 'redux'
import { onCheckReducer } from '../components/FileItem/FileItemReducer'
import { onChangeReducer } from '../components/ParserParameter/ParserParameterRedux'
import { createNewBorder } from '../components/NewConfig/NewConfigRedux'
const allReducers = combineReducers({
  onCheckReducer: onCheckReducer,
  onChangeReducer: onChangeReducer,
  createNewBorder: createNewBorder
})
export default allReducers
