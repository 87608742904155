import { navigate } from '@reach/router'
import * as request from 'superagent'
import { getApi } from '../utils/api/Api'
import { parserLog } from './api-constants'
import { getAuthorizationHeaders } from './auth-api'
const { toaster } = require('evergreen-ui')

export const parserGetLog = async (taskId: string) => {
  return request
    .post(getApi(parserLog.getLog))
    .set(await getAuthorizationHeaders())
    .field('task_id', taskId)
    .then(response => {
      return response.text
    })
    .catch(e => {
      const error =
        e.response && e.response.body && e.response.body.error
          ? e.response.body.error
          : 'Unknown Error'
      toaster.danger(error)
      const status = e.response && e.response.status ? e.response.status : e.response.statusCode
      if (status === 401) {
        navigate('/login')
      }
    })
}
