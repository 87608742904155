import * as React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
const { Button, Heading} = require('evergreen-ui')
import TaskItem from './TaskItem';
import styled from 'styled-components'
import ResponseItem from './ResponseItem'
import gql from 'graphql-tag'
import { Mutation } from 'react-apollo'
import { colors} from '../../utils/\/colors'

class DisplayCancelTask extends React.Component<any> {
  public state = {
    taskResponseData: [],
    isLoading: false,
    count:0
  }

  private displayTable = () => {
     return this.props.bulkData.map((item: any)=> {
          return <TaskItem item={item }/>
        
      })
  }

  private displayResponse = () => {
    return this.state.taskResponseData.map((item: any)=> {
      return <ResponseItem item= {item}/>
    })
  }

  private responseHeader = () => {
    return(
      <Header>
        <div className="fileName">File Name</div>
        <div className="status">Status</div> 
        <div>Message</div>
      </Header>
    )
  }
  
  private dispplayCancelTAsk = () => {
    var cancelResponseData: any [] =[]
    var count = 0
    return (
         <>
        <Mutation mutation={CancelTASKJOB}>
        {(cancelTask, { data }) => (
           <Button appearance="primary" isLoading={this.state.isLoading} onClick={() =>{
            this.setState({
              isLoading: true
            })
            for(let  item of this.props.bulkData) {
              cancelTask( {variables: { fileUuid: item.fileUuid}} )
              .then((result: any)=> {
                count = count +1
                this.setState({
                  count: count
                })
                const temp = {
                  inputFile: item.inputFile,
                  originalFile: item.originalFile,
                  success: result.data.cancelTask.success,
                  message: result.data.cancelTask.message

                }
                cancelResponseData.push(temp)
                this.setState({
                  taskResponseData : cancelResponseData
                })
               
              })
            }
            this.setState({
              isLoading: false
            })
             }}>
           Cancel Jobs
         </Button>
          )} 
        </Mutation>
      </>
  
    )
  }

private displayTableHeader() {
  return(
    <Header>
      <div className="fileName">File Name</div>
      <div>Status</div> 
    </Header>
  )

}
private displyResponseDataTable() {
  if(this.state.taskResponseData.length > 0) {
    return(
      <>
      {this.responseHeader()}
      {this.displayResponse()}
      </>
    )

  } else if(this.props.bulkData.length > 0){
    return(
      <>
      {this.displayTableHeader()}
      {this.displayTable()}
      {this.dispplayCancelTAsk()}
      </>
    )
  } else{
    return null
  }
}
  public render() {
    const totalCount = this.props.bulkData.length
    return(
      <Container>
      <div className="cardHeader">
      <Heading size={700} color={colors.blue}className="title">Cancel Task List</Heading>
      <Heading size={700} color={colors.blue} className="count" marginBottom={10}>
        <span>Completed:</span>{this.state.count} of {totalCount}
        </Heading>
        </div>
      { this.displyResponseDataTable()}
     
      </Container>
    ) 
  }
 }



const mapStateToProps = (storeData: any) => {
  return {
    bulkData: storeData.onCheckReducer.bulkOperationData,
  }
}

const CancelTASKJOB = gql`
  mutation CancelMutation($fileUuid: String!) {
    cancelTask(fileUuid: $fileUuid) {
      success
      message
    }
  }
`

const Container = styled.div`
box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.1);
border-radius: 5px;
padding: 20px;
background-color: white;
.cardHeader{
  display: flex
  border-bottom: 2px solid #F5F5F5;
  margin-bottom: 10px;
  .title{
    width: 80%;
  }
  .count{
    span{
      padding-right: 10px;
    }
  }
}


`

const Header = styled.div`
display: flex;
background-color: #F5F5F5;
box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.1);
border-radius: 5px;
padding: 20px;
margin-bottom: 10px;
font-weight: bold;
.fileName{
    width: 50%;
}
.status{
  width: 10%;
}
.
`

export default connect(mapStateToProps, null)(DisplayCancelTask)