import * as React from 'react'
const { Heading } = require('evergreen-ui')
import EditExistingBorder from './EditExistingBorder'
import CreateNewBoder from './CreateNewBorder'
import styled from 'styled-components'

class Menu extends React.Component {
  state = {
    toggleView: true,
  }

  private display = () => {
    if (this.state.toggleView) {
      return <CreateNewBoder />
    }
    return <EditExistingBorder />
  }
  public render() {
    return (
      <React.Fragment>
        <Container>
          <section className="menu">
            <Heading
              is={'h1'}
              size={600}
              color={'#116AB8'}
              onClick={() => {
                this.setState({
                  toggleView: true,
                })
              }}
            >
              Create New Border
            </Heading>
          </section>

          <section className="menu">
            <Heading
              is={'h1'}
              size={600}
              color={'#116AB8'}
              onClick={() => {
                this.setState({
                  toggleView: false,
                })
              }}
            >
              Edit Existing Border
            </Heading>
          </section>
        </Container>
        {this.display()}
      </React.Fragment>
    )
  }
}

export default Menu
const Container = styled.div`
   {
    display: flex;
    width: 90%;
    justify-content: center;
    .menu {
      margin-right: 5%;
      cursor: pointer;
    }
    .menu::after {
      content: '';
      display: block;
      width: 0;
      height: 2px;
      background: #4caf50;
      transition: width 0.3s;
    }
    .menu:hover::after {
      width: 100%;
      //transition: width .3s;
    }
  }
`
