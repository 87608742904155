import { getApi } from '../utils/api/Api'
import { AnonymisePdf } from './api-constants'
import { getAuthorizationHeaders } from './auth-api'

const axios = require('axios')

export const downloadAnonymisePdf = async (
  input_pdf: File | undefined,
  text_to_remove: any,
  replace_with: any,
  remove_images: any,
  use_ocr: any,
  case_sensitive: any,
  use_regex: any,
  ocr_languages: any,
  pages: any,
) => {
  var bodyFormData = new FormData()
  bodyFormData.append('text_to_remove', JSON.stringify(text_to_remove))
  bodyFormData.append('replace_with', JSON.stringify(replace_with))
  bodyFormData.append('pages', pages)
  bodyFormData.append('remove_images', remove_images)
  bodyFormData.append('use_ocr', use_ocr)
  bodyFormData.append('case_sensitive', case_sensitive)
  bodyFormData.append('use_regex', use_regex)
  if (ocr_languages > 0) {
    bodyFormData.append('ocr_languages', JSON.stringify(ocr_languages))
  }
  bodyFormData.append('input_pdf', input_pdf as Blob)
  return axios({
    method: 'post',
    url: getApi(AnonymisePdf.downloadAnonymisePdf),
    headers: {
      'Content-Type': 'multipart/form-data',
      ...await getAuthorizationHeaders()
    },
    data: bodyFormData,
  })
    .then((res: any) => {
      return res.data
    })
    .catch((e: any) => {
      throw e
    })
}

export const checkAnonymisePdfStatus = async (
  request_id: any,
  ) => {
    return axios({
      method: 'get',
      url: getApi(`${AnonymisePdf.downloadAnonymisePdf}?request_id=${request_id}`),
      headers: await getAuthorizationHeaders(),
    })
    .then((res: any) => {
      return res.data
    })
    .catch((e: any) => {
      throw e
    })
  }


