import * as React from 'react'
import { RouteComponentProps } from '@reach/router'
import styled from 'styled-components'
import WithAuthorization from '../../components/WithAuthorization/WithAuthorization'
import AllFiles from '../../components/AllFiles/AllFiles'
import CustomCard from '../../components/CustomCard/CustomCard'
import BulkOperation from '../../components/BulkOperation/BulkOperation'
import SearchHeader from '../../components/SearchHeader/SearchHeader'
import BankSearchContainer from '../../components/BankSearchContainer/BankSearchContainer'
interface IProps extends RouteComponentProps {
  bankCode: string
}

class BankData extends React.Component<IProps> {
  public render() {
    const { bankCode } = this.props
    return (
      <main>
        <CustomCard type={'allFiles'}>
          <CardHeader>
            <BankSearchContainer bankCode={bankCode} />
            <BulkOperation />
          </CardHeader>
          <AllFiles bankCode={bankCode} />
        </CustomCard>
      </main>
    )
  }
}

const CardHeader = styled.section`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 24px;
`

export default WithAuthorization(BankData, SearchHeader)
