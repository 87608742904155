import * as React from 'react'
import styled from 'styled-components'
import { Table, SelectField, toaster } from 'evergreen-ui'
import JSZip from 'jszip'
import * as XLSX from 'xlsx';
import NewWindow from 'react-new-window'
import TkHeadersLog from '../tkheader/tkHeadersLog'

export interface IPropsDetail {
    popupTitle: string
    popupName: string
    zipResponse: Blob | null
    zipResponseTimestamp: Date | null
    configFilenameRegex: string | null
    extractFilenameRegex: string | null
    logFilenameRegex: string | null
    onUnloadPopup: () => void
    inputHeaders: [] | null
    selectedConfigSheet?: string | null
}

interface IProps {
    params: IPropsDetail | null
}

interface IState {
    zipResponseTimestamp: Date | null
    extratedDataWorkbook: XLSX.WorkBook | null
    selectedWorksheetName: string | null
    tableCoordinates: Record<string, ICoordinates> | null
    statementColumns: Set<string> | null
    logs: string | null
}

const initialState: IState = {
    zipResponseTimestamp: null,
    extratedDataWorkbook: null,
    selectedWorksheetName: null,
    tableCoordinates: null,
    statementColumns: null,
    logs: null
}

interface ICoordinates {
    border1: any[]
    border2: number[]
}



export class PreviewExtraction extends React.Component<IProps, IState> {
    public state = initialState

    private onBlockPopup = () => {
        toaster.warning("Unable to open popup, Please check popup settings in browser.")

    }

    private onSelectSheet = (e: any) => {
        const newVal = e.target.value
        this.setState(previousState => ({
            ...previousState,
            selectedWorksheetName: newVal
        }))
    }

    private getTableCoordinates(worksheet: XLSX.WorkSheet | null, tablenames: Set<string>) {
        const tableCoordinateDict: Record<string, ICoordinates> = {}
        if (worksheet) {
            const worksheetJson = XLSX.utils.sheet_to_json(worksheet)
            if (worksheetJson.length > 0) {
                const rowList: any[] = Object.values(worksheetJson)
                let indexBorderStart: number = -1
                let indexHeaderEnd: number = -1
                let indexStatementStart: number = -1
                let indexStatementEnd: number = -1
                let currentTablename: string | null = null
                rowList.forEach((row: any, rindex) => {
                    const columnList = Object.values(row)
                    if (columnList.length > 0) {
                        const col: any = columnList[0]
                        if (tablenames.has(col) && !currentTablename) {
                            currentTablename = col
                        } else if (col == "border start") {
                            indexBorderStart = rindex
                        } else if (col == "header end") {
                            indexHeaderEnd = rindex
                            if (currentTablename) {
                                const coordinates: ICoordinates = { border1: [], border2: [] }
                                tableCoordinateDict[currentTablename] = coordinates
                                currentTablename = null
                                const gap = indexHeaderEnd - indexBorderStart
                                if (gap == 3) {
                                    Object.values(rowList[indexBorderStart + 1]).forEach((border: any) => {
                                        coordinates.border1.push(border)
                                    })
                                    Object.values(rowList[indexBorderStart + 2]).forEach((border: any) => {
                                        coordinates.border2.push(border)
                                    })
                                } else if (gap == 2) {
                                    Object.values(rowList[indexBorderStart + 1]).forEach((border: any) => {
                                        coordinates.border1.push(null)
                                        coordinates.border2.push(border)
                                    })
                                }
                            }
                        } else if (col == "statement level data start") {
                            indexStatementStart = rindex

                        } else if (col == "statement level data end") {
                            indexStatementEnd = rindex
                            if (indexStatementStart > -1 && (indexStatementStart + 1 < indexStatementEnd)) {
                                const statementColumns: string[] = []
                                Object.values(rowList[indexStatementStart + 1]).forEach((scol: any) => {
                                    const scolVal = scol.trim();
                                    if (scolVal) {
                                        statementColumns.push(scolVal)
                                    }
                                })
                                if (statementColumns.length > 0) {
                                    this.setState(previousState => ({
                                        ...previousState,
                                        statementColumns: new Set(statementColumns)
                                    }))
                                }
                            }
                        }
                    }
                })
            }
        }
        return tableCoordinateDict
    }

    // private getArrayInnerLength(arrayObj: any[] | null) {
    //     let arrayLength = 0
    //     if (arrayObj && arrayObj.length > 0) {
    //         arrayLength = arrayObj.length
    //         const firstItem = arrayObj[0]
    //         if (Array.isArray(firstItem)) {
    //             arrayLength = this.getArrayInnerLength(firstItem)
    //         }
    //     }
    //     return arrayLength
    // }
    private filterOutAutoGeneratedHeaders(tableColumnNames: string[] | null) {
        if (tableColumnNames)
            return tableColumnNames.filter((col) => (!col.toUpperCase().startsWith("CNP_") &&
                (!this.state.statementColumns || !this.state.statementColumns.has(col))))
        return tableColumnNames
    }

    private getTableColumnNames(selectedWorksheet: XLSX.WorkSheet): string[] {
        const worksheetJson: string[][] = XLSX.utils.sheet_to_json(selectedWorksheet, { header: 1 })
        let tableColumnNames: string[] = []
        if (worksheetJson && worksheetJson.length > 0) {
            tableColumnNames = worksheetJson[0]
        }
        return tableColumnNames;
    }

    private displayTableSelector(tableNames: string[] | null) {
        if (tableNames && tableNames.length > 0) {
            return (
                <SelectField width="100%" label="Tables:" onChange={this.onSelectSheet} value={this.state.selectedWorksheetName || ""}>
                    {tableNames.map((item: any) => (
                        <option value={item}>{item}</option>
                    ))}
                </SelectField>
            )
        } else {
            return null
        }
    }

    private displayTableCoordinates(borderInfo: ICoordinates | null, tableColumnNames: string[] | null) {
        if ((borderInfo && borderInfo.border2.length > 0 && borderInfo.border2.some((b) => b)) &&
            (tableColumnNames && tableColumnNames.length > 0)) {
            const columnNames = this.filterOutAutoGeneratedHeaders(tableColumnNames)
            if (columnNames && columnNames.length > 0) {
                const endPosition = columnNames.length
                //borderInfo.border may have empty values at the end
                const border1 = borderInfo.border1.slice(0, endPosition)
                const border2 = borderInfo.border2.slice(0, endPosition)
                return (
                    <ContainerBorder>
                        <legend className={'legend'}>Coordinates</legend>
                        <Table style={{ width: '100%' }}>
                            {(columnNames.length == border1.length) &&
                                <Table.Head>
                                    {columnNames.map((columnName: string, i) => (
                                        <Table.TextHeaderCell textProps={{ fontWeight: 'bold' }} >{columnName}</Table.TextHeaderCell>
                                    ))}
                                </Table.Head>
                            }
                            <Table.Body>

                                <Table.Row height="auto" className='itemRow' >
                                    {border1.map((coordinate: any, i) => (
                                        <Table.TextCell  >{coordinate}</Table.TextCell>
                                    ))}
                                </Table.Row>
                                <Table.Row height="auto" className='itemRow' >
                                    {border2.map((coordinate: any, i) => (
                                        <Table.TextCell  >{coordinate}</Table.TextCell>
                                    ))}
                                </Table.Row>
                            </Table.Body>
                        </Table>
                    </ContainerBorder>
                )
            }
        } else {
            return (<ContainerBorder style={{ 'text-align': 'center' }} >
                <legend className={'legend'}>Coordinates</legend>
                <span className={'banner'}> No Data to Display</span></ContainerBorder>
            )
        }

    }

    private displayExtractedData(table: any, tableColumnNames: string[] | null) {
        if ((table && table.length > 0) && (tableColumnNames && tableColumnNames.length > 0)) {
            return (
                <ContainerBorder>
                    <legend className={'legend'}>Extracted data</legend>
                    <Table style={{ width: '100%' }}>
                        <Table.Head>
                            {tableColumnNames.map((columnName: string, i) => (
                                <Table.TextHeaderCell textProps={{ fontWeight: 'bold' }} >{columnName}</Table.TextHeaderCell>
                            ))}
                        </Table.Head>
                        <Table.Body>
                            {
                                table.map((row: any, i: number) => (
                                    <Table.Row height="auto" className='itemRow' >
                                        {tableColumnNames.map((key) => (
                                            <Table.TextCell className='itemCell' >{(row[key] || "")}</Table.TextCell>
                                        ))}
                                    </Table.Row>
                                ))}
                        </Table.Body>
                    </Table>
                </ContainerBorder>
            )
        } else {
            return (<ContainerBorder style={{ 'text-align': 'center' }} >
                <legend className={'legend'}>Extracted data</legend>
                <span className={'banner'}> No Data to Display</span></ContainerBorder>
            )
        }
    }

    private displayExtractionLog(executionLogTxt: string | null) {
        if (executionLogTxt) {
            return (
                <ContainerBorder>
                    <legend className={'legend'}>Extraction Log</legend>
                    <TkHeadersLog log={executionLogTxt}></TkHeadersLog>
                </ContainerBorder>
            )
        }
        else {
            return (<ContainerBorder style={{ 'text-align': 'center' }} >
                <legend className={'legend'}>Extracted Log</legend>
                <span className={'banner'}> No Data to Display</span>
            </ContainerBorder>
            )
        }
    }

    private displayPreview() {
        const { extratedDataWorkbook, selectedWorksheetName, tableCoordinates, logs } = this.state

        let worksheetNames: string[] | null = null
        let coordinates: ICoordinates | null = null
        let tableColumnNames: string[] | null = null
        let worksheetJson: unknown[] | null = null
        if (logs && logs.length > 0) {

            if (extratedDataWorkbook && selectedWorksheetName) {
                const selectedWorksheet = extratedDataWorkbook.Sheets[selectedWorksheetName]
                worksheetNames = extratedDataWorkbook.SheetNames
                worksheetJson = XLSX.utils.sheet_to_json(selectedWorksheet)
                tableColumnNames = this.getTableColumnNames(selectedWorksheet)

                if (tableCoordinates) {
                    coordinates = tableCoordinates[selectedWorksheetName]
                }
            }

            return (
                <FlexContainer style={{ background: 'white' }}>
                    <Table style={{ width: '100%' }}>
                        <Table.Body>
                            <Table.Row height="auto" className='sectionRow' >
                                <Table.Cell className='sectionCell' >
                                    {this.displayTableSelector(worksheetNames)}
                                </Table.Cell>
                            </Table.Row>
                            <Table.Row height="auto" className='sectionRow' >
                                <Table.Cell className='sectionCell' >
                                    {this.displayTableCoordinates(coordinates, tableColumnNames)}
                                </Table.Cell>
                            </Table.Row>
                            <Table.Row height="auto" className='sectionRow'>
                                <Table.Cell className='sectionCell'>
                                    {this.displayExtractedData(worksheetJson, tableColumnNames)}
                                </Table.Cell>
                            </Table.Row>
                            <Table.Row height="auto" className='sectionRow' >
                                <Table.Cell className='sectionCell'>
                                    {this.displayExtractionLog(logs)}
                                </Table.Cell>
                            </Table.Row>
                        </Table.Body>
                    </Table>
                </FlexContainer>)
        } else {
            return (
                // if we dont use <Table> and related tags,  style related to it will not be loaded in initial render of NewWindow
                <FlexContainer style={{ background: 'white' }}>
                    No Data to display
                    <Table style={{ display: 'None' }}>
                        <Table.Head>
                            <Table.TextHeaderCell textProps={{ fontWeight: 'bold' }} >
                                <SelectField lable="Tables: "> <option></option></SelectField>
                                <TkHeadersLog></TkHeadersLog><ContainerBorder></ContainerBorder>
                            </Table.TextHeaderCell>
                        </Table.Head>
                        <Table.Body>
                            <Table.Row height="auto" className='sectionRow'  >
                                <Table.Cell className='sectionCell' ></Table.Cell>
                                <Table.TextCell></Table.TextCell>
                            </Table.Row>
                        </Table.Body>
                    </Table>
                </FlexContainer>
                //<FlexContainer style={{ background: 'white' }}>No Data to display</FlexContainer>
            )
        }
    }

    public render() {
        let onUnloadPopup = null
        let popupTitle = "Extract Preview"
        let popupName = "ExtractPreview"
        if (this.props.params) {
            const zipResponse = this.props.params.zipResponse
            const zipResponseTimestamp = this.props.params.zipResponseTimestamp
            const selectedConfigSheet = this.props.params.selectedConfigSheet
            onUnloadPopup = this.props.params.onUnloadPopup
            popupTitle = this.props.params.popupTitle
            popupName = this.props.params.popupName

            const extractFilenameRegex = RegExp((this.props.params.extractFilenameRegex || "output.xlsx"), "i")
            const configFilenameRegex = RegExp((this.props.params.configFilenameRegex || "config.xlsx"), "i")
            const logFilenameRegex = RegExp((this.props.params.logFilenameRegex || "execution.log"), "i")


            let isNewzip = false
            if ((zipResponseTimestamp != this.state.zipResponseTimestamp)) {
                isNewzip = true
                this.setState(prevState => ({
                    ...prevState,
                    zipResponseTimestamp: zipResponseTimestamp,
                }))
            }
            if (isNewzip && zipResponse) {
                const self = this
                const zipFileReadPromises: Promise<any>[] = [];
                JSZip.loadAsync(zipResponse).then(function (zip) {
                    const fileList = Object.entries(zip.files)
                    const fileDict: Record<string, number> = {}
                    let index = 0
                    fileList.forEach(([filename, zipFile]) => {
                        if (extractFilenameRegex.test(filename)) {
                            zipFileReadPromises.push(zipFile.async('arraybuffer'))
                            fileDict["outputXlx"] = index
                            index++
                        }
                        else if (configFilenameRegex.test(filename)) {
                            zipFileReadPromises.push(zipFile.async('arraybuffer'))
                            fileDict["configXlx"] = index
                            index++
                        } else if (logFilenameRegex.test(filename)) {
                            zipFileReadPromises.push(zipFile.async('text'))
                            fileDict["extractLog"] = index
                            index++
                        }
                    })

                    Promise.all(zipFileReadPromises).then((fileDatas) => {
                        const outputXlxIndex: any = ("outputXlx" in fileDict ? fileDict["outputXlx"] : null);
                        const configXlxIndex: any = ("configXlx" in fileDict ? fileDict["configXlx"] : null);
                        const extractLogIndex: any = ("extractLog" in fileDict ? fileDict["extractLog"] : null);
                        const executionLogTxt = fileDatas[extractLogIndex]
                        let extratedDataWorkbook = null
                        if (outputXlxIndex >= 0) {
                            const outputXlx = fileDatas[outputXlxIndex]
                            extratedDataWorkbook = XLSX.read(outputXlx);
                        }

                        let configWorkbook = null
                        if (configXlxIndex >= 0) {
                            const configXlx = fileDatas[configXlxIndex]
                            configWorkbook = XLSX.read(configXlx);
                        }

                        if ((extratedDataWorkbook && extratedDataWorkbook.SheetNames && extratedDataWorkbook.SheetNames.length > 0) &&
                            (configWorkbook && configWorkbook.SheetNames && configWorkbook.SheetNames.length > 0)) {
                            const tableCoordinateDict = self.getTableCoordinates(configWorkbook.Sheets[(selectedConfigSheet || configWorkbook.SheetNames[0])], new Set(extratedDataWorkbook.SheetNames))
                            self.setState({
                                zipResponseTimestamp: zipResponseTimestamp,
                                extratedDataWorkbook: extratedDataWorkbook,
                                selectedWorksheetName: extratedDataWorkbook.SheetNames[0],
                                tableCoordinates: tableCoordinateDict,
                                logs: executionLogTxt
                            })
                        } else {
                            self.setState(prevState => ({
                                ...prevState,
                                logs: executionLogTxt
                            }))
                        }
                    })
                })
            }
        }

        const parentWidth = window.innerWidth;
        const parentHeight = window.innerHeight;
        const popupWidth = 0.7 * parentWidth
        const popupHeight = 0.7 * parentHeight

        return (
            <NewWindow title={popupTitle} name={popupName} onUnload={onUnloadPopup} onBlock={this.onBlockPopup} features={{ width: popupWidth, height: popupHeight }}  >
                <Container>{this.displayPreview()}</Container>
            </NewWindow>
        )
    }
}

const Container = styled.div`
  font-size: 0.9rem;
  line-height: 1.2rem;
  position: relative;
  z-index: 2;
  border-radius: 0 0 5px 5px;
  font-family: monospace, monospace;
`

const FlexContainer = styled.div`
  display: flex;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  padding: 20px;
  margin-bottom: 10px;
  .sectionRow{
    border-bottom-width:1px;
    border-bottom-color:#D3D3D3;
    border-bottom-style:solid;
    padding: 2px;
  }
  .sectionCell{
    overflow: auto;
  }
  .itemRow{
    padding: 3px;
  }
  .itemCell span{
    text-overflow:inherit;
    white-space:pre;
    overflow: auto;
  }
`

const ContainerBorder: any = styled.fieldset`

border-width: 1px;
border-style: solid;
border-color: #D3D3D3;
border-radius: 10px;
padding:10px;
margin-top: 10px;
margin-bottom: 20px;
width:100%;

.legend {
  width: initial;
  border-bottom: 0;
  margin-bottom: 0;
  font-weight: bold;
  text-align: center;
  color: #286090;
}
.banner{
    font-weight: bold;
    color:coral;
}
`

export default PreviewExtraction
