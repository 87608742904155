import * as React from 'react'
const { Button, TextInput, withTheme, SideSheet, Heading, Tooltip, Icon, toaster, Switch } = require('evergreen-ui')
import styled from 'styled-components'
import { navigate } from '@reach/router'
import _ from 'lodash-es';
import { IStatus } from '../../utils/api/Api'
import { uploadFile, checkStatus } from '../../api/ocr-api'
import { isAuthenticated } from '../../api/auth-api'


interface IState {
  input_file: File | null
  password: string
  use_textract: boolean
  ocr_languages: string
  ocr_pages: string | null
  is_ocr_pages_valid: boolean
  ocr_pages_error_message: string
  isLoader: boolean
  isSideSheetOpen: boolean
  status: IStatus | null
}
const default_ocr_language = 'English'
const default_state: IState = {
  input_file: null,
  password: '',
  use_textract: false,
  ocr_languages: default_ocr_language,
  ocr_pages: null,
  is_ocr_pages_valid: true,
  ocr_pages_error_message: '',
  isSideSheetOpen: false,
  isLoader: false,
  status: null
}

class OcrConverter extends React.Component<any, any> {
  public state: IState = _.cloneDeep(default_state)

  private uploadFile = async () => {
    let { input_file, password, use_textract, ocr_languages, ocr_pages } = this.state
    this.setState({
      isLoader: true,
    })

    if (input_file) {
      let ocr_languages_arr = null
      if (!use_textract) {
        if (ocr_languages) {
          ocr_languages_arr = ocr_languages.split(",")
        }
        ocr_pages = null
      }

      uploadFile(input_file, password, use_textract, ocr_languages_arr, ocr_pages)
        .then((res: any) => {
          this.checkDownloadStatus(res)
        })
        .catch((e: any) => {
          const error =
            e.response && e.response.body && e.response.body.error
              ? e.response.body.error
              : 'Unknown Error'
          const status = e.response && e.response.status ? e.response.status : 0
          if (status === 401) {
            this.setState(default_state)
            alert(error)
            navigate('/login')
          } else {
            alert(error)
          }
          this.setState({
            isLoader: false,
          })
        })
    } else {
      this.setState({
        isLoader: false
      })
      !input_file ? alert('Input file can not be empty') : null
    }
  }

  private processFinishedResponse = (res: any) => {
    if (res.is_success) {
      this.downloadURI(res.result, (res.type + '_output.pdf'))
      this.resetParent()
    } else {
      toaster.danger(res.status_description)
      this.setState({
        isLoader: false
      })
    }
  }

  private checkDownloadStatus = (res: any) => {
    if (res.is_finished) {
      this.processFinishedResponse(res)
    } else {
      const timer = setInterval(() => {
        console.log("Calling checkStatus")
        checkStatus(res.session_id)
          .then((res: IStatus) => {
            this.setState({
              status: res,
            })
            if (
              res.is_finished
            ) {
              clearInterval(timer)
              this.processFinishedResponse(res)
            }
          })
          .catch((e: any) => {
            clearInterval(timer)
            const error =
              e.response && e.response.body && e.response.body.error
                ? e.response.body.error
                : 'Something went wrong'
            toaster.danger(error)
            this.setState({
              isLoader: false
            })
          })
      }, 20000)

      return () => {
        clearInterval(timer)
      }
    }
  }

  private downloadURI(uri: any, name: any) {
    var link: any = document.createElement('a')
    link.download = name
    link.href = uri
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  private onCloseSideSheet = () => {
    this.setState({ isSideSheetOpen: false })
    this.resetParent()
  }

  private resetParent = () => {
    this.props.setParentCloseOnExternalClick(true)
    var hdTools = document.getElementById("hdTools")
    if (hdTools) hdTools.click() // to hide Tool Popover
  }

  private handleSwitch = (e: any) => {
    const inputVal = e.target.checked
    if (inputVal) {
      this.setState({ "use_textract": inputVal, "ocr_languages": default_ocr_language })
    } else {
      this.setState({ "use_textract": inputVal, "ocr_pages": "" })
    }
  }

  private displayOcrLangauageSelection() {
    const { use_textract, ocr_languages } = this.state
    if (use_textract) {
      return ""
    } else {
      return (
        <section className="flex-container">
          <span>Language
            <Tooltip content=" Enter ocr language, default is English. Comma seperated if mutiple languages">
              <Icon
                icon="info-sign"
                size={10}
                marginLeft={5}
                color="muted"
                style={{ cursor: 'pointer' }}
              />
            </Tooltip>
          </span>
          <TextInput
            required
            value={ocr_languages}
            onChange={(e: any) => this.setState({ ocr_languages: e.target.value })}
            name="ocr_languages"
            placeholder="OCR language"
            width={200}
          />
        </section>
      )
    }
  }

  private handleOcrPageInput(e: any) {
    const inputVal = e.target.value
    this.setState({ ocr_pages: inputVal })
    if (inputVal) {
      const regexp = /^[1-9]\d*(,[1-9]\d*)*$/
      const isOcrPageValid = regexp.test(inputVal)
      this.setState({
        is_ocr_pages_valid: isOcrPageValid,
      })
      if (!isOcrPageValid) {
        this.setState({
          ocr_pages_error_message: "Invalid values provided",
        })
      }
    } else {
      this.setState({
        is_ocr_pages_valid: true,
      })
    }
  }

  private displayOcrPageNumberSelection() {
    const { use_textract, ocr_pages, is_ocr_pages_valid, ocr_pages_error_message } = this.state
    if (!use_textract) {
      return ""
    } else {
      return (
        <>
          <section className="flex-container">
            <span>Page Numbers
              <Tooltip content=" Enter page numbers for OCR, keep empty for all pages. Comma seperated if mutiple pages">
                <Icon
                  icon="info-sign"
                  size={10}
                  marginLeft={5}
                  color="muted"
                  style={{ cursor: 'pointer' }}
                />
              </Tooltip>
            </span>
            <TextInput
              required
              value={ocr_pages}
              onChange={(e: any) => this.handleOcrPageInput(e)}
              name="ocr_pages"
              placeholder="Page numbers"
              width={200}
            />
          </section>
          {(!is_ocr_pages_valid) ? (
            <section className="flex-container">
              <span></span>
              <div className="errorMessage">{ocr_pages_error_message}</div>
            </section>) : null
          }
        </>
      )
    }
  }

  private openSlideSheet = () => {
    if (!isAuthenticated()) {
      navigate('/login')
    } else {
      this.setState({ isSideSheetOpen: true })
    }
    this.props.setParentCloseOnExternalClick(false)
  }

  private extractNameFromFile = (file: any | undefined) => {
    if (!file) {
      return 'Choose a PDF'
    }
    let name = file.name
    const MAX_LABEL_WORD_COUNT = 20
    if (name.length > MAX_LABEL_WORD_COUNT) {
      const startCount = MAX_LABEL_WORD_COUNT / 3
      const endCount = (MAX_LABEL_WORD_COUNT * 2) / 3
      name = name.substring(0, startCount) + '…' + name.substring(name.length - endCount)
    }
    return name
  }

  private DispalyDataRender() {
    const { input_file, ocr_languages, ocr_pages, isSideSheetOpen } = this.state

    const pdfLabel = this.extractNameFromFile(input_file)
    return (
      <>
        <SideSheet
          width={500}
          isShown={isSideSheetOpen}
          onCloseComplete={this.onCloseSideSheet}
          preventBodyScrolling={true}
        >
          <Container>
            <section className="flex-container">
              <span>Pdf *</span>
              <input
                className="inputfile"
                type="file"
                id="pdf"
                accept=".pdf"
                onChange={(e: any) => {
                  this.setState({ input_file: e.target.files[0] })
                }}
              />
              <label
                className={this.props.theme.getButtonClassName()}
                htmlFor="pdf"
                style={{ overflow: 'hidden' }}
              >
                {pdfLabel}
              </label>
            </section>
            <section className="flex-container">
              <span>Password</span>
              <TextInput
                secureTextEntry={true}
                onChange={(e: any) => this.setState({ password: e.target.value })}
                name="password"
                placeholder="password"
                width={200}
              />
            </section>
            <section className="flex-container">
              <span>
                Use Textract
                <Tooltip content=" Run AWS Textract on the PDF. Charges per page apply">
                  <Icon
                    icon="info-sign"
                    size={10}
                    marginLeft={5}
                    color="muted"
                    style={{ cursor: 'pointer' }}
                  />
                </Tooltip>
              </span>
              <Switch onChange={(e: any) => this.handleSwitch(e)} />
            </section>
            {this.displayOcrLangauageSelection()}
            {this.displayOcrPageNumberSelection()}
          </Container>
          <BottomButton
            isLoading={this.state.isLoader}
            appearance="primary"
            onClick={this.uploadFile}
          >
            Upload
          </BottomButton>
        </SideSheet>
        <Heading is={'h1'} size={600} color={'#116AB8'} onClick={this.openSlideSheet}>
          OCR Conversion
        </Heading>
      </>
    )
  }

  public render() {
    return <>{this.DispalyDataRender()}</>
  }
}

export default withTheme(OcrConverter)

const Container = styled.li`
   {
    text-overflow: ellipsis;
    list-style: none;
    margin-bottom: 20px;
    padding-top: 20px;

    .flex-container {
      display: flex;
      width: 100%;
      padding: 10px 20px;
      span {
        width: 25%;
      }
    }

    section {
      width: 20%;
    }

    .upload-file-section {
      padding: 1rem;
    }
    .inputfile {
      width: 0.1px;
      height: 0.1px;
      opacity: 0;
      overflow: hidden;
      position: absolute;
      z-index: -1;
    }
    .inputfile + label {
      padding: 0.5rem;
      font-size: 13.5px;
      border-radius: 2px;
      width: 185px;
      cursor: pointer;
    }

    .inputfile + label {
      cursor: pointer;
    }
    .upload-section {
      overflow: hidden;
    }
    .errorMessage{
      font-size: 14px;
      color: red;
    }
  }
`

const BottomButton = styled(Button)`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  border-radius: 5px 5px 0 0;
  justify-content: center;
`
