import * as React from 'react'
import { RouteComponentProps } from '@reach/router'
import styled from 'styled-components'
import WithAuthorization from '../../components/WithAuthorization/WithAuthorization'
import SearchHeader from '../../components/SearchHeader/SearchHeader'
import CustomCard from '../../components/CustomCard/CustomCard'
import BulkOperation from '../../components/BulkOperation/BulkOperation'
import AllFiles from '../../components/AllFiles/AllFiles'

interface IProps extends RouteComponentProps {
  fileContains?: string
}

class FileSearch extends React.Component<IProps> {
  public render() {
    const { fileContains } = this.props
    return (
      <main>
        <CustomCard type={'allFiles'}>
          <CardHeader>
            <BulkOperation />
          </CardHeader>
          <AllFiles fileContains={fileContains} />
        </CustomCard>
      </main>
    )
  }
}

export default WithAuthorization(FileSearch, SearchHeader)

const CardHeader = styled.section`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 24px;
`
