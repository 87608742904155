export const GRAPHQL_BASE_URL = `graphql/`

export const FilePath = {
  downloadFile: `get_file/`,
  uploadNewFile: `parse_statement/`,
  existingConfigs:`get_bank_configs/`,
  syncConfigs:`banksubtypemap/sync/`,
  downloadHTMLFile: `v1/get_file/html`,
}

export const tkheaders = {
  uploadFile: `tkheaders/init/`,
  headerBounds: `tkheaders/finish/`,
  tkheadersPdf: `tkheaders/pdf_to_image/`,
  tkheadersConfig : `tkheaders/config/`
}

export const CanopySimulator = {
  uploadFile: `run_canopy_ul/`,
  replaceParsedFile: `replace_parsed_output`,
}

export const AnonymisePdf = {
  downloadAnonymisePdf: `anonymise_pdf/`,
}

export const ParserParams = {
  getParserParams: `get_parser_params/`,
}

export const parserLog = {
  getLog: `get_log/`,
}

export const OcrPdf = {
  uploadFile: `ocr`,
  checkStatus: `ocr/status`,
}
