import * as request from 'superagent'
import { getApi, IStatus } from '../utils/api/Api'
import { OcrPdf } from './api-constants'
import { getAuthorizationHeaders } from './auth-api'

interface IUploadNewFileResponse {
  taskId: string | null
  fileId: string | null
}
export const uploadFile = async (
  input_file: any,
  password: string,
  use_textract: boolean,
  ocr_languages: string[] | null,
  ocr_pages: string | null,

): Promise<IStatus> => {
  try {

    let ocr_request = request
      .post(getApi(OcrPdf.uploadFile))
      .set(await getAuthorizationHeaders())
      .attach('input_pdf', input_file)
      .field('password', password)
      .field('use_textract', use_textract)

    if (ocr_languages) {
      ocr_request = ocr_request.field('abbyy_ocr_languages', ocr_languages)
    }
    if (ocr_pages) {
      ocr_request = ocr_request.field('ocr_pages', ocr_pages)
    }

    const response = await ocr_request
    return response.body
  } catch (e) {
    throw e
  }
}

export const checkStatus = async (
  sessionId: String
): Promise<IStatus> => {
  try {
    const response = await request
      .get(getApi(OcrPdf.checkStatus))
      .query({ session_id: sessionId })
      .set(await getAuthorizationHeaders())
    return response.body
  } catch (e) {
    throw e
  }
}
