import { navigate, RouteComponentProps } from '@reach/router'
import * as React from 'react'
import { getTokenWithRestoreSession  } from '../../api/auth-api'

interface IState {
  token: string | null
}

// to avoid first render when @method componentDidMount hasn't been called yet
const INITIAL_TOKEN = 'initial_token'

class AuthRoutes extends React.Component<any, IState> {
  public state = {
    token: INITIAL_TOKEN,
  }

  public async componentDidMount(): Promise<void> {
    const token = await getTokenWithRestoreSession()
    this.setState({ token })
  }

  public async componentDidUpdate(
    prevProps: Readonly<RouteComponentProps<{}>>,
    prevState: IState,
  ): Promise<void> {
    const token = await getTokenWithRestoreSession()
    if (prevState.token !== token) {
      this.setState({ token })
    }
  }

  public render() {
    const { token } = this.state
    if (token === INITIAL_TOKEN) {
      return null
    }

    if (this.props.location && this.props.location.pathname === '/login') {
      if (token) {
        navigate('/')
        return null
      } else {
        return this.props.children
      }
    }

    if (!token) {
      navigate('/login')
      return <h1>Unauthorized</h1>
    }
    return this.props.children
  }
}

export default AuthRoutes
