import * as React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
const { Button, Heading,  TextInput, toaster} = require('evergreen-ui')
import TaskItem from './TaskItem';
import styled from 'styled-components'
import ResponseItem from './ResponseItem'
import gql from 'graphql-tag'
import { Mutation } from 'react-apollo'
import { colors} from '../../utils/\/colors'
import UploadParserParameter from './ParserParameter'

class DisplayRestartTask extends React.Component<any> {
  public state = {
    taskResponseData: [],
    isLoading: false,
    count:0,
    bankName: '',
    subtype: '',
  }

  private displayTable = () => {
     return this.props.bulkData.map((item: any)=> {
          return <TaskItem item={item }/>
        
      })
  }

  private displayResponse = () => {
    return this.state.taskResponseData.map((item: any)=> {
      return <ResponseItem item= {item}/>
    })
  }

  private responseHeader = () => {
    return(
      <Header>
        <div className="fileName">File Name</div>
        <div className="status">Status</div> 
        <div>Message</div>
      </Header>
    )
  }

  private parserParameter = () => {
    if(this.props.params.length > 0) {
      var result: any[] = []
      result = this.props.params
      var param: any = {}
      for (var i = 0; i < result.length; i++) {
        if (result[i].type === 'str') {
          param[result[i].key] = result[i].value.trim()
        } else if (result[i].type === 'int' || result[i].type === 'float') {
          const temp = +result[i].value
          param[result[i].key] = temp
        } else {
          param[result[i].key] = result[i].value
        }
      }
      return JSON.stringify(param)
    } else {
      return null
    }

  }
  
  private dispplayCancelTAsk = () => {
    var cancelResponseData: any [] =[]
    var count = 0
    return (
         <>
        <Mutation mutation={RerunParserMutation}>
        {(rerunTask, { data }) => (
           <Button appearance="primary" isLoading={this.state.isLoading} onClick={() =>{
            if(this.props.params.length > 0 && this.state.bankName && this.state.subtype) {
              this.setState({
                isLoading: true
              })
              for(let  item of this.props.bulkData) {
                rerunTask( {variables: 
                  { 
                    fileToReparse: item.fileUuid,
                    bank: this.state.bankName,
                    subtype: this.state.subtype,
                    pageRange: 'all',
                    params: this.parserParameter()
  
                  },
                  
                } )
                .then((result: any)=> {
                  count = count +1
                  this.setState({
                    count: count
                  })
                  const temp = {
                    inputFile: item.inputFile,
                    originalFile: item.originalFile,
                    success: result.data.reProcessStatement.success,
                    message: result.data.reProcessStatement.message
  
                  }
                  cancelResponseData.push(temp)
                  this.setState({
                    taskResponseData : cancelResponseData
                  })
                 
                })
              }
             } else{
               !this.state.subtype ? toaster.danger('Enter Bank Name') : null
               !this.state.bankName ?  toaster.danger('Enter Subtype'): null
               this.props.params.length <= 0 ? toaster.danger('Enter parser parameter'): null
           
             }
            this.setState({
              isLoading: false
            })
             }}>
           Restart Jobs
         </Button>
          )} 
        </Mutation>
      </>
  
    )
  }

private displayTableHeader() {
  return(
    <Header>
      <div className="fileName">File Name</div>
      <div>Status</div> 
    </Header>
  )

}
private displyResponseDataTable() {
  if(this.state.taskResponseData.length > 0) {
    return(
      <>
      {this.responseHeader()}
      {this.displayResponse()}
      </>
    )

  } else if(this.props.bulkData.length > 0){
    return(
      <>
      <ParserContainer>
      <section className="upload-section">
              <TextInput
                required
                onChange={(e: any) => this.setState({ bankName: e.target.value.trim() })}
                name="bankName"
                placeholder="Bank name"
                width={200}
              />
            </section>
            <section className="upload-section">
              <TextInput
                required
                onChange={(e: any) => this.setState({ subtype: e.target.value.trim() })}
                name="subtype"
                placeholder="subtype"
                width={200}
              />
            </section>
      <UploadParserParameter subtype={this.state.subtype} bank={this.state.bankName} />
      </ParserContainer>
      {this.displayTableHeader()}
      {this.displayTable()}
      {this.dispplayCancelTAsk()}
      </>
    )
  } else{
    return null
  }
}
  public render() {
    const totalCount = this.props.bulkData.length
    return(
      <Container>
      <div className="cardHeader">
      <Heading size={700} color={colors.blue}className="title">Restart Task List</Heading>
      <Heading size={700} color={colors.blue} className="count" marginBottom={10}>
        <span>Completed:</span>{this.state.count} of {totalCount}
        </Heading>
        </div>
      { this.displyResponseDataTable()}
     
      </Container>
    ) 
  }
 }



const mapStateToProps = (storeData: any) => {
  return {
    bulkData: storeData.onCheckReducer.bulkOperationData,
    params: storeData.onChangeReducer.params,
  }
}

const RerunParserMutation = gql`
  mutation Reparse(
    $fileToReparse: String!
    $bank: String!
    $subtype: String!
    $pageRange: String
    $params: JSONString
  ) {
    reProcessStatement(
      fileUuid: $fileToReparse
      bank: $bank
      subtype: $subtype
      pageRange: $pageRange
      params: $params
    ) {
      success
      message
    }
  }
`

const ParserContainer = styled.div`
display: flex;
padding: 20px;
.upload-section{
  width: 33%;
}
`
const Container = styled.div`
box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.1);
border-radius: 5px;
padding: 20px;
background-color: white;
.cardHeader{
  border-bottom: 2px solid #F5F5F5;
  display: flex
  .title{
    width: 80%;
  }
  .count{
    span{
      padding-right: 10px;
    }
  }
}


`

const Header = styled.div`
display: flex;
background-color: #F5F5F5;
box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.1);
border-radius: 5px;
padding: 20px;
margin-bottom: 10px;
font-weight: bold;
.fileName{
    width: 50%;
}
.status{
  width: 10%;
}
.
`

export default connect(mapStateToProps, null)(DisplayRestartTask)