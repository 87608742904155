import * as React from 'react'
import WithAuthorization from '../../components/WithAuthorization/WithAuthorization'
import FilesUpload from './UploadFile'

class CanopySimulator extends React.Component<any, any> {
  public render() {
    return (
      <main>
        <FilesUpload />
      </main>
    )
  }
}

export default WithAuthorization(CanopySimulator)
