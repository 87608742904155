import * as React from 'react'

const { Badge, Heading, Text } = require('evergreen-ui')

interface IProps {
  params: string | undefined
}

function getParams(paramsStr: string, filter: boolean) {
  const params = JSON.parse(paramsStr)
  return Object.keys(params)
    .filter(key => params[key] === filter)
    .sort((a, b) => a.localeCompare(b))
}

const Params: React.FunctionComponent<IProps> = ({ params }: IProps) =>
  params && params !== '{}' ? (
    <div>
      <Heading is={'h6'}>true</Heading>
      {getParams(params, true).map(tag => (
        <Badge key={tag} marginRight={8} marginBottom={4}>
          {tag}
        </Badge>
      ))}
      <Heading is={'h6'} style={{ marginTop: 16 }}>
        false
      </Heading>
      {getParams(params, false).map(tag => (
        <Badge key={tag} marginRight={8} marginBottom={4}>
          {tag}
        </Badge>
      ))}
    </div>
  ) : (
    <div>
      <pre>
        <Text color={'muted'}>
          <code>No Parameters provided</code>
        </Text>
      </pre>
    </div>
  )
export default Params
