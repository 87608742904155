import * as React from 'react'

import ParserParameter from '../ParserParameter/ParserParameter'

const { Dialog, Button } = require('evergreen-ui')

class UploadParserParameter extends React.Component<any, any> {
  public state = {
    shown: false,
  }

  private displaySelectParameter() {
    const { bank, subtype } = this.props
    return (
      <Dialog
        contentContainerProps={{
          padding: 10,
        }}
        isShown={this.state.shown}
        onCloseComplete={this.closeDialog}
        confirmLabel={'Save'}
        hasHeader={false}
        title="Rerun Parser"
        topOffset={'6vmin'}
        preventBodyScrolling={true}
      >
        <>
          <ParserParameter subtype={subtype} bank={bank} />
        </>
      </Dialog>
    )
  }

  private showDialog = () => this.setState({ shown: true })
  private closeDialog = () => this.setState({ shown: false })

  public render() {
    return (
      <>
        {this.displaySelectParameter()}
        <Button onClick={this.showDialog}>Select Parser Parameter</Button>
      </>
    )
  }
}

export default UploadParserParameter
