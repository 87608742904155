import * as React from 'react'
import styled from 'styled-components'
const { Icon } = require('evergreen-ui')
import TaskItem from './TaskItem';
class ResponseItem extends TaskItem {
    private displayStatus = () => {
        const { success } = this.props.item
        if (success) {
            return <Icon icon="tick-circle" color="success" />
        } else {
            return <Icon icon="cross" color="danger" />
        }
    }
    public render() {
        const { message, item } = this.props.item
        return (
            <Container>
                <div className="fileName">{super.displayFileName(item)}</div>
                <div className="status">{this.displayStatus()}</div>
                <div>{message}</div>
            </Container>
        )
    }
}

const Container = styled.div`
  display: flex;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  padding: 20px;
  background-color: #f5f5f5;
  margin-bottom: 10px;
  .fileName {
    width: 50%;
  }
  .status {
    width: 10%;
  }
`

export default ResponseItem
