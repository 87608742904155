import * as React from 'react'
import { Query } from 'react-apollo'
import gql from 'graphql-tag'
import { Helmet } from 'react-helmet'
import styled from 'styled-components'

import WithAuthorization from '../../components/WithAuthorization/WithAuthorization'
import FileDetailLogoHeader from '../../components/FileDetailLogoHeader/FileDetailLogoHeader'
import { generateGenericFavicon } from '../../utils/generateGenericFaviconForHelmet'
import { getOrderedHistory } from '../../components/FileHistory/file-history.utils'
import { GetFileDetailedHistory_files_historySet_edges } from '../../schemaTypes'
import FileHistoryDetailedItem from '../../components/FileHistoryDetailedItem/FileHistoryDetailedItem'
import FileDetailOverviewCard from '../../components/FileDetailOverviewCard/FileDetailOverviewCard'
import TitleCard from '../../components/TitleCard/TitleCard'
import FileHistoryDetailedItemLink from '../../components/FileHistoryDetailedItem/FileHistoryDetailedItemLink'

interface IProps {
  fileId?: string
}

const POLL_INTERVAL = 10000

class FileHistoryPage extends React.Component<IProps> {
  public render() {
    const { fileId } = this.props
    if (!fileId) {
      return <span>No file found</span>
    }
    return (
      <main>
        <Query query={GET_FILE_DETAIL_HISTORY} variables={{ fileId }} pollInterval={POLL_INTERVAL}>
          {({ loading, error, data }) => {
            if (error) {
              return <span>error</span>
            }
            if (loading) {
              return <span>fetching</span>
            }

            if (!data || !data.files || !data.files.historySet) {
              return <span>No data found</span>
            }

            const histories = getOrderedHistory(data.files.historySet.edges)

            return (
              <>
                <Helmet defer={false} link={generateGenericFavicon()}>
                  <title>File History | Canopy</title>
                </Helmet>
                <Container>
                  <FileDetailOverviewCard {...data.files} />
                  <TitleCard icon={'history'} title={'History'}>
                    {histories.map(
                      ({ node }: GetFileDetailedHistory_files_historySet_edges) =>
                        node && (
                          <section>
                            <FileHistoryDetailedItem {...node} />
                          </section>
                        ),
                    )}
                  </TitleCard>
                </Container>
              </>
            )
          }}
        </Query>
      </main>
    )
  }
}

const Container = styled.div`
  display: grid;
  padding: 24px 0;
  grid-template-columns: 1fr;
  grid-gap: 24px;
`

const GET_FILE_DETAIL_HISTORY = gql`
  query GetFileDetailedHistory($fileId: ID!) {
    files(id: $fileId) {
      id
      mUser {
        id
        userId
      }
      fileUuid
      taskId
      parentUserId
      statementDate
      inputFile
      configFile
      parsingStatus
      originalFile
      bankStatus
      bankCode
      subtype
      pageRange
      createdAt
      params
      updatedAt
      outputExcel
      historySet {
        edges {
          node {
            id
            parsingStatus
            createdAt
            updatedAt
            description
            params
            taskId
            finishedAt
            processingAt
            historyUuid
            meta
          }
        }
      }
    }
  }
`

export default WithAuthorization(FileHistoryPage, FileDetailLogoHeader)
