import  {FilesStatus}  from '../../schemaTypes'



export const FILE_TYPE_PDF = "input_pdf"
export const FILE_TYPE_TRANSFORMED_PDF = "input_processed_pdf"
export const FILE_TYPE_OUTPUT_EXCEL = "output_excel"
export const FILE_TYPE_UL_EXCEL = "canopy_ul"


export type ItemType =  {
     isPdfDownload: boolean 
     isExcelDownload: boolean 
     isCanopyDownload: boolean 
     isDownloadAll: boolean 
}

export type DisplayFieldInfo = {
   isAvailable: boolean 
   isS3key: boolean 
   field: any 
}

export type responseInfo = {
  item: any 
  success: boolean 
  message: string 
}

export function  convertFileTypeToItemType(fileType:string):ItemType {
  var isPdfDownload = false
  var isExcelDownload = false
  var isCanopyDownload = false
  var isDownloadAll = false
    switch (fileType) {
        case FILE_TYPE_PDF:
            isPdfDownload = true
        case FILE_TYPE_OUTPUT_EXCEL:
            isExcelDownload = true
        case FILE_TYPE_UL_EXCEL:
            isCanopyDownload = true
      }
    return { isPdfDownload, isExcelDownload, isCanopyDownload, isDownloadAll }
  }

  export  function getDisplayFieldByItemType(item:any, itemType:ItemType):DisplayFieldInfo{
    const { isPdfDownload, isExcelDownload, isCanopyDownload, isDownloadAll } = itemType
    const { inputFile, originalFile, outputExcel, canopyUlExcel, parsingStatus } = item

    var field = null
    var isAvailable = false
    var isS3key = false
    if(isPdfDownload || isDownloadAll){
      if(inputFile){
        field = inputFile
      }else{
        field = originalFile
        isS3key = true
      }
    }
    else if (parsingStatus != FilesStatus.EX_FAI){
          field = isExcelDownload?outputExcel:canopyUlExcel
    }
    isAvailable = !!field
    return {isAvailable,isS3key, field}
  }

  export const getDisplayFieldByFieldType = (item:any, fileType:string):DisplayFieldInfo => {
     return getDisplayFieldByItemType(item, convertFileTypeToItemType(fileType))
  }
