import * as React from 'react'
import { navigate } from '@reach/router'

const { Icon } = require('evergreen-ui')

const { Text } = require('evergreen-ui')

import { GetFileDetail_files_historySet_edges } from '../../schemaTypes'
import TitleCard from '../TitleCard/TitleCard'
import FileHistoryItem from '../FileHistoryItem/FileHistoryItem'

interface IProps {
  fileId: string
  history: GetFileDetail_files_historySet_edges[]
  style?: React.CSSProperties
  className?: string
}

class FileHistory extends React.Component<IProps> {
  public render() {
    const { history, style, fileId, className } = this.props
    if (!history || history.length === 0) {
      return (
        <TitleCard icon={'history'} title={'File history'} style={style}>
          <pre>
            <Text color={'muted'}>
              <code>No history found</code>
            </Text>
          </pre>
        </TitleCard>
      )
    }

    return (
      <TitleCard
        className={className}
        icon={'history'}
        title={'File history'}
        style={{ ...style }}
        onClickTitle={() => {
          navigate(`/files/${fileId}/history`)
        }}
        actionComponent={<Icon icon={'chevron-right'} />}
      >
        {history
          .slice(0, 3)
          .map(
            ({ node }: GetFileDetail_files_historySet_edges, index) =>
              node && <FileHistoryItem key={index} {...node} />,
          )}
      </TitleCard>
    )
  }
}

export default FileHistory
