import * as React from 'react'
import styled from 'styled-components'
import { extractFileDataFromS3Key } from '../../utils/s3'
const { Icon } = require('evergreen-ui')
import {cleanInputFile} from '../../utils/FileUtils'
class ResponseItem extends React.Component<any> {
  private displayFileName = () => {
    const { inputFile, originalFile } = this.props.item
    if (inputFile !== null) {
      return <span>{cleanInputFile(inputFile)}</span>
    } else {
      const { filename } = extractFileDataFromS3Key(originalFile)
      const file = filename.split(/[/]+/).pop()
      return <span>{file}</span>
    }
  }
  private displayStatus = () => {
    const { success } = this.props.item
    if (success) {
      return <Icon icon="tick-circle" color="success" />
    } else {
      return <Icon icon="cross" color="danger" />
    }
  }
  public render() {
    const { message } = this.props.item
    return (
      <Container>
        <div className="fileName">{this.displayFileName()}</div>
        <div className="status">{this.displayStatus()}</div>
        <div>{message}</div>
      </Container>
    )
  }
}

const Container = styled.div`
  display: flex;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  padding: 20px;
  background-color: #f5f5f5;
  margin-bottom: 10px;
  .fileName {
    width: 50%;
  }
  .status {
    width: 10%;
  }
`

export default ResponseItem
