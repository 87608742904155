import { RouteComponentProps } from '@reach/router'
import gql from 'graphql-tag'
import * as React from 'react'
import { Query } from 'react-apollo'
import { Helmet } from 'react-helmet'
import styled from 'styled-components'
import FileDetailLogoHeader from '../../components/FileDetailLogoHeader/FileDetailLogoHeader'
import FileDetailOverviewCard from '../../components/FileDetailOverviewCard/FileDetailOverviewCard'
import { getOrderedHistory } from '../../components/FileHistory/file-history.utils'
import FileHistory from '../../components/FileHistory/FileHistory'
import ParamsCardContainer from '../../components/Params/ParamsCardContainer'
import QueryError from '../../components/QueryError/QueryError'
import Stacktrace from '../../components/Stacktrace/Stacktrace'
import WithAuthorization from '../../components/WithAuthorization/WithAuthorization'
import { FilesStatus, GetFileDetail_files } from '../../schemaTypes'

interface IProps extends RouteComponentProps {
  fileId?: string
}

const s = 1000
const POLL_INTERVAL = 2 * s

function FileDetail(props: IProps) {
  return (
    <main>
      <Query
        query={GET_FILE_DETAIL}
        variables={{ fileId: props.fileId }}
        pollInterval={POLL_INTERVAL}
        fetchPolicy={'network-only'}
      >
        {({ loading, error, data }) => {
          if (error) {
            return <QueryError error={error} />
          }
          if (loading || !data || !props.fileId) {
            return <span>fetching</span>
          }

          return (
            <>
              {data.files ? (
                <>
                  <Helmet defer={false} link={generateFileDetailFavicon(data.files)}>
                    <title>{generateFileDetailTitle(data.files)}</title>
                  </Helmet>
                  <Container>
                    <FileDetailOverviewCard {...data.files} style={{ gridColumn: '1 / -1' }} />
                    <ParamsCardContainer
                      params={data.files.params}
                      style={{ gridColumn: '1 / span 1' }}
                    />
                    <FileHistory
                      className="grid-item-file-history"
                      fileId={props.fileId}
                      history={getOrderedHistory(data.files.historySet.edges, 3)}
                    />
                    <Stacktrace logs={getLogs(data)} style={{ gridColumn: '1 / -1' }} />
                  </Container>
                </>
              ) : (
                <div style={{ color: 'red' }}>No file found</div>
              )}
            </>
          )
        }}
      </Query>
    </main>
  )
}

function getLogs(data: any) {
  const orderedHistory = getOrderedHistory(data.files.historySet.edges)
  const latestHistory =
    orderedHistory && orderedHistory.length > 0 ? orderedHistory[0].node : undefined
  if (latestHistory) {
    return latestHistory.description
  }
  return null
}

const generateFileDetailFavicon = ({
  parsingStatus = FilesStatus.EX_SUC,
}: GetFileDetail_files): any[] => {
  const sizes = ['16x16', '32x32']
  return sizes.map(size => {
    const delimiter = status === FilesStatus.EX_SUC ? '' : parsingStatus + '/'
    const href = `/assets/favicons/${delimiter}favicon-${size}.png`
    return {
      rel: 'icon',
      type: 'image/png',
      sizes: size,
      href,
      'data-react-helmet': true,
    }
  })
}

const generateFileDetailTitle = ({
  mUser: { userId: clientId },
  bankCode,
}: GetFileDetail_files) => {
  return `${clientId} ‣ ${bankCode}`.toUpperCase()
}

const Container = styled.div`
  display: grid;
  grid-gap: 24px;
  padding: 24px 0;
  grid-auto-flow: row;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  .grid-item-file-history {
    grid-column: 2/ -1;
    @media (max-width: 708px) {
      grid-column: 1 / -1;
    }
  }
`

const GET_FILE_DETAIL = gql`
  query GetFileDetail($fileId: ID!) {
    files(id: $fileId) {
      id
      mUser {
        id
        userId
      }
      fileUuid
      taskId
      parentUserId
      statementDate
      bankPredictedBy
      inputFile
      configFile
      parsingStatus
      originalFile
      bankStatus
      bankCode
      subtype
      createdAt
      params
      updatedAt
      outputExcel
      pageRange
      historySet {
        edges {
          node {
            id
            historyUuid
            parsingStatus
            createdAt
            updatedAt
            description
            params
            taskId
            finishedAt
            processingAt
            meta
          }
        }
      }
    }
  }
`

export default WithAuthorization(FileDetail, FileDetailLogoHeader)
