/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetAllFiles
// ====================================================

export interface GetAllFiles_allFiles_edges_node_mUser {
  __typename: 'UsersNode'
  /**
   * The ID of the object.
   */
  id: string
  userId: string
}

export interface GetAllFiles_allFiles_edges_node {
  __typename: 'FilesNode'
  /**
   * The ID of the object.
   */
  id: string
  inputFile: string
  fileUuid: string
  parsingStatus: FilesStatus
  bankStatus: string
  originalFile: string
  params: any
  subtype: number | null
  mUser: GetAllFiles_allFiles_edges_node_mUser
  updatedAt: any
  bankCode: string
}

export interface GetAllFiles_allFiles_edges {
  __typename: 'FilesNodeEdge'
  /**
   * The item at the end of the edge
   */
  node: GetAllFiles_allFiles_edges_node | null
}

export interface GetAllFiles_allFiles_pageInfo {
  __typename: 'PageInfo'
  /**
   * When paginating forwards, the cursor to continue.
   */
  endCursor: string | null
  /**
   * When paginating forwards, are there more items?
   */
  hasNextPage: boolean
}

export interface GetAllFiles_allFiles {
  __typename: 'FilesNodeConnection'
  edges: (GetAllFiles_allFiles_edges | null)[]
  pageInfo: GetAllFiles_allFiles_pageInfo
}

export interface GetAllFiles {
  allFiles: GetAllFiles_allFiles | null
}

export interface GetAllFilesVariables {
  fileStatus?: string | null
  cursor?: string | null
  bankCode?: string | null
  clientId?: string | null
  fileContains?: string | null
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetFileDetailHeader
// ====================================================

export interface GetFileDetailHeader_files_mUser {
  __typename: 'UsersNode'
  /**
   * The ID of the object.
   */
  id: string
  userId: string
}

export interface GetFileDetailHeader_files {
  __typename: 'FilesNode'
  /**
   * The ID of the object.
   */
  id: string
  mUser: GetFileDetailHeader_files_mUser
  inputFile: string
  bankCode: string
}

export interface GetFileDetailHeader {
  /**
   * The ID of the object
   */
  files: GetFileDetailHeader_files | null
}

export interface GetFileDetailHeaderVariables {
  fileId: string
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: Reparse
// ====================================================

export interface Reparse_reProcessStatement {
  __typename: 'ReProcessStatement'
  success: boolean | null
}

export interface Reparse {
  reProcessStatement: Reparse_reProcessStatement | null
}

export interface ReparseVariables {
  fileToReparse: string
  bank?: string
  subtype?: string
  pageRange?: string | null
  params: string
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetFileId
// ====================================================

export interface GetFileId_allHistory_edges_node_mFile {
  __typename: 'FilesNode'
  /**
   * The ID of the object.
   */
  id: string
}

export interface GetFileId_allHistory_edges_node {
  __typename: 'HistoryNode'
  mFile: GetFileId_allHistory_edges_node_mFile
}

export interface GetFileId_allHistory_edges {
  __typename: 'HistoryNodeEdge'
  /**
   * The item at the end of the edge
   */
  node: GetFileId_allHistory_edges_node | null
}

export interface GetFileId_allHistory {
  __typename: 'HistoryNodeConnection'
  edges: (GetFileId_allHistory_edges | null)[]
}

export interface GetFileId {
  allHistory: GetFileId_allHistory | null
}

export interface GetFileIdVariables {
  taskId: string
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetFileDetail
// ====================================================

export interface GetFileDetail_files_mUser {
  __typename: 'UsersNode'
  /**
   * The ID of the object.
   */
  id: string
  userId: string
}

export interface GetFileDetail_files_historySet_edges_node {
  __typename: 'HistoryNode'
  /**
   * The ID of the object.
   */
  id: string
  taskId: string
  historyUuid: any
  parsingStatus: HistoryStatus
  createdAt: any
  updatedAt: any
  description: string | null
  params: any
  finishedAt: any | null
  processingAt: any | null
  meta: null | null
}

export interface GetFileDetail_files_historySet_edges {
  __typename: 'HistoryNodeEdge'
  /**
   * The item at the end of the edge
   */
  node: GetFileDetail_files_historySet_edges_node | null
}

export interface GetFileDetail_files_historySet {
  __typename: 'HistoryNodeConnection'
  edges: (GetFileDetail_files_historySet_edges | null)[]
}

export interface GetFileDetail_files {
  __typename: 'FilesNode'
  /**
   * The ID of the object.
   */
  id: string
  mUser: GetFileDetail_files_mUser
  fileUuid: any
  inputFile: string
  configFile: string
  parsingStatus: FilesStatus
  bankCode: string
  subtype: number | null
  createdAt: any
  params: any
  updatedAt: any
  outputExcel: string
  historySet: GetFileDetail_files_historySet | null
}

export interface GetFileDetail {
  /**
   * The ID of the object
   */
  files: GetFileDetail_files | null
}

export interface GetFileDetailVariables {
  fileId: string
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetDetailedHistory
// ====================================================

export interface GetDetailedHistory_allHistory_edges_node {
  __typename: 'HistoryNode'
  /**
   * The ID of the object.
   */
  id: string
  historyUuid: any
  parsingStatus: HistoryStatus
  createdAt: any
  updatedAt: any
  processingAt: any | null
  finishedAt: any | null
  description: string | null
  canopyUlLog: string | null
  params: any
}

export interface GetDetailedHistory_allHistory_edges {
  __typename: 'HistoryNodeEdge'
  /**
   * The item at the end of the edge
   */
  node: GetDetailedHistory_allHistory_edges_node | null
}

export interface GetDetailedHistory_allHistory {
  __typename: 'HistoryNodeConnection'
  edges: (GetDetailedHistory_allHistory_edges | null)[]
}

export interface GetDetailedHistory {
  allHistory: GetDetailedHistory_allHistory | null
}

export interface GetDetailedHistoryVariables {
  historyId: string
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetFileDetailedHistory
// ====================================================

export interface GetFileDetailedHistory_files_mUser {
  __typename: 'UsersNode'
  /**
   * The ID of the object.
   */
  id: string
  userId: string
}

export interface GetFileDetailedHistory_files_historySet_edges_node {
  __typename: 'HistoryNode'
  /**
   * The ID of the object.
   */
  id: string
  parsingStatus: HistoryStatus
  createdAt: any
  updatedAt: any
  description: string | null
  params: any
  finishedAt: any | null
  processingAt: any | null
  historyUuid: any
  meta: any | null
  taskId: any | null
}

export interface GetFileDetailedHistory_files_historySet_edges {
  __typename: 'HistoryNodeEdge'
  /**
   * The item at the end of the edge
   */
  node: GetFileDetailedHistory_files_historySet_edges_node | null
}

export interface GetFileDetailedHistory_files_historySet {
  __typename: 'HistoryNodeConnection'
  edges: (GetFileDetailedHistory_files_historySet_edges | null)[]
}

export interface GetFileDetailedHistory_files {
  __typename: 'FilesNode'
  /**
   * The ID of the object.
   */
  id: string
  mUser: GetFileDetailedHistory_files_mUser
  fileUuid: any
  inputFile: string
  configFile: string
  parsingStatus: FilesStatus
  bankCode: string
  createdAt: any
  params: any
  updatedAt: any
  outputExcel: string
  historySet: GetFileDetailedHistory_files_historySet | null
}

export interface GetFileDetailedHistory {
  /**
   * The ID of the object
   */
  files: GetFileDetailedHistory_files | null
}

export interface GetFileDetailedHistoryVariables {
  fileId: string
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

/**
 * An enumeration.
 */
export enum FilesStatus {
  EX_FAI = 'EX_FAI',
  EX_PRO = 'EX_PRO',
  EX_SUC = 'EX_SUC',
  EX_TBP = 'EX_TBP',
  EX_NOT =  'EX_NOT'
}

export enum FilesConversionStatus {
  CV_TBP = 'CV_TBP',
  CV_PRO = 'CV_PRO',
  CV_SUC = 'CV_SUC',
  CV_FAI = 'CV_FAI'
}

export enum BankStatus {
  BS_NR = 'BS_NR',
  BS_PEN = 'BS_PEN',
  BS_RUN = 'BS_RUN',
  BS_BNK = 'BS_BNK',
  BS_SUB = 'BS_SUB',
  BS_SRN= 'BS_SRN'
}

export enum FilterStatus {
  EX_FAI = 'EX_FAI',
  EX_PRO = 'EX_PRO',
  EX_SUC = 'EX_SUC',
  EX_TBP = 'EX_TBP',
  BS_NR = 'BS_NR',
  BS_PEN = 'BS_PEN',
  BS_RUN = 'BS_RUN',
  BS_BNK = 'BS_BNK',
  BS_SUB = 'BS_SUB',
}
export enum HistoryStatus {
  EX_FAI = 'EX_FAI',
  EX_PRO = 'EX_PRO',
  EX_SUC = 'EX_SUC',
  EX_TBP = 'EX_TBP',
}

export enum PdfAnonymizer {
  INVALID = 'INVALID',
  SUCCEEDED = 'SUCCEEDED',
  FAILED = 'FAILED',
  TIMED_OUT = 'TIMED_OUT',
  ABORTED = 'ABORTED',
  PENDING = 'PENDING',
  RUNNING = 'RUNNING'
}

/**
 * An enumeration.
 */

//==============================================================
// END Enums and Input Objects
//==============================================================