import * as React from 'react'
import { RouteComponentProps } from '@reach/router'
import styled from 'styled-components'
import WithAuthorization from '../../components/WithAuthorization/WithAuthorization'
import AllFiles from '../../components/AllFiles/AllFiles'
import CustomCard from '../../components/CustomCard/CustomCard'
import BulkOperation from '../../components/BulkOperation/BulkOperation'
import SearchHeader from '../../components/SearchHeader/SearchHeader'
interface IProps extends RouteComponentProps {
  bank_subtype: string
}

class BankSubtype extends React.Component<IProps> {
  public render() {
    const { bank_subtype } = this.props

    const index = bank_subtype.indexOf('_') // Gets the first index where a space occours
    const bankCode = bank_subtype.substr(0, index) // Gets the first part
    const subtype = bank_subtype.substr(index + 1)
    return (
      <main>
        <CustomCard type={'allFiles'}>
          <CardHeader>
            <BulkOperation />
          </CardHeader>
          <AllFiles bankCode={bankCode} subtype={subtype} />
        </CustomCard>
      </main>
    )
  }
}

const CardHeader = styled.section`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 24px;
`

export default WithAuthorization(BankSubtype, SearchHeader)
