export enum fileStatusEnum {
  ALL = 'All',
  EX_TBP = 'To Be Processed',
  EX_PRO = 'Processing',
  EX_SUC = 'Extraction Successful',
  EX_FAI = 'Extraction Failed',
  EX_NOT = 'No Key Table Was Found'
}

export enum AllFilterEnum {
  ALL = 'ALL',
}
