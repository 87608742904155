import * as React from 'react'
import WithAuthorization from '../../components/WithAuthorization/WithAuthorization'
import UploadMultipleFile from './UploadMultipleFile'

class FilesUpload extends React.Component<any, any> {
  public render() {
    return (
      <main>
        <UploadMultipleFile />
      </main>
    )
  }
}

export default WithAuthorization(FilesUpload)
