export const OncheckAction = (arrayData: any, isChecked: boolean) => {
  return {
    type: 'oncheck',
    arrayData: arrayData,
    isChecked: isChecked,
  }
}
export const OnSelectAll = (arrayData: any, isChecked: boolean) => {
  return {
    type: 'onSelectAll',
    arrayData: arrayData,
    isChecked: isChecked,
  }
}
