import * as React from 'react'

const { Badge } = require('evergreen-ui')

interface IProps {
  pageRange: string | null
}

const FileRange: React.FunctionComponent<IProps> = ({ pageRange }: IProps) => (
  <Badge style={{ textTransform: 'capitalize', marginLeft: '10px' }}>
    <span style={{ fontWeight: 400 }}>Pages:</span>{' '}
    <span style={{ textTransform: 'lowercase' }}>{pageRange || 'n/a'}</span>
  </Badge>
)

export default FileRange
