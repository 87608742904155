import * as React from 'react'
import DisplayDownloadTask from '../../components/BulkDownload/BulkDownload'
import WithAuthorization from '../../components/WithAuthorization/WithAuthorization'

class BulkDownload extends React.Component<any> {
  public render() {
    //@ts-ignore
    return <DisplayDownloadTask location={this.props.location.state} />
  }
}

export default WithAuthorization(BulkDownload)
