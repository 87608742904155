import styled from 'styled-components'

const { Text, Heading } = require('evergreen-ui')

import { colors } from '../../utils/colors'

export const HeaderText = styled(Text)`
  font-size: 1rem;
  color: ${colors.white};
`

export const HeaderTextStrong = styled(Heading)`
  color: ${colors.white};
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 1.2rem;
  display: inline;
  margin: 0 4px;
`
