import * as request from 'superagent'
import { getApi } from '../utils/api/Api'
import { getAuthorizationHeaders } from './auth-api'
import { FilePath } from './api-constants'
const { toaster } = require('evergreen-ui')

export const existingConfigs = async () => {
  return request
    .get(getApi(FilePath.existingConfigs))
    .set(await getAuthorizationHeaders())
    .then((response: any) => {
      return response.body
    })
    .catch(e => {
      const error =
        e.response && e.response.body && e.response.body.error
          ? e.response.body.error
          : 'Unknown Error'
      toaster.danger(error)
    })
}

export const syncConfigsFromExcelToDb = async () => {
  return request
    .post(getApi(FilePath.syncConfigs))
    .set(await getAuthorizationHeaders())
    .then((response: any) => {
      return response.body
    })
    .catch(e => {
      const error =
        e.response && e.response.body && e.response.body.error
          ? e.response.body.error
          : 'Unknown Error'
      toaster.danger(error)
    })
}
