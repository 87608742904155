import * as React from 'react'
import WithAuthorization from '../../components/WithAuthorization/WithAuthorization'
import DisplayCancelTask from '../../components/BulkTaskCancellation/BulkTaskCancellation'

class BulkTaskCancel extends React.Component<any> {

  public render() {
    return <DisplayCancelTask />
  }

  
}


export default WithAuthorization(BulkTaskCancel)