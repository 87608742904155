import * as React from 'react'
import { colors } from '../../utils/colors'

const { Card } = require('evergreen-ui')

type cardType = 'allFiles' | 'default'

interface IProps {
  children: React.ReactNode
  type?: cardType
  verticalMargin?: number
  style?: React.CSSProperties
}

const CustomCard: React.FunctionComponent<IProps> = ({ type, children, style }: IProps) => {
  if (type === 'allFiles') {
    return (
      <Card
        background={colors.white}
        padding={24}
        elevation={2}
        minHeight={400}
        style={{ overflow: 'hidden', position: 'relative', ...style }}
      >
        {children}
      </Card>
    )
  }
  return (
    <Card background={colors.white} padding={24} style={{ overflow: 'hidden' }}>
      {children}
    </Card>
  )
}
CustomCard.defaultProps = {
  type: 'default',
  verticalMargin: 24,
}

export default CustomCard
