import * as React from 'react'
import TitleCard from '../TitleCard/TitleCard'
import Params from './Params'

interface IProps {
  params: string
  style?: React.CSSProperties
}

const ParamsCardContainer: React.FunctionComponent<IProps> = ({ params, style }: IProps) => (
  <TitleCard title={'Parameters'} icon={'comparison'} style={style}>
    <Params params={params} />
  </TitleCard>
)
export default ParamsCardContainer
