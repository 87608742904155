import { Paragraph } from 'evergreen-ui'
import * as React from 'react'
import styled from 'styled-components'
import { canopySimulator, downloadFile } from '../../api/canopy-simulator'
import { extractNameFromFile } from '../CanopySimulator/UploadFile'
const { Dialog, Button, TextInputField, withTheme, Heading } = require('evergreen-ui')

function CustomHeader() {
  return (
    <section>
      <Heading size={500} marginTop="default">
        Rerun Conversion
      </Heading>
      <Paragraph size={300} marginTop="default">
        Are you sure you want to rerun Conversion with the following details:
      </Paragraph>
    </section>
  )
}

class RerunConversion extends React.Component<any, any> {
  public static defaultProps = {
    buttonText: 'Rerun Conversion',
    buttonTheme: 'default',
  }
  public state = {
    isRerunDialogOpen: false,
    bank_name: this.props.bankName,
    subtype: this.props.subType,
    isLoading: false,
    recipe_file: undefined,
    error: '',
  }

  public render(): React.ReactNode {
    const { bank_name, subtype } = this.state
    const { recipe_file } = this.state
    const recipeLabel = extractNameFromFile(recipe_file, 'config')
    return (
      <>
        <Dialog
          contentContainerProps={{
            padding: 10,
          }}
          header={CustomHeader()}
          isShown={this.state.isRerunDialogOpen}
          onCloseComplete={this.closeDialog}
          confirmLabel={'Rerun Conversion'}
          hasFooter={false}
          hasHeader={true}
          title="Rerun Conversion"
          topOffset={'6vmin'}
          preventBodyScrolling={true}
        >
          <Containers>
            <section className="upload-section">
              <span>Recipe</span>
              <input
                className="inputfile"
                type="file"
                id="recipe"
                name="recipe"
                accept=".xls, .xlsx"
                onChange={(e: any) => {
                  this.setState({ recipe_file: e.target.files[0] })
                }}
              />
              <label className={this.props.theme.getButtonClassName()} htmlFor="recipe">
                {recipeLabel}
              </label>
            </section>
            <div
              style={{
                padding: '5px 10px',
              }}
            >
              <TextInputField
                onChange={this.handleBankCodeChange}
                value={bank_name}
                spellCheck={false}
                label={'Bank name'}
                placeholder={'Bank name'}
              />
            </div>
            <div
              style={{
                padding: '0 10px',
              }}
            >
              <TextInputField
                onChange={this.handlesubtypeChange}
                value={subtype}
                spellCheck={false}
                label={'subtype'}
                placeholder={'subtype'}
              />
            </div>
          </Containers>
          <section style={{ textAlign: 'center' }}>
            <Paragraph size={300} marginLeft={13}>
              <b>NOTE:</b> Either <b>Recipe</b> or both <b>Bank Name</b> and <b>Subtype</b>
              need to be provided. If <b>Recipe</b> is provided, <b>Bank Name</b> and <b>Subtype</b>{' '}
              are ignored.
            </Paragraph>
          </section>
          {this.state.error && <StyledAlert>{this.state.error}</StyledAlert>}
          <Button
            style={{
              margin: '5px 0',
              float: 'right',
            }}
            isLoading={this.state.isLoading}
            disabled={Boolean(!(recipe_file || (bank_name && subtype)))}
            appearance="primary"
            type={'submit'}
            onClick={this.onConfirmUpload}
          >
            Rerun
          </Button>
          <Button
            appearance="minimal"
            onClick={this.closeDialog}
            style={{
              margin: '5px 0',
              float: 'right',
            }}
          >
            Cancel
          </Button>
        </Dialog>
        <Button className="icon" onClick={this.showDialog}>
          {this.displayParser()}
        </Button>
      </>
    )
  }

  private onConfirmUpload = async () => {
    const { recipe_file, bank_name, subtype } = this.state
    this.setState({ isLoading: true, error: '' })
    canopySimulator(undefined, recipe_file, bank_name, subtype, this.props.fileId)
      .then(async (res: any) => {
        this.setState({ isLoading: false, error: '' })
        this.closeDialog()
        const { canopy_ul_file, log } = res.body['data']
        await downloadFile(canopy_ul_file)
        //navigate(`/canopysimulatorlog/`, { state: { log: log } })
      })
      .catch((e: any) => {
        const error =
          e.response && e.response.body && e.response.body.error
            ? e.response.body.error
            : 'Unknown Error'
        this.setState({ error: error, isLoading: false })
      })
  }

  private handleBankCodeChange = (e: any) => {
    this.setState({ bank_name: e.target.value.trim() })
  }

  private displayParser() {
    const { buttonText } = this.props
    return <span> {buttonText} </span>
  }

  private displayCancel() {
    return <span> {'Cancel'} </span>
  }
  private handlesubtypeChange = (e: any) => {
    this.setState({ subtype: e.target.value.trim() })
  }

  private showDialog = () => this.setState({ isRerunDialogOpen: true })
  private closeDialog = () => this.setState({ isRerunDialogOpen: false })
}

const Containers = styled.span`
  .errorMessage{
    color: red;
    font-size: 10px
    position: relative
    top: -20px;
  }
  section {
    margin-right: 20px;
    span {
      margin-right: 10px;
    }
  }
  .upload-section {
    padding: 1rem;
  }
  .inputfile {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    position: absolute;
    z-index: -1;
  }
  .inputfile + label {
    padding: 0.5rem;
    border-radius: 2px;
    width: 150px;
    overflow: hidden;
  }
  .inputfile + label {
    cursor: pointer;
  }
`

const StyledAlert = styled.div`
  background-color: #fff2f0;
  border: 1px solid #ffccc7;
  margin: 20px 0;
  padding: 10px;
`

export default withTheme(RerunConversion)
