import * as React from 'react'
import styled from 'styled-components'
import BaseItem from './BaseItem'

class TaskItem extends BaseItem {
  public displayFileName = () => {
    return super.displayFileName(this.props.item, this.props.itemType)
  }
  public render() {
    return (
      <Container>
        <div className="fileName">{this.displayFileName()}</div>
        <div>Not started</div>
      </Container>
    )
  }
}

const Container = styled.div`
  display: flex;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  padding: 20px;
  background-color: white;
  margin-bottom: 10px;
  .fileName {
    width: 50%;
  }
`

export default TaskItem
