import * as React from 'react'
import styled from 'styled-components'
import { getDisplayName, logoutApi } from '../../api/auth-api'
import { colors } from '../../utils/colors'
const { Icon } = require('evergreen-ui')

const { Popover, Text, Menu, Position } = require('evergreen-ui')

class UserAvatar extends React.Component {
  public render() {
    const UserName = getDisplayName()

    return (
      <Container>
        <Popover
          position={Position.BOTTOM_RIGHT}
          content={
            <Menu>
              <AvatarDetails>
                <Text className={'avatar-email'}>{UserName}</Text>
              </AvatarDetails>
              <Menu.Divider />
              <Menu.Group>
                <Menu.Item icon="log-out" intent="danger" onSelect={this.logout}>
                  Log-out
                </Menu.Item>
              </Menu.Group>
            </Menu>
          }
        >
          <Text size={500} className="avtar">
            {UserName}
            <Icon paddingTop={10} size={22} icon="chevron-down" />
          </Text>
        </Popover>
      </Container>
    )
  }

  private logout = async () => {
    await logoutApi()
  }
}

const AvatarDetails = styled.div`
  padding: 16px;
  span {
    display: block;
    user-select: none;
    padding: 2px 0;
  }
  .avatar-email {
    font-style: italic;
    padding-top: 10px;
  }
`

const Container = styled.div`
  .avatar-userprofile {
    cursor: pointer;
  }
  .avtar {
    color: ${colors.white};
    cursor: pointer;
    margin: 0 1rem 0 1rem;
  }
`

export default UserAvatar
