import { FilesStatus, HistoryStatus, BankStatus, FilterStatus } from '../schemaTypes'
import { AllFilterEnum } from '../components/FileStatus/file-status.utils'

export const colors = {
  primary: 'cyan',
  primaryDark: 'pink',
  white: '#fff',
  black: '#000',
  hoverBackground: 'rgba(14,30,37,0.05)',
  darkBlack: '#0e1e25',
  blue: '#116AB8'
}

export const getFileStatusBadgeColor = (status: FilesStatus | HistoryStatus) => {
  switch (status) {
    case FilesStatus.EX_FAI:
      return 'red'
    case FilesStatus.EX_SUC:
      return 'green'
    case FilesStatus.EX_TBP:
      return 'orange'
    case FilesStatus.EX_PRO:
      return 'yellow'
    case FilesStatus.EX_NOT:
       return 'blue'
    default:
      return 'purple'
  }
}

export const getBankStatusBadgeColor = (status: BankStatus) => {
  switch (status) {
    case BankStatus.BS_BNK:
      return 'red'
    case BankStatus.BS_NR:
      return 'blue'
    case BankStatus.BS_PEN:
      return 'purple'
    case BankStatus.BS_RUN:
      return 'yellow'
    case BankStatus.BS_SUB:
      return 'green'
    case BankStatus.BS_SRN:
        return 'orange'
    default:
      return 'blue'
  }
}

export const getFileStatusStripeColor = (
  status: FilesStatus | AllFilterEnum | HistoryStatus | BankStatus,
) => {
  switch (status) {
    case AllFilterEnum.ALL:
      return '#fff'
    case FilesStatus.EX_FAI:
      return '#BF0E08'
    case FilesStatus.EX_SUC:
      return '#00783E'
    case FilesStatus.EX_TBP:
      return '#425A70'
    case FilesStatus.EX_PRO:
      return '#F7D154'
    case BankStatus.BS_BNK:
      return 'red'
    case BankStatus.BS_NR:
      return 'green'
    case BankStatus.BS_PEN:
      return 'purple'
    case BankStatus.BS_RUN:
      return 'yellow'
    case BankStatus.BS_SUB:
      return 'orange'
    default:
      return '#14B5D0'
  }
}

export const getTextColor = (isErrorText:boolean) => {
  if(isErrorText){
    return 'red'
  }
  return colors.black
}

// export const getFilterColor = (status: FilterStatus ) => {
//   switch (status) {
//     case FilterStatus.ALL:
//       return '#fff'
//     case FilesStatus.EX_FAI:
//       return '#BF0E08'
//     case FilesStatus.EX_SUC:
//       return '#00783E'
//     case FilesStatus.EX_TBP:
//       return '#425A70'
//     case FilesStatus.EX_PRO:
//       return '#F7D154'
//     default:
//       return '#14B5D0'
//   }

// }
