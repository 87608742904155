import * as React from 'react'
import styled from 'styled-components'
import { extractFileDataFromS3Key } from '../../utils/s3'
import {cleanInputFile, FILE_NOT_AVAILABLE} from '../../utils/FileUtils'
import { getTextColor } from '../../utils/colors'
import {getDisplayFieldByItemType, ItemType } from './utils'
class BaseItem extends React.Component<any> {
  public displayFileName(item:any, itemType:ItemType) {
    const displayInfo = getDisplayFieldByItemType(item, itemType)
    var displayValue = null
    if(displayInfo.isAvailable){
      if (displayInfo.isS3key){
        const { filename } = extractFileDataFromS3Key(displayInfo.field)
        displayValue = filename.split(/[/]+/).pop()
      }else{
        displayValue = cleanInputFile(displayInfo.field)
      }
    }else{
      displayValue = FILE_NOT_AVAILABLE
    }
    return <span style={{ color: getTextColor(!displayInfo.isAvailable) }} >{displayValue}</span>
  }
}

export default BaseItem
