import * as React from 'react'
import styled from 'styled-components'
import { parserGetLog } from '../../api/parser-log-api'
import { colors } from '../../utils/colors'
import TitleCard from '../TitleCard/TitleCard'
const { Button, toaster } = require('evergreen-ui')
var wrap = require('word-wrap')

class ParamsLogContainer extends React.Component<any> {
  public state = {
    parserText: [],
    logs: '',
  }
  public componentDidMount() {
    const taskId = this.props.params
    parserGetLog(taskId).then((result: any) => {
      if (result){
      this.setState({
        parserText: result.split('\n'),
        logs: result,
      })
     }else{
      const msg = 'Not available'
      this.setState({
        parserText: [msg],
        logs: msg,
      })
     }
    })
  }
  private displayLog = () => {
    return this.state.parserText.map((item: any, index: number) => {
      return (
        <div style={{ display: 'flex', width: '100%' }}>
          <div style={{ width: '40px' }}>{index + 1}.</div>
          <div style={{ lineHeight: 1.6 }}>{item}</div>
        </div>
      )
    })
  }
  public render() {
    // @ts-ignore
    const hasClipboardApi = navigator.clipboard

    const toasterOptions = { duration: 2, id: 'unique-toaster' }
    return (
      <>
        <TitleCard
          title={'Parser Log'}
          icon={'comparison'}
          actionComponent={
            <div>
              {hasClipboardApi && (
                <Button
                  iconBefore={'clipboard'}
                  appearance="minimal"
                  onClick={() => {
                    // @ts-ignore
                    navigator.clipboard
                      .writeText(this.state.logs)
                      .then(() => toaster.success('Copied!', toasterOptions))
                      .catch(() => toaster.danger('Failed to Copy', toasterOptions))
                  }}
                >
                  Copy
                </Button>
              )}
            </div>
          }
        >
          <Container>{this.displayLog()}</Container>
        </TitleCard>
      </>
    )
  }
}

export default ParamsLogContainer

const Container = styled.div`
  background-color: ${colors.darkBlack};
  overflow: auto;
  padding: 1rem;
  margin: -12px -24px -24px -24px;
  font-size: 0.9rem;
  line-height: 1.2rem;
  position: relative;
  z-index: 2;
  border-radius: 0 0 5px 5px;
  color: rgba(255, 255, 255, 0.9);
  font-family: monospace, monospace;
`
