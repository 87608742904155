import * as React from 'react'
import { connect } from 'react-redux'
const { Button, Heading, TextInput, toaster } = require('evergreen-ui')
import JSZip from 'jszip'
import { saveAs } from 'file-saver'
import TaskItem from './TaskItem';
import styled from 'styled-components'
import ResponseItem from './ResponseItem'
import { canopySimulator } from '../../api/canopy-simulator'
import { extractNameFromFile } from '../CanopySimulator/UploadFile'
import { FilesStatus } from '../../schemaTypes'
import { FILE_NOT_AVAILABLE, convertBase64StringToBlob } from '../../utils/FileUtils'
import { colors } from '../../utils/\/colors'

class DisplayRestartCoversionTask extends React.Component<any> {
  public state = {
    taskResponseData: [],
    isLoading: false,
    count: 0,
    bankName: '',
    subtype: '',
    recipe_file: undefined,
  }

  private displayResponse = () => {
    return this.state.taskResponseData.map((item: any) => {
      return <ResponseItem item={item} />
    })
  }

  private responseHeader = () => {
    return (
      <Header>
        <div className="fileName">File Name</div>
        <div className="status">Status</div>
        <div>Message</div>
      </Header>
    )
  }

  private onfileDownloaded(item: any, processCount: number, isSuccess: boolean, message: string, zip: JSZip, zipFile: string, taskResponseData: any[]): void {
    const temp = {
      item: item,
      success: isSuccess,
      message: message,
    }
    taskResponseData.push(temp)
    this.setState({
      taskResponseData: taskResponseData,
    })

    const successCount = Object.keys(zip.files).length
    this.setState({
      count: processCount
    })
    if (processCount == this.props.bulkData.length && successCount > 0) {
      this.setState({
        isLoading: false,
      })
      zip
        .generateAsync({
          type: 'blob',
        })
        .then((content: any) => {
          saveAs(content, zipFile + '.zip')
        })
    }
  }

  private restartTaskConversions = async () => {
    const { recipe_file, bankName, subtype } = this.state
    this.setState({ isLoading: true })
    var processCount = 0
    var zip = new JSZip()
    const zipFile = 'canopy-ul'
    var cancelResponseData: any[] = []
    for (let item of this.props.bulkData) {
      if (item.parsingStatus == FilesStatus.EX_SUC) {
        canopySimulator(undefined, recipe_file, bankName, subtype, item.fileUuid)
          .then(async (res: any) => {
            const { canopy_ul_file, log } = res.body['data']
            const blob = convertBase64StringToBlob(canopy_ul_file, 'application/zip')
            zip.loadAsync(blob).then(() => {
              processCount = processCount + 1
              this.onfileDownloaded(item, processCount, true, 'successful', zip, zipFile, cancelResponseData)
            })
          })
          .catch((e: any) => {
            const error =
              e.response && e.response.body && e.response.body.error
                ? e.response.body.error
                : 'Unknown Error'
            processCount = processCount + 1
            this.onfileDownloaded(item, processCount, false, error, zip, zipFile, cancelResponseData)
          })
      } else {
        processCount = processCount + 1
        this.onfileDownloaded(item, processCount, false, FILE_NOT_AVAILABLE, zip, zipFile, cancelResponseData)
      }
    }
  }

  private displayConversionButton = () => {
    const { recipe_file, bankName, subtype } = this.state
    return (<Button onClick={this.restartTaskConversions} appearance="primary"
      disabled={Boolean(!(recipe_file || (bankName && subtype)))}
      isLoading={this.state.isLoading}>
      Rerun Conversions
    </Button>)
  }

  private displayTableHeader() {
    return (
      <Header>
        <div className="fileName">File Name</div>
        <div>Status</div>
      </Header>
    )
  }

  private displayTable = () => {
    return this.props.bulkData.map((item: any) => {
      return <TaskItem item={item} />

    })
  }

  private displyResponseDataTable() {
    if (this.state.taskResponseData.length > 0) {
      return (
        <>
          {this.responseHeader()}
          {this.displayResponse()}
        </>
      )
    } else if (this.props.bulkData.length > 0) {
      const { recipe_file } = this.state
      const recipeLabel = extractNameFromFile(recipe_file, 'config')
      return (
        <>
          <ParserContainer>
            <section className="upload-section">
              <span>Recipe</span>
              <input
                className="inputfile"
                type="file"
                id="recipe"
                name="recipe"
                accept=".xls, .xlsx"
                onChange={(e: any) => {
                  this.setState({ recipe_file: e.target.files[0] })
                }}
              />
            </section>
            <section className="upload-section">
              <TextInput
                required
                onChange={(e: any) => this.setState({ bankName: e.target.value.trim() })}
                name="bankName"
                placeholder="Bank name"
                width={200}
              />
            </section>
            <section className="upload-section">
              <TextInput
                required
                onChange={(e: any) => this.setState({ subtype: e.target.value.trim() })}
                name="subtype"
                placeholder="subtype"
                width={200}
              />
            </section>
          </ParserContainer>
          <MandatoryInfo>* Either Recipe file or Bank and Subtype are mandatory</MandatoryInfo>
          {this.displayTableHeader()}
          {this.displayTable()}
          {this.displayConversionButton()}
        </>
      )
    } else {
      return null
    }
  }

  public render() {
    const totalCount = this.props.bulkData.length
    return (
      <Container>
        <div className="cardHeader">
          <Heading size={700} color={colors.blue} className="title">Restart Conversion Task List</Heading>
          <Heading size={700} color={colors.blue} className="count" marginBottom={10}>
            <span>Completed:</span>{this.state.count} of {totalCount}
          </Heading>
        </div>
        {this.displyResponseDataTable()}
      </Container>
    )
  }
}

const mapStateToProps = (storeData: any) => {
  return {
    bulkData: storeData.onCheckReducer.bulkOperationData,
    params: storeData.onChangeReducer.params,
  }
}

const ParserContainer = styled.div`
display: flex;
padding: 20px;
.upload-section{
  width: 33%;
}
`
const Container = styled.div`
box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.1);
border-radius: 5px;
padding: 20px;
background-color: white;
.cardHeader{
  border-bottom: 2px solid #F5F5F5;
  display: flex
  .title{
    width: 80%;
  }
  .count{
    span{
      padding-right: 10px;
    }
  }
}
`

const Header = styled.div`
display: flex;
background-color: #F5F5F5;
box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.1);
border-radius: 5px;
padding: 20px;
margin-bottom: 10px;
font-weight: bold;
.fileName{
    width: 50%;
}
.status{
  width: 10%;
}
.
`

const MandatoryInfo = styled.span`
height: 29px;
text-align: right;
width: 9rem;
margin-left: 16px;
color:red;
`

export default connect(mapStateToProps, null)(DisplayRestartCoversionTask)