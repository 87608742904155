import * as React from 'react'
import styled from 'styled-components'
const { Spinner } = require('evergreen-ui')
const { Icon } = require('evergreen-ui')
class ResponseItem extends React.Component<any> {
  display() {
    if (this.props.item.pdf) {
      const { pdf, bankName, subtype, status, isSuccess } = this.props.item
      return (
        <Container>
          <div className="flex-item">{pdf['name']}</div>
          <div className="flex-item">{bankName}</div>
          <div className="flex-item">{subtype}</div>
          <div className="flex-item">{isSuccess ? (<><Icon icon="tick-circle" color="success" /></>) :
            (<><Icon icon="cross" color="danger" /></>)
          }</div>
          <div className="flex-item">{status}</div>
        </Container>
      )
    } else {
      return <Spinner size={60} marginX="auto" />
    }
  }

  public render() {
    return <>{this.display()}</>
  }
}

export default ResponseItem

const Container = styled.li`
   {
    display: flex;
    width: 100%;
    .flex-item {
      width: 25%;
      padding: 10px;
      overflow: hidden;
      text-align: center;
    }
  }
`
