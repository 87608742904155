import * as request from 'superagent';
import { getApi } from '../utils/api/Api';
import { CanopySimulator } from './api-constants';
import { getAuthorizationHeaders } from './auth-api';
import { downloadBlob, convertBase64StringToBlob } from '../utils/FileUtils'
export const canopySimulator = (
  uploadExcelFile: any,
  recipe: any,
  bankName: string,
  subtype: string,
  fileId?: string,
) => {
  return new Promise(async (resolve, reject) => {
    const req = request.post(getApi(CanopySimulator.uploadFile))
    req
      .set(await getAuthorizationHeaders())
      .field('bank_name', bankName)
      .field('subtype', subtype)
      .attach('recipe', recipe)
    fileId && req.field('task_id', fileId)
    uploadExcelFile &&
      uploadExcelFile.forEach((element: any) => {
        req.attach('excel', element)
      })
    req.end((error: any, res: any) => {

      error ? reject(error) : resolve(res);
    });
  })
}

export const downloadFile = (canopy_ul_file: any) => {
  const name = 'canopySimulator'
  const blob = convertBase64StringToBlob(canopy_ul_file, 'application/zip')
  downloadBlob(blob, name)
}

export const replaceParsedOutput = (
  parsedOutput: any,
  runConversion: boolean,
  fileId: string,
) => {
  return new Promise(async (resolve, reject) => {
    const req = request.post(getApi(CanopySimulator.replaceParsedFile))
    req
      .set(await getAuthorizationHeaders())
      .field('file_id', fileId)
      .field('run_conversion', runConversion)
      .attach('parsed_output', parsedOutput)

    req.end((error: any, res: any) => {

      error ? reject(error) : resolve(res);
    });
  })
}
