export const INITAL_STATE = {
  bulkOperationData: [],
  checked: false,
  shown: false,
}

export const onCheckReducer = (state = INITAL_STATE, action: any) => {
  switch (action.type) {
    case 'oncheck':
      var data: any[] = []
      data = [...state.bulkOperationData]
      const temp = action.arrayData
      const index = data.indexOf(temp)
      if (index < 0) {
        data.push(temp)
      } else if (index >= 0) {
        data.splice(index, 1)
      }
      const obj = Object.assign({}, state, {
        bulkOperationData: data,
      })
      return obj

    case 'onclick':
      const toggleObj = Object.assign({}, state, {
        bulkOperationData: action.arrayData,
      })
      return toggleObj
    case 'onSelectAll':
      var data: any[] = []
      data = [...state.bulkOperationData]
      const _obj = Object.assign({}, state, {
        bulkOperationData: action.arrayData,
      })
      return _obj
    default:
      return state
  }
}
