import * as React from 'react'
import gql from 'graphql-tag'
import { Query } from 'react-apollo'
import QueryError from '../../components/QueryError/QueryError'
const { Spinner } = require('evergreen-ui')
import styled from 'styled-components'
import TitleCard from '../../components/TitleCard/TitleCard'

class GetAllFiles extends React.Component<any> {
  public render() {
    return (
      <Query query={GET_ALL_FILES}>
        {({ loading, error, data }) => {
          if (error) {
            return <QueryError error={error} />
          }
          if (loading) {
            return (
              <Loader>
                <Spinner marginX="auto" marginY={120} size={72} />
              </Loader>
            )
          }
          if (!data) {
            return (
              <div>
                <pre>{<code>{JSON.stringify(error, null, 2)}</code>}</pre>
              </div>
            )
          } else {
            const totalCount = data['allFiles']['totalCount']
            return (
              <TitleCard title={'Stats Page'} icon={'comparison'} style={{ width: '50%' }}>
                <h3>Total no of files: {totalCount}</h3>
              </TitleCard>
            )
          }
        }}
      </Query>
    )
  }
}

const GET_ALL_FILES = gql`
  query {
    allFiles {
      totalCount
      edgeCount
    }
  }
`
const Loader = styled.div``

export default GetAllFiles
