import * as React from 'react'
import styled from 'styled-components'
import { FilterStatusEnum, AllFilterEnum } from '../FileStatusFilter/FilterStatusEnum'
const { Dialog,Button, Icon,  Select,SelectField,TextInput,TextInputField, Table, Tooltip, withTheme } = require('evergreen-ui')
const moment = require('moment')



export class  FilterFields {
  clientId: string ='';
  bankCode: string ='';
  subType: string ='';
  fileName: string ='';
  stashboardTaskId: string ='';
  stashboardJobId: string = '';
  parentId: string = '';
  jobStatus: string = AllFilterEnum.ALL;
  updatedAtGteInput: string = '';
  updatedAtLteInput: string = '';
  updatedAtGte: string|null = null;
  updatedAtLte: string|null = null;
}

interface IProps {
  onFilterChange: (filterValue: FilterFields) => void,
  displayInline: boolean
}

interface IState {
  isDialogOpen: boolean
  filterValue: FilterFields
  appliedFilter:boolean
  error: string
}

const initialState: IState = {
  isDialogOpen: false,
  filterValue: new FilterFields(),
  appliedFilter: false,
  error: '',
}

class MainFilter extends React.Component<IProps, IState> {
  public static defaultProps = {
    buttonText: 'Filter',
    buttonTheme: 'default',
  }
  public state = initialState

  private showDialog = () => this.setState({ isDialogOpen: true })
  private closeDialog = () =>   {
    if(this.state.appliedFilter){
      this.setState({ isDialogOpen: false })
    }else{
      this.setState(previousState => ({
        ...initialState
      }))
    }
  }

  private updatefilterValue = (fieldName:string, fieldValue:any) =>  {
    const newfilterValue = {
      ...this.state.filterValue,
      [fieldName]:fieldValue
    }
    this.setState({filterValue:newfilterValue})
  }

  private fieldOnChange = (e:any) => {
    this.updatefilterValue(e.target.name, e.target.value)
  }

  private onChangeJobStatusSelect = (event: any) => {
    const selected = event.target.value
    this.updatefilterValue("jobStatus", selected)
  }

  private onApply = async () =>  {

    const filterValue = this.state.filterValue
    const numberPattern = /^[0-9]+$/
    if(filterValue.stashboardTaskId){
      const stashboardTaskId = filterValue.stashboardTaskId.trim()
      if(!stashboardTaskId.match(numberPattern) || !(+stashboardTaskId)){
        this.setState({error:'Stashboard TaskId can contain only numbers and needs to be greater than zero'})
        return
      }else{
        this.state.filterValue.stashboardTaskId = stashboardTaskId.toString()
      }
    }
    if(filterValue.stashboardJobId){
      const stashboardJobId = filterValue.stashboardJobId.trim()
      if(!stashboardJobId.match(numberPattern) || !(+stashboardJobId)){
        this.setState({error:'Stashboard JobId can contain only numbers and needs to be greater than zero'})
        return
      }else{
        this.state.filterValue.stashboardJobId = stashboardJobId.toString()
      }
    }
    if(filterValue.parentId){
      const parentId = filterValue.parentId.trim()
      if(!parentId.match(numberPattern) || !(+parentId)){
        this.setState({error:'ParentId can contain only numbers and needs to be greater than zero'})
        return
      }else{
        this.state.filterValue.parentId = parentId.toString()
      }
    }
    let updatedAtGte = null
    if(filterValue.updatedAtGteInput){
        updatedAtGte = moment(filterValue.updatedAtGteInput, "YYYY-MM-DD", true)
        updatedAtGte.utc(true)
        this.state.filterValue.updatedAtGte = updatedAtGte.startOf('day').toDate().toJSON()

    }else{
      this.state.filterValue.updatedAtGte =null
    }
    if(filterValue.updatedAtLteInput){
      const updatedAtLte = moment(filterValue.updatedAtLteInput, "YYYY-MM-DD", true)
        updatedAtLte.utc(true)
        this.state.filterValue.updatedAtLte = updatedAtLte.endOf('day').toDate().toJSON()

        if (updatedAtGte && (updatedAtGte > updatedAtLte)){
          this.setState({error:" `From date` should be less than or equal to `To date`"})
          return
        }

    }else{
      this.state.filterValue.updatedAtLte =null
    }
      
    this.props.onFilterChange(filterValue)
    if (this.props.displayInline){
      await this.setState({appliedFilter:true, error:''})
    }else{
      await this.setState({appliedFilter:true, error:'', isDialogOpen:false})
    }
    
  }

  private onReset = async () =>  {
    await this.setState(previousState => ({
      ...initialState,
      isDialogOpen:true
    }))
    this.props.onFilterChange(this.state.filterValue)
  }

  private toggleFilterDisplay = () => {
    this.setState({ isDialogOpen: (!this.state.isDialogOpen) })
  }

  private toggleFilterTooltip = () => {
    return this.state.isDialogOpen ? "Hide Filter Section": "Show Filter Section"
  }

  public renderInlineFilter(maxUpdatedAt:any ): React.ReactNode {
    return (
        <>
          <Tooltip content={this.toggleFilterTooltip()}>
            <Button className="icon" onClick={this.toggleFilterDisplay}>
              <Icon icon={'filter'}  color={'muted'} size={16} />
            </Button>
          </Tooltip>
         <FlexContainer style={{ display:this.state.isDialogOpen ? "block": "none" }}>
            <Table >
              <Table.Body className='sectionBody'>
                <Table.Row height="auto" className='sectionRow'  >
                  <Table.Cell className='sectionCell' >
                    <div className="textBoxSpacing">
                      <TextInputField
                        onChange={this.fieldOnChange}
                        value={this.state.filterValue.clientId}
                        containerProps={{}}
                        spellCheck={false}
                        name={"clientId"}
                        label={'ClientId'}
                        placeholder={'Enter ClientId'}
                      />
                    </div>              
                  </Table.Cell>
                  <Table.Cell className='sectionCell' >
                    <div className="textBoxSpacing">
                      <TextInputField
                        onChange={this.fieldOnChange}
                        value={this.state.filterValue.bankCode}
                        containerProps={{}}
                        spellCheck={false}
                        name={"bankCode"}
                        label={'Bank'}
                        placeholder={'Enter Bank code'}
                      />
                    </div>              
                  </Table.Cell>
                  <Table.Cell className='sectionCell' >
                    <div className="textBoxSpacing">
                      <TextInputField
                        onChange={this.fieldOnChange}
                        value={this.state.filterValue.subType}
                        containerProps={{}}
                        spellCheck={false}
                        name={"subType"}
                        label={'SubType'}
                        placeholder={'Enter SubType'}
                      />
                    </div>              
                  </Table.Cell>
                  <Table.Cell className='sectionCell' >
                    <div className="textBoxSpacing">
                      <TextInputField
                        onChange={this.fieldOnChange}
                        value={this.state.filterValue.fileName}
                        containerProps={{}}
                        spellCheck={false}
                        name={"fileName"}
                        label={'FileName'}
                        placeholder={'Enter FileName'}
                      />
                    </div>              
                  </Table.Cell>
                </Table.Row>
                <Table.Row height="auto" className='sectionRow'  >
                  <Table.Cell className='sectionCell' >
                    <div className="textBoxSpacing">
                      <TextInputField
                        onChange={this.fieldOnChange}
                        value={this.state.filterValue.stashboardTaskId}
                        containerProps={{}}
                        spellCheck={false}
                        name={"stashboardTaskId"}
                        label={'Stashboard TaskId'}
                        placeholder={'Enter TaskId (number)'}
                      />
                    </div>              
                  </Table.Cell>
                  <Table.Cell className='sectionCell' >
                    <div className="textBoxSpacing">
                      <TextInputField
                        onChange={this.fieldOnChange}
                        value={this.state.filterValue.stashboardJobId}
                        containerProps={{}}
                        spellCheck={false}
                        name={"stashboardJobId"}
                        label={'Stashboard JobId'}
                        placeholder={'Enter JobId (number)'}
                      />
                    </div>              
                  </Table.Cell>
                  <Table.Cell className='sectionCell' >
                    <div className="textBoxSpacing">
                      <TextInputField
                        onChange={this.fieldOnChange}
                        value={this.state.filterValue.parentId}
                        containerProps={{}}
                        spellCheck={false}
                        name={"parentId"}
                        label={'Parent Id'}
                        placeholder={'Enter ParentId (number)'}
                      />
                    </div>              
                  </Table.Cell>
                  <Table.Cell className='sectionCell' >
                    <div className="textBoxSpacing">
                    <SelectField 
                      label={'Status'}
                      onChange={this.onChangeJobStatusSelect} 
                      defaultValue={this.state.filterValue.jobStatus}
                      value={this.state.filterValue.jobStatus}>
                      {Object.entries(FilterStatusEnum).
                      map(([val, label]) => (<option value={val}>{label}</option>))}
                    </SelectField>
                    </div>              
                  </Table.Cell>
                </Table.Row>
                <Table.Row height="auto" className='sectionRow'  >
                  <Table.Cell className='sectionCell' >
                    <div className="textBoxSpacing">
                      <span>Date Range</span>
                      <div>
                        <input  type="date" name="updatedAtGteInput" className="inputDate" width={120} placeholder = "From" title='From'
                            value={this.state.filterValue.updatedAtGteInput}
                            max={maxUpdatedAt}
                            onChange={this.fieldOnChange}
                          />
                        <span className = "rangeSpan"> To </span>
                        <input  type="date" name="updatedAtLteInput" className="inputDate"  width={120} placeholder = "To" title='To'
                            value={this.state.filterValue.updatedAtLteInput}
                            max={maxUpdatedAt}
                            onChange={this.fieldOnChange}
                        />
                      </div>
                    </div>              
                  </Table.Cell>
                  <Table.Cell className='sectionCell' span={2} >
                    <div className="textBoxSpacing">
                      <Button
                        appearance="primary"
                        className='filterButton'
                        type={'submit'}
                        onClick={() => this.onApply()}>
                        Apply
                      </Button>
                      
                      <Button
                        appearance="default"
                        className='filterButton'
                        onClick={() => this.onReset()}>
                        Reset
                      </Button>
                    </div>
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
            {this.state.error && <StyledAlert>{this.state.error}</StyledAlert>}
          </FlexContainer>
        </>
      )
  }

  public renderFilterInDialog(maxUpdatedAt:any): React.ReactNode {
    return (
        <>
          <Dialog
            contentContainerProps={{
              padding: 10,
            }}
            isShown={this.state.isDialogOpen}
            onCloseComplete={this.closeDialog}
            confirmLabel={'Filter'}
            hasFooter={false}
            hasHeader={true}
            title="Filter"
            topOffset={'6vmin'}
            preventBodyScrolling={true}
          >
            <Container>
            <section className="flex-container">
            <span>ClientId</span>
            <TextInput
              width={200}
              name={"clientId"}
              value={this.state.filterValue.clientId}
              placeholder="Enter ClientId"
              onChange={this.fieldOnChange}
            />
          </section>
          <section className="flex-container">
            <span>Bank</span>
            <TextInput
              width={200}
              name={"bankCode"}
              value={this.state.filterValue.bankCode}
              placeholder="Enter Bank code"
              onChange={this.fieldOnChange}
            />
          </section>
          <section className="flex-container">
            <span>Subtype</span>
            <TextInput
              width={200}
              name={"subType"}
              value={this.state.filterValue.subType}
              placeholder="Enter Subtype"
              onChange={this.fieldOnChange}
            />
          </section>
          <section className="flex-container">
            <span>Filename</span>
            <TextInput
              width={200}
              name={"fileName"}
              value={this.state.filterValue.fileName}
              placeholder="Enter Filename"
              onChange={this.fieldOnChange}
            />
          </section>
          <section className="flex-container">
            <span>Stashboard TaskId</span>
            <TextInput
              width={200}
              name={"stashboardTaskId"}
              value={this.state.filterValue.stashboardTaskId}
              placeholder="Enter TaskId (number)"
              onChange={this.fieldOnChange}
            />
          </section>
          <section className="flex-container">
            <span>Stashboard JobId</span>
            <TextInput
              width={200}
              name={"stashboardJobId"}
              value={this.state.filterValue.stashboardJobId}
              placeholder="Enter JobId (number)"
              onChange={this.fieldOnChange}
            />
          </section>
          <section className="flex-container">
            <span >Parent Id</span>
            <TextInput
              width={200}
              name={"parentId"}
              value={this.state.filterValue.parentId}
              placeholder="Enter ParentId (number)"
              onChange={this.fieldOnChange}
            />
          </section>
          <section className="flex-container">
            <span>
              Status
            </span>
            <Select width={200}  flex={false}
            onChange={this.onChangeJobStatusSelect} 
            defaultValue={this.state.filterValue.jobStatus}>
                    {Object.entries(FilterStatusEnum).
                    map(([val, label]) => (<option value={val}>{label}</option>))}
            </Select>
          </section>
          <section className="flex-container">
            <span>Date Range</span>
            <Range>
            {/* <TextInput
              width={120}
              name={"updatedAtGteInput"}
              value={this.state.filterValue.updatedAtGteInput}
              placeholder="from (dd/mm/yyyy)"
              onChange={this.fieldOnChange}
            />  */}
            <input  type="date" name="updatedAtGteInput" className="inputDate" width={120} placeholder = "From" title='From'
            value={this.state.filterValue.updatedAtGteInput}
            max={maxUpdatedAt}
            onChange={this.fieldOnChange}
             />
            <span> To </span>
             {/* <TextInput
              width={120}
              name={"updatedAtLteInput"}
              value={this.state.filterValue.updatedAtLteInput}
              placeholder="to (dd/mm/yyyy)"
              onChange={this.fieldOnChange}
            /> */}
            <input  type="date" name="updatedAtLteInput" className="inputDate"  width={120} placeholder = "To" title='To'
            value={this.state.filterValue.updatedAtLteInput}
            max={maxUpdatedAt}
            onChange={this.fieldOnChange}
            />
            </Range>
          </section>
            </Container>
            {this.state.error && <StyledAlert>{this.state.error}</StyledAlert>}
            <Button
              style={{
                margin: '5px 0',
                float: 'right',
              }}
              appearance="primary"
              type={'submit'}
              onClick={() => this.onApply()}
            >
              Apply
            </Button>
            <Button
              appearance="default"
              onClick={() => this.onReset()}
              style={{
                margin: '5px 0',
                float: 'right',
              }}
            >
              Reset
            </Button>
          </Dialog>
          <Tooltip content="Filter Jobs">
          <Button className="icon" onClick={this.showDialog}>
            <Icon icon={'filter'}  color={'muted'} size={16} />
          </Button>
          </Tooltip>
        </>
      )
  }

  public render(): React.ReactNode {
    //const options = Object.entries(FilterStatusEnum).filter(([val, label]) => val != 'ALL').map(([val, label]) => <option value={val}>{label}</option>)
    const maxUpdatedAt = moment(new Date()).format("YYYY-MM-DD");

    if (this.props.displayInline){
      return this.renderInlineFilter(maxUpdatedAt)

    }else{
      return this.renderFilterInDialog(maxUpdatedAt)
    }
  }
}





const Container = styled.span`
  .errorMessage{
    color: red;
    font-size: 10px
    position: relative
    top: -20px;
  }
  .flex-container {
      display: flex;
      width: 100%;
      padding: 3px 3px;
      span {
        width: 28%;
        font-weight: bold;
        color: #234361;
      }
  }
  section {
    width: 20%;
  }
  .inputDate{
      -webkit-appearance: none;
      -moz-appearance: none;
      border: none;
      border-radius: 3px;
      background-color: white;
      box-shadow: inset 0 0 0 1px rgba(67, 90, 111, 0.3), inset 0 1px 2px rgba(67, 90, 111, 0.14);
      font-family: "SF UI Text", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
      font-size: 12px;
      font-weight: 400;
      color: #425A70;
      box-sizing: border-box;
      padding-left: 10px;
      letter-spacing: 0;
      line-height: 16px;
      height: 32px;
    }
  
`

const StyledAlert = styled.div`
  background-color: #fff2f0;
  border: 1px solid #ffccc7;
  margin: 20px;
  padding: 10px;
`

const Range = styled.div`
 display: flex
 span {
    width: auto;
    padding:8px;
  }  
`
const FlexContainer = styled.div`
  font-size: 0.9rem;
  line-height: 1.2rem;
  position: relative;
  z-index: 2;
  font-family: monospace, monospace;
  border-width: 3px;
  border-style: solid;
  border-color: #D3D3D3;
  border-radius: 25px;
  margin-top: 20px;
  background-color: white;

  .sectionBody{
   padding:6px;
  }
  .sectionRow{
    border-bottom-width:0px;
  }
  .sectionCell{
    //overflow: auto;
  }
  .textBox {
    margin-top: 16px;
  }
  .textBoxSpacing {
    margin-top: 2px;
  }
  span{
    font-family: "SF UI Text", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 14px;
    font-weight: 500;
    color: #425A70;
    box-sizing: border-box;
    line-height: 20px;
    letter-spacing: -0.05px;
  }
  .rangeSpan{
    padding:8px;
  }
  .filterButton{
    margin-top: 20px;
    float:right;
    margin-right:8px;
  }
  
  .inputDate{
    -webkit-appearance: none;
    -moz-appearance: none;
    border: none;
    border-radius: 3px;
    background-color: white;
    box-shadow: inset 0 0 0 1px rgba(67, 90, 111, 0.3), inset 0 1px 2px rgba(67, 90, 111, 0.14);
    font-family: "SF UI Text", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 12px;
    font-weight: 400;
    color: #425A70;
    box-sizing: border-box;
    padding-left: 10px;
    letter-spacing: 0;
    line-height: 16px;
    height: 32px;
  }
`

export default withTheme(MainFilter)