import * as React from 'react'

const { Badge } = require('evergreen-ui')

interface IProps {
  taskId: number | null
}

const TaskId: React.FunctionComponent<IProps> = ({ taskId }: IProps) => (
  <Badge style={{ textTransform: 'capitalize', marginLeft: '10px' }}>
    <span style={{ fontWeight: 400 }}>Stashboard Task ID:</span>{' '}
    <span style={{ textTransform: 'lowercase' }}>{taskId || 'n/a'}</span>
  </Badge>
)

export default TaskId
