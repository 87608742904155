import * as React from 'react'

const { Pill } = require('evergreen-ui')

import { getFileStatusBadgeColor } from '../../utils/colors'
import { FilesStatus, HistoryStatus } from '../../schemaTypes'
import { fileStatusEnum } from './file-status.utils'

interface IProps {
  status: FilesStatus | HistoryStatus
}

const FileStatus: React.FunctionComponent<IProps> = ({ status }: IProps) => (
  <Pill style={{ textTransform: 'capitalize' }} color={getFileStatusBadgeColor(status)}>
    {fileStatusEnum[status]}
  </Pill>
)

export default FileStatus
