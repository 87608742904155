import * as request from 'superagent'
import { paramsObjectFromServerType } from '../components/RerunParser/RerunParserForm'
import { getApi } from '../utils/api/Api'
import { FilePath } from './api-constants'
import { getAuthorizationHeaders } from './auth-api'

interface IUploadNewFileResponse {
  taskId: string | null
  fileId: string | null
}

export const uploadNewFile = async (
  bankName: string,
  subtype: string,
  password: string,
  pdf: any,
  params: paramsObjectFromServerType | undefined,
  config?: any,
  page_range?: any,
): Promise<IUploadNewFileResponse> => {
  try {
    const response = await request
      .post(getApi(FilePath.uploadNewFile))
      .set(await getAuthorizationHeaders())
      .field('bank_name', bankName)
      .field('subtype', subtype)
      .field('password', password)
      .field('params', JSON.stringify(params))
      .attach('input_pdf', pdf)
      .attach('config_input', config)
      .field('page_range', page_range)
    const { history_id, file_id } = response.body
    return { taskId: history_id, fileId: file_id }
  } catch (e) {
    throw e
  }
}
