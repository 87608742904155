var tkHeaderRow: any = [
    {
        'tableRow': 'Line start'
    },
    {
        'tableRow': 'Border Position'
    },
    {
        'tableRow': 'Column header'
    },
    {
        'tableRow': 'Meta header'
    }
];

export const INITAL_STATE = {
    createBorderData: [],
    page: [],
}
export const createNewBorder = (state = INITAL_STATE, action: any) => {
    switch (action.type) {
        case 'onCreateBorder':
            var tkheaderConfigData: any = []
            var tkheaderPage: any = []
            action.createBorderData.forEach((element: any, index: number) => {
                const temp = {
                    'height': element['height'],
                    'tkheaderImage': element['image'],
                    'width': element['width'],
                    'tkHeadersCoordinates': {},
                    'tkHeaderRow': {},
                    'tkheaderData': {},
                    'title': []
                }
                tkheaderConfigData.push(temp);
                tkheaderPage.push(index + 1);
            });
            const createBorderObject = Object.assign({}, state, {
                createBorderData: tkheaderConfigData,
                page: tkheaderPage,
            })
            return createBorderObject
        case 'onChangeCreateBorder':
            const index = state.page.findIndex((item: any) => item == action.pageNumber);
            if (action.ChangeType == "tkHeadersCoordinates") {
                var tempTkHeader: any[] = state.createBorderData
                tempTkHeader[index]['tkHeadersCoordinates'][action.title] = [...action.tkHeaderBorderData]
                const onChangeBorderObject = Object.assign({}, state, {
                    createBorderData: tempTkHeader,
                    page: state.page,
                })
                return onChangeBorderObject
            }
            else if (action.ChangeType == "tkHeaderRow") {
                var tempTkHeaderRow: any[] = state.createBorderData
                tempTkHeaderRow[index]['tkHeaderRow'][action.title] = [...action.tkHeaderBorderData]
                const onChangeBorderObject = Object.assign({}, state, {
                    createBorderData: tempTkHeaderRow,
                    page: state.page,
                })
                return onChangeBorderObject
            }
            else if (action.ChangeType == "tkheaderData") {
                var tempTkHeaderData: any[] = state.createBorderData
                tempTkHeaderData[index]['tkheaderData'][action.title] = [...action.tkHeaderBorderData]
                const onChangeBorderObject = Object.assign({}, state, {
                    createBorderData: tempTkHeaderData,
                    page: state.page,
                })
                return onChangeBorderObject
            }
        case 'onTitleChange':
            const pageindex = state.page.findIndex((item: any) => item == action.pageNumber);
            var tempTitleChange: any[] = state.createBorderData
            var tempTitle: any[] = tempTitleChange[pageindex]['title']
            tempTitle.push(action.title)
            tempTitleChange[pageindex]['title'] = [...tempTitle]
            tempTitleChange[pageindex]['tkheaderData'][action.title] = []
            tempTitleChange[pageindex]['tkHeaderRow'][action.title] = tkHeaderRow
            tempTitleChange[pageindex]['tkHeadersCoordinates'][action.title] = []
            const onChangeTitle = Object.assign({}, state, {
                createBorderData: state.createBorderData,
                page: state.page,
            })
            return onChangeTitle

        case 'onDeleteTitle':
            const findpageindex = state.page.findIndex((item: any) => item == action.pageNumber);
            var deleteTitleChange: any[] = state.createBorderData
            var deleteTempTitle: any[] = deleteTitleChange[findpageindex]['title']
            const deleteIndex = deleteTempTitle.indexOf(action.title)
            deleteTempTitle.splice(deleteIndex, 1)
            delete deleteTitleChange[findpageindex]['tkheaderData'][action.title]
            delete deleteTitleChange[findpageindex]['tkHeaderRow'][action.title]
            delete deleteTitleChange[findpageindex]['tkHeadersCoordinates'][action.title]
            const onDeleteTitleData = Object.assign({}, state, {
                createBorderData: state.createBorderData,
                page: state.page,
            })
            return onDeleteTitleData

        default:
            return state
    }
}
