import * as React from 'react'
import styled from 'styled-components'
const { Pill } = require('evergreen-ui')

import { getBankStatusBadgeColor } from '../../utils/colors'
import { BankStatus } from '../../schemaTypes'
import { bankStatusEnum } from './bank-status.utils'

interface IProps {
  status: BankStatus
}

const BankStatusComp: React.FunctionComponent<IProps> = ({ status }: IProps) => (
  <Pill style={{ textTransform: 'capitalize' }} color={getBankStatusBadgeColor(status)}>
    {bankStatusEnum[status]}
  </Pill>
)

export default BankStatusComp
