import * as React from 'react'
import { Link } from '@reach/router'
const { Heading } = require('evergreen-ui')

import Logo from '../Logo/Logo'

const LogoHeader = () => (
  <Logo>
    <Heading is={'h1'} size={600} marginTop={0}>
      Home
    </Heading>
  </Logo>
)

export default LogoHeader
