import { paramsObjectFromServerType } from '../components/RerunParser/RerunParserForm'
import { getApi } from '../utils/api/Api'
import { tkheaders } from './api-constants'
import { getAuthorizationHeaders } from './auth-api'
const axios = require('axios')
export const tkheadersUploadFile = async (
  page: any,
  input_file: any,
  params: paramsObjectFromServerType | any,
) => {
  var bodyFormData = new FormData()
  if(page) {
    bodyFormData.append('page', page)
  }
  if(Object.keys(params).length > 0) {
    bodyFormData.append('params', JSON.stringify(params))
  }
  bodyFormData.append('input_pdf', input_file as Blob)
  return axios({
    method: 'post',
    url: getApi(tkheaders.tkheadersPdf),
    headers: {
      'Content-Type': 'multipart/form-data',
      ...await getAuthorizationHeaders()
    },
    data: bodyFormData,
  })
    .then((response: any) => {
      return response.data['data']
    })
    .catch((e: any) => {
      throw e
    })
}
