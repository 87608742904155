import * as React from 'react'
import dayjs from 'dayjs'
import styled from 'styled-components'
import { Link } from '@reach/router'
import { CircularProgressbar } from 'react-circular-progressbar'
import 'react-circular-progressbar/dist/styles.css'
import CancelTask from '../CancelRerunParser/CancelRerunTask'

const { Text, Heading, Icon, toaster } = require('evergreen-ui')

import { GetFileDetail_files_historySet_edges_node } from '../../schemaTypes'
import FileStatus from '../FileStatus/FileStatus'
import { colors } from '../../utils/colors'

const FileHistoryItem: React.FunctionComponent<any> = ({
  historyUuid,
  parsingStatus,
  createdAt,
  updatedAt,
  meta,
  taskId,
}: GetFileDetail_files_historySet_edges_node) =>
  parsingStatus === null ? null : (
    <Container>
      <div>
        <div className="title">
          <Heading is={'h3'}>config_version</Heading>
          <FileStatus status={parsingStatus} />
        </div>
        <Text size={400} color={'muted'}>
          <div>
            Created on{' : '}
            {dayjs(createdAt).format('DD MMM YYYY, HH:mm')}
          </div>
          <div>
            Updated on{' : '}
            {dayjs(updatedAt).format('DD MMM YYYY, HH:mm')}
          </div>
        </Text>
      </div>
      <div className="progressBar">{displayProgress(meta, parsingStatus)}</div>
      <div className="cancelTask">{displayCancelTask(parsingStatus, taskId)}</div>
      <Link to={`${location.pathname}/history/${historyUuid}`}>
        <Icon className="chevron" icon={'chevron-right'} />
      </Link>
    </Container>
  )

const displayCancelTask = (status: string, taskId: string) => {
  if (status === 'EX_PRO' || status === 'EX_TBP') {
    return <CancelTask taskId={taskId} />
  } else {
    return null
  }
}

const displayProgress = (meta: any, status: string) => {
  if (status === 'EX_PRO') {
    try {
      const Meta = JSON.parse(meta)
      return (
        <CircularProgressbar
          value={Meta['progress']['current']}
          text={`${Meta['progress']['current']}%`}
        />
      )
    } catch (e) {
      return <CircularProgressbar value={0} text={`N/A`} />
    }
  } else {
    return null
  }
}

const Container = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: 350px 80px 50px auto;
  grid-gap: 15px;
  position: relative;
  text-decoration: none;
  padding: 1rem 0;
  .title {
    margin-bottom: 0.3rem;
    display: flex;
    align-items: baseline;
    h3 {
      padding-right: 1rem;
    }
  }
  span > strong {
    font-weight: 500;
    color: ${colors.darkBlack};
  }

  ::before {
    cursor: pointer;
    content: '';
    position: absolute;
    top: 0;
    right: -24px;
    bottom: 0;
    left: -24px;
  }
  .cancelTask {
    margin-left: 50%;
  }
  .chevron {
    opacity: 0.5;
    margin-left: 90%;
    transition: all 200ms;
    color: ${colors.darkBlack};
    transform: scale(0.8);
  }
  :hover {
    .chevron {
      opacity: 1;
      transform: scale(1);
    }
    ::before {
      background: rgba(14, 30, 37, 0.05);
    }
  }
`

export default FileHistoryItem
