import * as React from 'react'
import styled from 'styled-components'

const { Card, Heading, Icon } = require('evergreen-ui')

import { colors } from '../../utils/colors'

interface IProps {
  title: string
  icon: string
  children: any
  style?: React.CSSProperties
  actionComponent?: React.ReactNode
  onClickTitle?: () => void
  className?: string
}

class TitleCard extends React.Component<IProps> {
  public render() {
    const { title, icon, children, actionComponent, style, onClickTitle, className } = this.props
    return (
      <Card
        className={className}
        elevation={2}
        padding={24}
        background={colors.white}
        style={{ overflow: 'hidden', ...style }}
      >
        <TitleContainer
          hasClickHandler={!!onClickTitle}
          onClick={() => {
            if (!!onClickTitle) {
              onClickTitle()
            }
          }}
        >
          <div>
            <Icon icon={icon} size={16} />
            <Heading is={'h2'} size={600}>
              {title}
            </Heading>
          </div>
          <section className="action-component">{actionComponent}</section>
        </TitleContainer>
        {children}
      </Card>
    )
  }
}

const TitleContainer: any = styled.div`
  display: grid;
  position: relative;
  h2 {
    color: ${colors.darkBlack};
    display: inline;
    margin-left: 1rem;
  }
  cursor: ${({ hasClickHandler }: any) => (hasClickHandler ? 'pointer' : 'mouse')};
  grid-template-columns: 1fr auto;
  grid-gap: 1rem;
  align-items: center;
  padding-bottom: 1rem;
  margin-bottom: 1rem;

  .action-component {
    opacity: ${({ hasClickHandler }: any) => (hasClickHandler ? 0.4 : 1)};
    transition: all 0.3s ease-out;
  }
  :hover {
    .action-component {
      opacity: 1;
      transform: ${({ hasClickHandler }: any) => (hasClickHandler ? 'scale(1.2)' : 'none')};
    }
  }

  ::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 2px;
    background-color: #f5f7f9;
  }
`

export default TitleCard
