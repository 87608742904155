import * as React from 'react'
import styled from 'styled-components'
import WithAuthorization from '../../components/WithAuthorization/WithAuthorization'
import CustomCard from '../../components/CustomCard/CustomCard'
import Menu from '../../components/NewConfig/menu'
class NewConfig extends React.Component {
  public render() {
    return (
      <main>
        <CustomCard>
          <Menu />
        </CustomCard>
      </main>
    )
  }
}

export default WithAuthorization(NewConfig)
