import * as React from 'react'
import WithAuthorization from '../../components/WithAuthorization/WithAuthorization'
import DisplayRestartTask from '../../components/BulkTaskRestart/BulkTaskRestart'

class BulkTaskRestart extends React.Component<any> {

  public render() {
    return <DisplayRestartTask />
  }

  
}


export default WithAuthorization(BulkTaskRestart)