import * as React from 'react'

const { Badge } = require('evergreen-ui')

interface IProps {
  parentUserId: number | null
}

const ParentUserId: React.FunctionComponent<IProps> = ({ parentUserId }: IProps) => (
  <Badge style={{ textTransform: 'capitalize', marginLeft: '10px' }}>
    <span style={{ fontWeight: 400 }}>Stashboard Parent User ID:</span>{' '}
    <span style={{ textTransform: 'lowercase' }}>{parentUserId || 'n/a'}</span>
  </Badge>
)

export default ParentUserId
