import * as React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { FilePath } from '../../api/api-constants'
import { getAuthorizationHeaders } from '../../api/auth-api'
import { getApi } from '../../utils/api/Api'
import ParserParameter from '../ParserParameter/ParserParameter'
import { OnSuccessAction } from '../ParserParameter/OnSuccessAction'
import { OnCreateBorder } from './onCreateBorder';
import { navigate } from '@reach/router'
import { uploadFile } from '../../api/tkheaders-api'
import { bindActionCreators } from 'redux'
const { Dialog, Button, Icon, TextInputField } = require('evergreen-ui')
const axios = require('axios')

interface IProps {
    fileId: string
    fileName: string
    bankCode: string
}

interface IState {
    isDialogOpen: boolean
    pageNumber: string
    isLoading: boolean,
    error: string,
}

class EditBorder extends React.Component<any, any> {
    public static defaultProps = {
        buttonText: 'Edit Border',
        buttonTheme: 'default',
    }
    public state = {
        isDialogOpen: false,
        pageNumber: null,
        isLoading: false,
        error: '',
    }

    private showDialog = () => this.setState({ isDialogOpen: true })
    private closeDialog = () => this.setState({ isDialogOpen: false, error: '', pageNumber: '' })

    public render(): React.ReactNode {
        const { pageNumber } = this.state
        const { param } = this.props
        return (
            <>
                <Dialog
                    contentContainerProps={{
                        padding: 10,
                    }}
                    isShown={this.state.isDialogOpen}
                    onCloseComplete={this.closeDialog}
                    confirmLabel={'Edit Border'}
                    hasFooter={false}
                    hasHeader={true}
                    title="Edit Border"
                    topOffset={'6vmin'}
                    preventBodyScrolling={true}
                >
                    <Container>
                        <div
                            style={{
                                padding: '5px 10px',
                            }}
                        >
                            <TextInputField
                                onChange={this.handlePageNumberChange}
                                value={pageNumber}
                                spellCheck={false}
                                // required
                                // validationMessage="Page number is required"
                                label={'Page*'}
                                placeholder={'Page number'}
                            />
                        </div>
                    </Container>
                    {this.state.error && <StyledAlert>{this.state.error}</StyledAlert>}
                    <ParserParameter
                        paramsParameter={param}
                    />
                    <Button
                        style={{
                            margin: '5px 0',
                            float: 'right',
                        }}
                        isLoading={this.state.isLoading}
                        appearance="primary"
                        type={'submit'}
                        onClick={this.onSubmit}
                    >
                        Edit Border
                    </Button>
                    <Button
                        appearance="minimal"
                        onClick={this.closeDialog}
                        style={{
                            margin: '5px 0',
                            float: 'right',
                        }}
                    >
                        Cancel
                    </Button>
                </Dialog>
                <Button className="icon" onClick={this.showDialog}>
                    {this.displayParser()}
                </Button>
            </>
        )
    }

    private onSubmit = async () => {
        localStorage.removeItem('draw-app')
        const { pageNumber } = this.state
        const selectedPage = pageNumber ? (+pageNumber) : null;
        if (!selectedPage) {
            this.setState({ isLoading: false, error: 'Please provide valid Page number' })
        } else {
            this.setState({ isLoading: true, error: '' })
            const pdf_download = this.downloadFile(this.props.fileId, this.props.fileName, 'input_processed_pdf') 
            const excel_download = this.downloadFile(this.props.fileId, (this.props.bankCode + '.xlsx'), 'bank_config')
            Promise.all([pdf_download,excel_download])
                .then(async (files: any[]) => {
                    const input_pdf= files[0]
                    const bank_config= files[1]
                    const sheetname = this.props.bankCode + "_" + this.props.subtype

                    if(input_pdf && bank_config){

                    const selectedPage = pageNumber ? (+pageNumber) : 1;
                    const parserParams: any = {}
                    const params = this.props.params
                    for (var i = 0; i < params.length; i++) {
                        if (params[i].type === 'str') {
                            parserParams[params[i].key] = params[i].value.trim()
                        } else if (params[i].type === 'int' || params[i].type === 'float') {
                            const temp = +params[i].value
                            parserParams[params[i].key] = temp
                        } else {
                            parserParams[params[i].key] = params[i].value
                        }
                    }
                    
                    uploadFile(sheetname, selectedPage, bank_config, input_pdf, parserParams)
                    .then((result: any) => {
                        this.setState({
                            isDialogOpen: false,
                            pageNumber: null,
                            isLoading: false,
                            error: '',
                        })
                        let bounds: any[] = []
                        result['headers']['bounds'].forEach((element: any) => {
                          const value = {
                            x: element[1][0],
                            y: element[1][1],
                          }
                          bounds.push(value)
                        })
                        localStorage.setItem('draw-app', JSON.stringify(bounds))
              
                        navigate(`/tkheaders/`, {
                          state: {
                            headers: result['headers'],
                            image: result['image'],
                            config_input: bank_config,
                            bank_name: sheetname,
                            log: result['log'],
                            page: selectedPage,
                            input_pdf: input_pdf,
                            error: '',
                            params: parserParams,
                          },
                        })
                      })
                      .catch((e: any) => {
                        const error =
                          e.response && e.response.body && e.response.body.error
                            ? e.response.body.error
                            : 'Unknown Error'
              
                        const status = e.response && e.response.status ? e.response.status : 0
                        if (status === 401) {
                          this.setState({
                            isDialogOpen: false,
                            pageNumber: null,
                            isLoading: false,
                            error: error,
                          })
                          navigate('/login')
                        } else if (status === 400) {
                          this.setState({
                            isDialogOpen: false,
                            pageNumber: null,
                            isLoading: false,
                            error: error,
                          })
                          const log = e.response.body.log
                          navigate(`/tkheaders/`, {
                            state: {
                              headers: null,
                              image: null,
                              config_input: null,
                              bank_name: null,
                              log: log,
                              error: error,
                            },
                          })
                        } else {
                          this.setState({ error: error, isLoading: false })
                        }
                      })
                    }else{

                        this.setState({ error: 'either PDF or bank config file cannot be found', isLoading: false })
                    }
                    
                })
                .catch((e: any) => {
                    const error = e.response && e.response.data ? e.response.data.error : 'Unknown Error'
                    this.setState({ error: error, isLoading: false })
                })
        }
    }


    private handlePageNumberChange = (e: any) => {
        this.setState({ pageNumber: e.target.value.trim() })
    }

    private displayParser() {
        const { buttonText } = this.props
        if (this.props.page === 'home') {
            return <Icon icon={'refresh'} color={'muted'} size={16} />
        } else {
            return <span> {buttonText} </span>
        }
    }

    private downloadFile = async (fileId: string, fileName: string, file_type: string) => {
        var bodyFormData = new FormData()
        var api_url = null

        bodyFormData.set('file_pk', fileId)
        bodyFormData.set('response_file', file_type)
        api_url = getApi(FilePath.downloadFile)

        return axios({
            method: 'post',
            url: api_url,
            headers: {
                'Content-Type': 'multipart/form-data',
                ...await getAuthorizationHeaders()
            },
            data: bodyFormData,
            responseType: 'blob',
        })
            .then((res: any) => {

                const blob = new Blob([res.data], { type: res.headers['content-type'] })
                var file = new File([blob], fileName, { type: blob.type });
                return file;
            })
            .catch((e: any) => {
                const error = e.response && e.response.data ? e.response.data.error : 'Unable to download file'
                this.setState({ error: error, isLoading: false })
            })
    }
}



const Container = styled.span`
  .errorMessage{
    color: red;
    font-size: 10px
    position: relative
    top: -20px;
  }
`
const StyledAlert = styled.div`
  background-color: #fff2f0;
  border: 1px solid #ffccc7;
  margin: 20px 0;
  padding: 10px;
`

const mapStateToProps = (storeData: any) => {
    return {
        params: storeData.onChangeReducer.params,
        borderData: storeData.createNewBorder
    }
}

const mapDispatch = (dispatch: any) => {
    return bindActionCreators(
        {
            OnSuccessAction: OnSuccessAction,
            OnCreateBorder: OnCreateBorder
        },
        dispatch)
}

export default connect(mapStateToProps, mapDispatch)(EditBorder)
