import * as React from 'react'
const { Button, TextInput, withTheme, SideSheet, Heading, Text } = require('evergreen-ui')
import styled from 'styled-components'
import { connect } from 'react-redux'
import { uploadFile } from '../../api/tkheaders-api'
import { navigate } from '@reach/router'
import { OnSuccessAction } from '../ParserParameter/OnSuccessAction'
import ParserParameter from '../ParserParameter/ParserParameter'
import { bindActionCreators } from 'redux'
import { isAuthenticated } from '../../api/auth-api'

interface IState {
  bank_name: string
  page: number
  config_input: File | undefined
  input_file: File | undefined
  isLoader: boolean
  isSideSheetOpen: boolean
}


class TkHeaders extends React.Component<any, any> {
  public state = {
    bank_name: '',
    page: 0,
    config_input: undefined,
    input_file: undefined,
    isSideSheetOpen: false,
    isLoader: false,
  }

  componentDidMount() {
    this.props.OnSuccessAction('')
  }

  private uploadFile = async () => {
    localStorage.removeItem('draw-app')
    const { bank_name, page, input_file, config_input } = this.state
    var params: any[] = []
    var paramsResult: any = {}
    this.setState({
      isLoader: true,
    })

    if (bank_name && page && input_file && config_input) {
      params = this.props.params

      for (var i = 0; i < params.length; i++) {
        if (params[i].type === 'str') {
          paramsResult[params[i].key] = params[i].value.trim()
        } else if (params[i].type === 'int' || params[i].type === 'float') {
          const temp = +params[i].value
          paramsResult[params[i].key] = temp
        } else {
          paramsResult[params[i].key] = params[i].value
        }
      }
      uploadFile(bank_name, page, config_input, input_file, paramsResult)
        .then((result: any) => {
          this.setState({
            bank_name: '',
            page: 0,
            config_input: undefined,
            input_file: undefined,
            isSideSheetOpen: false,
            isLoader: false,
          })
          let bounds: any[] = []
          result['headers']['bounds'].forEach((element: any) => {
            const value = {
              x: element[1][0],
              y: element[1][1],
            }
            bounds.push(value)
          })
          localStorage.setItem('draw-app', JSON.stringify(bounds))

          navigate(`/tkheaders/`, {
            state: {
              headers: result['headers'],
              image: result['image'],
              config_input: config_input,
              bank_name: bank_name,
              log: result['log'],
              page: page,
              input_pdf: input_file,
              error: '',
              params: paramsResult
            },
          })
        })
        .catch((e: any) => {
          const error =
            e.response && e.response.body && e.response.body.error
              ? e.response.body.error
              : 'Unknown Error'

          const status = e.response && e.response.status ? e.response.status : 0
          if (status === 401) {
            this.setState({
              bank_name: '',
              page: 0,
              config_input: undefined,
              input_file: undefined,
              isSideSheetOpen: false,
              isLoader: false,
            })
            alert(error)
            navigate('/login')
          } else if (status === 400) {
            this.setState({
              bank_name: '',
              page: 0,
              config_input: undefined,
              input_file: undefined,
              isSideSheetOpen: false,
              isLoader: false,
            })
            const log = e.response.body.log
            navigate(`tkheaders`, {
              state: {
                headers: null,
                image: null,
                config_input: null,
                bank_name: null,
                log: log,
                error: error,
              },
            })
          } else {
            this.setState({
              isLoader: false,
            })
            alert(error)
          }
        })
    } else {
      this.setState({
        isLoader: false,
      })
      bank_name === '' ? alert('BankName can not be empty') : null
      page === 0 ? alert('Page number can not be empty') : null
      input_file === undefined ? alert('Input file can not be empty') : null
      config_input === undefined ? alert('Config file can not be empty') : null
    }
  }

  private DispalyDataRender() {
    const { config_input, input_file, isSideSheetOpen } = this.state

    const pdfLabel = this.extractNameFromFile(input_file, 'pdf')
    const configLabel = this.extractNameFromFile(config_input, 'config')
    return (
      <>
        <SideSheet
          width={500}
          isShown={isSideSheetOpen}
          onCloseComplete={this.onCloseSideSheet}
          preventBodyScrolling={true}
        >
          <Container>
            <section className="flex-container">
              <span>Pdf *</span>
              <input
                className="inputfile"
                type="file"
                id="pdf"
                accept=".pdf"
                onChange={(e: any) => {
                  this.setState({ input_file: e.target.files[0] })
                }}
              />
              <label
                className={this.props.theme.getButtonClassName()}
                htmlFor="pdf"
                style={{ overflow: 'hidden' }}
              >
                {pdfLabel}
              </label>
            </section>
            <section className="flex-container">
              <span>Excel *</span>
              <input
                className="inputfile"
                type="file"
                id="config"
                accept=".xls, .xlsx"
                onChange={(e: any) => {
                  this.setState({ config_input: e.target.files[0] })
                }}
              />
              <label className={this.props.theme.getButtonClassName()} htmlFor="config">
                {configLabel}
              </label>
            </section>

            <section className="flex-container">
              <span>Sheet Name *</span>
              <TextInput
                required
                onChange={(e: any) => this.setState({ bank_name: e.target.value.trim() })}
                name="bankName"
                placeholder="Sheet within excel config"
                width={200}
              />
            </section>

            <section className="flex-container">
              <span>Page *</span>
              <TextInput
                required
                onChange={(e: any) => this.setState({ page: e.target.value.trim() })}
                name="page"
                placeholder="Page number"
                width={200}
              />
            </section>
          </Container>
          <div style={{ padding: '20px', fontSize: '14px' }}>
            {' '}
            <b style={{ paddingRight: '10px' }}>Note:</b>
            The param <span style={{ fontStyle: 'italic' }}>USE_SUBTYPE_META_CONFIG </span> is
            disabled for the Create Borders operations.
          </div>
          <section style={{ marginBottom: '2rem' }}>
            <ParserParameter bank={this.state.bank_name} />
          </section>
          <BottomButton
            isLoading={this.state.isLoader}
            appearance="primary"
            onClick={this.uploadFile}
          >
            Upload
          </BottomButton>
        </SideSheet>

        <Heading is={'h1'} size={600} color={'#116AB8'} onClick={this.openSlideSheet}>
          Create Borders
        </Heading>
      </>
    )
  }

  private onCloseSideSheet = () => {
    this.setState({ isSideSheetOpen: false })
  }

  private openSlideSheet = () => {
    if (!isAuthenticated()) {
      navigate('/login')
    }else{
    this.setState({ isSideSheetOpen: true })
    }
  }

  private extractNameFromFile = (file: any | undefined, type: 'pdf' | 'config') => {
    if (!file) {
      if (type === 'pdf') {
        return 'Choose a PDF'
      }
      if (type === 'config') {
        return 'Choose  config'
      } else {
        return 'error'
      }
    }

    let name = file.name
    const MAX_LABEL_WORD_COUNT = 20
    if (name.length > MAX_LABEL_WORD_COUNT) {
      const startCount = MAX_LABEL_WORD_COUNT / 3
      const endCount = (MAX_LABEL_WORD_COUNT * 2) / 3
      name = name.substring(0, startCount) + '…' + name.substring(name.length - endCount)
    }
    return name
  }

  public render() {
    return <>{this.DispalyDataRender()}</>
  }
}

const mapStateToProps = (storeData: any) => {
  return {
    params: storeData.onChangeReducer.params,
  }
}

const mapDispatch = (dispatch: any) => {
  return bindActionCreators(
    {
      OnSuccessAction: OnSuccessAction,
    },
    dispatch,
  )
}

const UploadFileWithThemen = withTheme(TkHeaders)

export default connect(mapStateToProps, mapDispatch)(UploadFileWithThemen)

const Container = styled.li`
   {
    text-overflow: ellipsis;
    list-style: none;
    margin-bottom: 20px;
    padding-top: 20px;

    .flex-container {
      display: flex;
      width: 100%;
      padding: 10px 20px;
      span {
        width: 25%;
      }
    }

    section {
      width: 20%;
    }

    .upload-file-section {
      padding: 1rem;
    }
    .inputfile {
      width: 0.1px;
      height: 0.1px;
      opacity: 0;
      overflow: hidden;
      position: absolute;
      z-index: -1;
    }
    .inputfile + label {
      padding: 0.5rem;
      font-size: 13.5px;
      border-radius: 2px;
      width: 185px;
      cursor: pointer;
    }

    .inputfile + label {
      cursor: pointer;
    }
    .upload-section {
      overflow: hidden;
    }
  }
`

const BottomButton = styled(Button)`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  border-radius: 5px 5px 0 0;
  justify-content: center;
`
