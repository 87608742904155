import * as React from 'react'
import styled from 'styled-components'

import { getFileStatusStripeColor } from '../../utils/colors'
import { FilesStatus, HistoryStatus } from '../../schemaTypes'

interface IProps {
  status: FilesStatus | HistoryStatus
}

const FileStatusStripe: React.FunctionComponent<IProps> = ({ status }: IProps) => (
  <Stripe status={status} />
)

const Stripe = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 8px;
  background: ${({ status }: IProps) => getFileStatusStripeColor(status)};
`

export default FileStatusStripe
