export enum FilterStatusEnum {
  ALL = 'All',
  EX_TBP = 'To Be Processed',
  EX_PRO = 'Processing',
  EX_SUC = 'Extraction Successful',
  EX_FAI = 'Extraction Failed',
  BS_NR = 'Bank detection not run',
  BS_PEN = 'Yet to start',
  BS_RUN = 'Bank detection currently running',
  BS_BNK = 'Bank detected',
  BS_SUB = 'Subtype detected',
}

export enum AllFilterEnum {
  ALL = 'ALL',
}
