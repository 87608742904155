import * as React from 'react'
import styled from 'styled-components'
import { Link } from '@reach/router'

import { colors } from '../../utils/colors'

class Logo extends React.Component {
  public render() {
    return (
      <Container>
        <Link to={'/'}>
          <img src="/assets/logo.png" alt="" />
        </Link>
        {this.props.children}
      </Container>
    )
  }
}

const Container = styled.div`
  display: inline-flex;
  align-items: center;
  padding: 4px;
  img {
    height: 40px;
    width: 40px;
    margin: 0 1rem 0 1rem;
  }
  h1 {
    color: ${colors.white};
  }
`

export default Logo
