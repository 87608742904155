export const generateGenericFavicon = () => {
  const sizes = ['16x16', '32x32']
  return sizes.map(size => {
    const href = `/assets/favicons/favicon-${size}.png`
    return {
      rel: 'icon',
      type: 'image/png',
      sizes: size,
      href,
    }
  })
}
