import * as React from 'react'
import styled from 'styled-components'
import { ApolloConsumer } from 'react-apollo'
import gql from 'graphql-tag'
import { navigate } from '@reach/router'
import { connect } from 'react-redux'
const { toaster } = require('evergreen-ui')
const {
  Button,
  SideSheet,
  TextInputField,
  Heading,
  withTheme,
  RadioGroup,
} = require('evergreen-ui')
import { uploadNewFile } from '../../api/upload-file-api'
import { colors } from '../../utils/colors'
import ParserParameter from '../ParserParameter/ParserParameter'

interface IState {
  isSideSheetOpen: boolean
  bankName: string
  subtype: string
  password: string
  pdf: File | null
  config: File | null
  loadingApi: boolean
  isNumberValid: boolean
  isDisplayRange: boolean
  start: number
  end: number
  isStart: boolean
  isEnd: boolean
  value: string
}

class UploadNewFile extends React.Component<any, any> {
  public state = {
    bankName: '',
    subtype: '',
    password: '',
    isSideSheetOpen: false,
    pdf: undefined,
    config: undefined,
    loadingApi: false,
    isNumberValid: true,
    isDisplayRange: false,
    start: 0,
    end: 0,
    isStart: false,
    isEnd: false,
    options: [
      { label: 'All', value: 'all' },
      { label: 'Range', value: 'range' },
    ],
    value: 'all',
  }

  public render() {
    const { isSideSheetOpen, bankName, subtype, pdf, config, password, loadingApi } = this.state
    const pdfLabel = this.extractNameFromFile(pdf, 'pdf')
    const configLabel = this.extractNameFromFile(config, 'config')

    return (
      <>
        <SideSheet
          containerProps={{
            style: {
              overflow: 'hidden',
            },
          }}
          width={500}
          isShown={isSideSheetOpen}
          onCloseComplete={this.toggleSideSheet}
          preventBodyScrolling={true}
        >
          <Container>
            <ScrollSection>
              <Heading is="h1" className={'title'}>
                Upload new file or test new config
              </Heading>
              <section className="upload-section">
                <input
                  className="inputfile"
                  type="file"
                  id="pdf"
                  accept=".pdf"
                  onChange={(e: any) => {
                    this.setState({ pdf: e.target.files[0] })
                  }}
                />
                <label className={this.props.theme.getButtonClassName()} htmlFor="pdf">
                  {pdfLabel}
                </label>
                <span style={{ paddingLeft: 5 }}>*</span>
                <div className="textBoxSpacing">
                  <TextInputField
                    onChange={this.handlePasswordChange}
                    value={password}
                    containerProps={{}}
                    spellCheck={false}
                    label={'Password'}
                    placeholder={'password'}
                  />
                </div>

                <div style={{ fontSize: 10, marginBottom: 10 }}>
                  Note: Google master config folder is used by default
                </div>
                <input
                  className="inputfile"
                  type="file"
                  id="config"
                  accept=".xls, .xlsx"
                  onChange={(e: any) => {
                    this.setState({ config: e.target.files[0] })
                  }}
                />
                <label className={this.props.theme.getButtonClassName()} htmlFor="config">
                  {configLabel}
                </label>

                <div className="textBoxSpacing">
                  <TextInputField
                    onChange={this.handleBankCodeChange}
                    value={bankName}
                    containerProps={{}}
                    spellCheck={false}
                    label={'Bank name'}
                    placeholder={'Bank name'}
                  />
                </div>
                <div className="textBox">
                  <TextInputField
                    onChange={this.handlesubtypeChange}
                    value={subtype}
                    containerProps={{}}
                    spellCheck={false}
                    label={'Subtype'}
                    placeholder={'subtype'}
                  />
                </div>
                <section>
                  <RadioGroup
                    label="Pages"
                    value={this.state.value}
                    options={this.state.options}
                    onChange={(value: any) => {
                      value === 'all'
                        ? this.setState({ value, isDisplayRange: false })
                        : this.setState({ value, isDisplayRange: true })
                    }}
                  />

                  {this.displayRangeField()}
                </section>
              </section>
              <ParserParameter subtype={this.state.subtype} bank={this.state.bankName} />
            </ScrollSection>
            <ApolloConsumer>
              {client => (
                <BottomButton
                  isLoading={loadingApi}
                  appearance="primary"
                  onClick={() => this.uploadFile(client)}
                >
                  Upload
                </BottomButton>
              )}
            </ApolloConsumer>
          </Container>
        </SideSheet>
        <Button onClick={this.toggleSideSheet} style={{ marginRight: '1rem' }} appearance="primary">
          Single Upload
        </Button>
      </>
    )
  }

  private displayRangeField = () => {
    if (this.state.isDisplayRange) {
      return (
        <Range>
          <TextInputField
            marginRight={10}
            onChange={(e: any) => this.handleRangeField(e, 'start')}
            placeholder={'start *'}
          />
          {this.state.isStart ? (
            <span className="errorMessage">This is not a valid number</span>
          ) : null}
          <TextInputField
            onChange={(e: any) => this.handleRangeField(e, 'end')}
            placeholder={'end *'}
          />
          {this.state.isEnd ? (
            <span className="errorMessage">This is not a valid number</span>
          ) : null}
        </Range>
      )
    } else {
      return null
    }
  }

  private handleRangeField = (e: any, key: string) => {
    var regexp = /^[0-9]+$/
    if (key === 'start') {
      this.setState({
        isStart: !regexp.test(e.target.value),
      })
    } else {
      this.setState({
        isEnd: !regexp.test(e.target.value),
      })
    }
    this.setState({ [key]: e.target.value })
  }

  private handleBankCodeChange = (e: any) => {
    this.setState({ bankName: e.target.value.trim() })
  }

  private handlePasswordChange = (e: any) => {
    this.setState({ password: e.target.value })
  }

  private handlesubtypeChange = (e: any) => {
    this.setState({ subtype: e.target.value.trim() })
  }

  private toggleSideSheet = () => {
    this.setState({
      isSideSheetOpen: !this.state.isSideSheetOpen,
      bankName: '',
      subtype: '',
      password: '',
      pdf: undefined,
      config: undefined,
      loadingApi: false,
      isNumberValid: true,
      isDisplayRange: false,
      start: 0,
      end: 0,
      isStart: false,
      isEnd: false,
    })
  }

  private uploadFile = async (client: any) => {
    var page_range = ''
    var params: any[] = []
    var result: any = {}
    const { pdf, config, bankName, subtype, isNumberValid, isDisplayRange, password } = this.state
    const start = +this.state.start
    const end = +this.state.end
    if (isDisplayRange) {
      if (start > 0 && end >= start) {
        page_range = start + '-' + end
      } else {
        start <= 0 ? alert('Value of start should be greater than zero ') : null
        end < start ? alert('Value of end should be greater or equal to start') : null
      }
    } else {
      page_range = 'all'
    }

    if (isNumberValid && page_range != '') {
      this.setState({ loadingApi: true })

      params = this.props.params

      for (var i = 0; i < params.length; i++) {
        if (params[i].type === 'str') {
          result[params[i].key] = params[i].value.trim()
        } else if (params[i].type === 'int' || params[i].type === 'float') {
          const temp = +params[i].value
          result[params[i].key] = temp
        } else {
          result[params[i].key] = params[i].value
        }
      }
      uploadNewFile(bankName, subtype, password, pdf, result, config, page_range)
        .then(async (result: any) => {
          const { taskId, fileId } = result
          this.setState({ loadingApi: false })
          // navigate to file detail page
          if (taskId) {
            const { data } = await client.query({
              query: GET_FILE_ID_FROM_HISTORY,
              variables: { taskId },
            })
            if (
              !data ||
              !data.allHistory ||
              !data.allHistory.edges ||
              !data.allHistory.edges[0] ||
              !data.allHistory.edges[0].node
            ) {
              location.reload()
            } else {
              const fileId = data.allHistory.edges[0].node.mFile.id
              navigate(`/files/${fileId}`)
            }
          } else {
            const { data } = await client.query({
              query: GET_FILE_ID,
              variables: { fileId },
            })
            if (
              !data ||
              !data.allFiles ||
              !data.allFiles.edges ||
              !data.allFiles.edges[0] ||
              !data.allFiles.edges[0].node
            ) {
              location.reload()
            } else {
              const fileId = data.allFiles.edges[0].node.id
              navigate(`/files/${fileId}`)
            }
          }
        })
        .catch((e: any) => {
          this.setState({ loadingApi: false })
          const error =
            e.response && e.response.body && e.response.body.error
              ? e.response.body.error
              : 'Something went wrong'
          toaster.danger(error)
        })
    } else {
      !isNumberValid ? alert('Number should be in proper format') : null
      !page_range ? alert('page_range can not be empty') : null
    }
  }

  private extractNameFromFile = (file: any | undefined, type: 'pdf' | 'config') => {
    if (!file) {
      if (type === 'pdf') {
        return 'Choose a PDF'
      }
      if (type === 'config') {
        return 'Choose a config'
      } else {
        return 'error'
      }
    }

    let name = file.name
    const MAX_LABEL_WORD_COUNT = 20
    if (name.length > MAX_LABEL_WORD_COUNT) {
      const startCount = MAX_LABEL_WORD_COUNT / 3
      const endCount = (MAX_LABEL_WORD_COUNT * 2) / 3
      name = name.substring(0, startCount) + '…' + name.substring(name.length - endCount)
    }
    return name
  }
}

const ScrollSection = styled.section`
  overflow: scroll;
  height: calc(100vh - 32px);
`

const Container = styled.div`
  position: relative;
  height: 100vh;
  .title {
    text-align: center;
    text-transform: capitalize;
    font-size: 1.2rem;
    font-weight: 500;
    margin-bottom: 1rem;
    padding: 1rem;
    background: ${colors.hoverBackground};
  }
  .upload-section {
    padding: 1rem;
  }
  .textBox {
    margin-top: 16px;
  }
  .textBoxSpacing {
    margin-top: 20px;
  }
  .inputfile + label {
    padding: 0.5rem;
    border-radius: 2px;
    width: 100%;
    overflow: hidden;
  }
  .inputfile {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    position: relative;
    z-index: -1;
  }
  .inputfile + label {
    cursor: pointer;
  }
`

const BottomButton = styled(Button)`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  border-radius: 5px 5px 0 0;
  justify-content: center;
`
const Range = styled.div`
 display: flex
 width: 100%;
 .errorMessage{
  font-size: 10px;
  color: red;
  position: relative;
  left:-120px
  bottom: -40px;
}

`
const GET_FILE_ID_FROM_HISTORY = gql`
  query GetFileId($taskId: String!) {
    allHistory(first: 1, taskId: $taskId) {
      edges {
        node {
          mFile {
            id
          }
        }
      }
    }
  }
`
const GET_FILE_ID = gql`
  query GetFileId($fileId: String) {
    allFiles(fileUuid: $fileId) {
      edges {
        node {
          id
        }
      }
    }
  }
`
const mapStateToProps = (storeData: any) => {
  return {
    params: storeData.onChangeReducer.params,
  }
}

const UploadFileWithThemen = withTheme(UploadNewFile)

export default connect(mapStateToProps, null)(UploadFileWithThemen)
