import * as React from 'react'

import Logo from '../Logo/Logo'
import { colors } from '../../utils/colors'
import { HeaderText, HeaderTextStrong } from '../Styles/HeaderStyles'

interface IProps {
  bankCode?: string
  clientId?: string
  subtype?: string
  taskId?: string
  stashboardJobId?: string
  parentUserId?: string
  bank_subtype?: string
  fileContains?: string
}

class SearchHeader extends React.Component<any> {
  private displayHeader = () => {
    const {
      bankCode,
      clientId,
      subtype,
      taskId,
      stashboardJobId,
      parentUserId,
      bank_subtype,
      fileContains,
    } = this.props
    if (bankCode) {
      return (
        <HeaderText color={colors.white}>
          <span style={{ paddingRight: '10px' }}> Files for bank </span>
          <HeaderTextStrong>{bankCode}</HeaderTextStrong>
        </HeaderText>
      )
    } else if (clientId) {
      return (
        <HeaderText color={colors.white}>
          <span style={{ paddingRight: '10px' }}> Files for client</span>
          <HeaderTextStrong>{clientId}</HeaderTextStrong>
        </HeaderText>
      )
    } else if (subtype) {
      return (
        <HeaderText color={colors.white}>
          <span style={{ paddingRight: '10px' }}>Files for subtype</span>
          <HeaderTextStrong>{subtype}</HeaderTextStrong>
        </HeaderText>
      )
    } else if (taskId) {
      return (
        <HeaderText color={colors.white}>
          <span style={{ paddingRight: '10px' }}>Files for Stashboard task ID</span>
          <HeaderTextStrong>{taskId}</HeaderTextStrong>
        </HeaderText>
      )
    } else if (stashboardJobId) {
      return (
        <HeaderText color={colors.white}>
          <span style={{ paddingRight: '10px' }}>Files for Stashboard statement job ID</span>
          <HeaderTextStrong>{stashboardJobId}</HeaderTextStrong>
        </HeaderText>
      )
    } else if (parentUserId) {
      return (
        <HeaderText color={colors.white}>
          <span style={{ paddingRight: '10px' }}>
            {' '}
            Files for Parent user ID for the stashboard job
          </span>
          <HeaderTextStrong>{parentUserId}</HeaderTextStrong>
        </HeaderText>
      )
    } else if (bank_subtype) {
      return (
        <HeaderText color={colors.white}>
          <span style={{ paddingRight: '10px' }}> Files for bank_subtype</span>
          <HeaderTextStrong>{bank_subtype}</HeaderTextStrong>
        </HeaderText>
      )
    } else if (fileContains) {
      return (
        <HeaderText color={colors.white}>
          <span style={{ paddingRight: '10px' }}> Files for fileContains</span>
          <HeaderTextStrong>{fileContains}</HeaderTextStrong>
        </HeaderText>
      )
    }
  }
  public render() {
    return <Logo>{this.displayHeader()}</Logo>
  }
}

export default SearchHeader
