export enum AuthenticationMode {
  CognitoSocial,
  CognitoSocialUserPass,
  CognitoUserPass,
  CanopyUserPass,
  CanopySSO,
}

const isProduction = process.env.NODE_ENV === 'production'

const config = {
  authenticationMode: (AuthenticationMode[
    ((process.env.REACT_APP_AUTHENTICATION_MODE ||
      'CanopyUserPass') as unknown) as AuthenticationMode
  ] as unknown) as AuthenticationMode,
  aws: {
    region: process.env.REACT_APP_AMPLIFY_AUTH_REGION,
    cognito: {
      userPoolId: process.env.REACT_APP_AMPLIFY_AUTH_USER_POOL_ID,
      clientId: process.env.REACT_APP_AMPLIFY_AUTH_USER_POOL_WEB_CLIENT_ID,
      oauthDomain: process.env.REACT_APP_AMPLIFY_AUTH_OAUTH_DOMAIN,
    },
  },
  stripePublishableKey: process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY,
  api: {
    auth: process.env.REACT_APP_AUTH_API || 'https://dev-api.kurtosys.org',
    backend: process.env.REACT_APP_BACKEND_API,
    viz_url: process.env.REACT_APP_VIZ_UI,
  },
  costPerPage: parseFloat(process.env.REACT_APP_COST_PER_PAGE || '0.50'),
};

if (!config.api.backend) {
  config.api.backend = isProduction
    ? `${location.origin}/api/`
    : `https://datafeed-processor-dev.kurtosys.org/api/`
}

if (config.authenticationMode === AuthenticationMode.CanopySSO) {
  console.warn('`AuthenticationMode.CanopySSO` is unsupported.');
}

export const authWithSocialButtons = [
  AuthenticationMode.CognitoSocialUserPass,
  AuthenticationMode.CognitoSocial,
].includes(config.authenticationMode)

export const authWithUserPassForm = [
  AuthenticationMode.CognitoSocialUserPass,
  AuthenticationMode.CanopyUserPass,
  AuthenticationMode.CognitoUserPass,
].includes(config.authenticationMode)


export default config;