type InputFile = {
  folder: string
  bank: string
  clientId: string
  filename: string
  extension:string
}
export const extractFileDataFromS3Key = (inputFile: string): InputFile => {
  const [folder, bank, clientId, ...rest] = inputFile.split('/')
  let filename, extension
  {
  const file= rest.join('/')
    filename = file.substring(0, file.lastIndexOf('.'))
    extension = file.substring(file.lastIndexOf('.') + 1);
  }
  return {
    folder,
    bank,
    clientId,
    filename,
    extension,
  }
}
