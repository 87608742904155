import gql from 'graphql-tag'
import * as React from 'react'
import { Mutation } from 'react-apollo'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { FilesStatus, ReparseVariables } from '../../schemaTypes'
import ParserParameter from '../ParserParameter/ParserParameter'
const { Dialog, Button, Icon, Tooltip, TextInputField, toaster, RadioGroup } = require('evergreen-ui')

interface IProps {
  bankName: string
  subtype: string
  fileId: string
  fileStatus: FilesStatus
  defaultSelections?: any
  buttonText?: string
  buttonTheme?: any
  isBank: boolean
  isSubtype: boolean
  isDisplayRange: boolean
  start: number
  end: number
  isStart: boolean
  isEnd: boolean
  value: string
}

enum apiStatusEnum {
  noop,
  start,
  loading,
  success,
  fail,
}

interface IState {
  isRerunDialogOpen: boolean
  reparseApiParams: ReparseVariables | undefined
  apiStatus: apiStatusEnum
}

class RerunParser extends React.Component<any, any> {
  public static defaultProps = {
    buttonText: 'Rerun Parser',
    buttonTheme: 'default',
  }
  public state = {
    isRerunDialogOpen: false,
    reparseApiParams: undefined,
    apiStatus: apiStatusEnum.noop,
    bankName: this.props.bankName,
    subtype: this.props.subType,
    isBank: false,
    isSubtype: false,
    isDisplayRange: false,
    start: 0,
    end: 0,
    isStart: false,
    isEnd: false,
    options: [
      { label: 'All', value: 'all' },
      { label: 'Range', value: 'range' },
    ],
    value: 'all',
    isLoading: false,
  }

  componentDidMount() {
    const { pageRange } = this.props
    if (pageRange !== 'all') {
      var splitArray = pageRange.split('-')
      this.setState({
        isDisplayRange: true,
        value: 'range',
        start: splitArray[0],
        end: splitArray[1],
      })
    }
  }

  public render(): React.ReactNode {
    const { bankName, subtype } = this.state
    const { param } = this.props
    return (
      <>
        <Mutation
          mutation={RerunParserMutation}
          variables={this.generateArgumentsForReparseMutation()}
        >
          {(callRerunParserMutation: any) => (
            <>
              <Dialog
                contentContainerProps={{
                  padding: 10,
                }}
                isShown={this.state.isRerunDialogOpen}
                onCloseComplete={this.closeDialog}
                confirmLabel={'Rerun Parser'}
                hasFooter={false}
                hasHeader={true}
                title="Rerun Parser"
                topOffset={'6vmin'}
                preventBodyScrolling={true}
              >
                <Container>
                  <div
                    style={{
                      padding: '5px 10px',
                    }}
                  >
                    <TextInputField
                      required
                      onChange={this.handleBankCodeChange}
                      value={bankName}
                      spellCheck={false}
                      label={'Bank name'}
                      placeholder={'Bank name'}
                    />
                    {this.state.isBank ? (
                      <span className="errorMessage">Enter Bank name</span>
                    ) : null}
                  </div>
                  <div
                    style={{
                      padding: '0 10px',
                    }}
                  >
                    <TextInputField
                      required
                      onChange={this.handlesubtypeChange}
                      value={subtype}
                      spellCheck={false}
                      label={'subtype'}
                      placeholder={'subtype'}
                    />
                    {this.state.isSubtype ? (
                      <span className="errorMessage">Enter Subtype</span>
                    ) : null}
                  </div>
                  <section>
                    <RadioGroup
                      label="Pages"
                      value={this.state.value}
                      options={this.state.options}
                      onChange={(value: any) => {
                        value === 'all'
                          ? this.setState({ value, isDisplayRange: false })
                          : this.setState({ value, isDisplayRange: true })
                      }}
                    />
                    {this.displayRangeField()}
                  </section>
                </Container>

                <ParserParameter
                  paramsParameter={param}
                  subtype={this.state.subtype}
                  bank={this.state.bankName}
                />
                <Button
                  style={{
                    margin: '5px 0',
                    float: 'right',
                  }}
                  isLoading={this.state.isLoading}
                  appearance="primary"
                  type={'submit'}
                  onClick={(e: any) =>
                    this.submitParams(e, callRerunParserMutation, this.closeDialog)
                  }
                >
                  Rerun
                </Button>
              </Dialog>
              <Tooltip content="Rerun Parsing">
              <Button
                className="icon"
                isLoading={this.isButtonDisabled()}
                onClick={this.showDialog}
              >
                {this.displayParser()}
              </Button>
              </Tooltip>
            </>
          )}
        </Mutation>
      </>
    )
  }

  private displayRangeField = () => {
    const { isDisplayRange, start, end } = this.state
    if (isDisplayRange) {
      return (
        <Range>
          <TextInputField
            marginRight={10}
            onChange={(e: any) => this.handleRangeField(e, 'start')}
            placeholder={'start *'}
          />
          {this.state.isStart ? (
            <span className="errorMessage">This is not a valid number</span>
          ) : null}
          <TextInputField
            onChange={(e: any) => this.handleRangeField(e, 'end')}
            placeholder={'end *'}
          />
          {this.state.isEnd ? (
            <span className="errorMessage">This is not a valid number</span>
          ) : null}
        </Range>
      )
    } else {
      return null
    }
  }

  private handleRangeField = (e: any, key: string) => {
    var regexp = /^[0-9]+$/
    if (key === 'start') {
      this.setState({
        isStart: !regexp.test(e.target.value),
      })
    } else {
      this.setState({
        isEnd: !regexp.test(e.target.value),
      })
    }
    this.setState({ [key]: e.target.value })
  }

  private submitParams = (e: any, callMutation: any, closeDialog: any) => {
    e.preventDefault()
    this.setState({
      isLoading: true,
    })
    var page_range = ''
    const { bankName, subtype, isDisplayRange } = this.state
    const start = +this.state.start
    const end = +this.state.end
    if (isDisplayRange) {
      if (start > 0 && end >= start) {
        page_range = start + '-' + end
      } else {
        start <= 0 ? alert('Value of start should be greater than zero ') : null
        end < start ? alert('Value of end should be greater or equal to start') : null
      }
    } else {
      page_range = 'all'
    }
    if (bankName && subtype && page_range != '') {
      callMutation()
        .then((res: any) => {
          if (res['data']['reProcessStatement']) {
            if (res['data']['reProcessStatement']['success']) {
              closeDialog()
              //@ts-ignore
              location.reload(true)
            } else {
              toaster.danger(res['data']['reProcessStatement']['message'])
            }
          } else {
            toaster.danger(res['errors'][0]['message'])
          }
        })
        .catch(() => {
          this.setState({
            isLoading: false,
          })
          toaster.danger('Something went wrong')
        })
    } else {
      bankName === '' ? this.setState({ isBank: true }) : this.setState({ isBank: false })

      subtype === '' ? this.setState({ isSubtype: true }) : this.setState({ isSubtype: false })
      page_range === '' ? alert('page_range can not be empty') : null
    }
  }

  private handleBankCodeChange = (e: any) => {
    this.setState({ bankName: e.target.value.trim() })
    this.state.bankName === '' ? this.setState({ isBank: true }) : this.setState({ isBank: false })
  }

  private displayParser() {
    const { buttonText } = this.props
    if (this.props.page === 'home') {
      return <Icon icon={'refresh'} color={'muted'} size={16} />
    } else {
      return <span> {buttonText} </span>
    }
  }

  private handlesubtypeChange = (e: any) => {
    this.setState({ subtype: e.target.value.trim() })
    this.state.subtype === ''
      ? this.setState({ isSubtype: true })
      : this.setState({ isSubtype: false })
  }

  private showDialog = () => this.setState({ isRerunDialogOpen: true })
  private closeDialog = () => this.setState({ isRerunDialogOpen: false })

  private isButtonDisabled = (): boolean => {
    //@ts-ignore
    const status = FilesStatus[this.props.fileStatus]
    return status === FilesStatus.EX_TBP || status === FilesStatus.EX_PRO
  }

  private generateArgumentsForReparseMutation = (): ReparseVariables => {
    var result: any[] = []
    var param: any = {}
    const bank = this.state.bankName
    const { subtype, isDisplayRange, start, end } = this.state

    const pageRange = isDisplayRange ? start + '-' + end : 'all'

    const fileToReparse = this.props.fileId

    result = this.props.params

    for (var i = 0; i < result.length; i++) {
      if (result[i].type === 'str') {
          param[result[i].key] = result[i].value.trim()
      } else if (result[i].type === 'int' || result[i].type === 'float') {
        const temp = +result[i].value
        param[result[i].key] = temp
      } else {
        param[result[i].key] = result[i].value
      }
    }
    const params = JSON.stringify(param)
    return {
      fileToReparse,
      bank,
      subtype,
      pageRange,
      params,
    }
  }
}

const Container = styled.span`
  .errorMessage{
    color: red;
    font-size: 10px
    position: relative
    top: -20px;
  }
`
const Range = styled.div`
 display: flex
 width: 100%;
 margin-bottom: 20px;
 .errorMessage{
  font-size: 10px;
  color: red;
  position: relative;
  left:-100px
  top: 42px;
}
`

const RerunParserMutation = gql`
  mutation Reparse(
    $fileToReparse: String!
    $bank: String!
    $subtype: String!
    $pageRange: String
    $params: JSONString
  ) {
    reProcessStatement(
      fileUuid: $fileToReparse
      bank: $bank
      subtype: $subtype
      pageRange: $pageRange
      params: $params
    ) {
      success
      message
    }
  }
`

const mapStateToProps = (storeData: any) => {
  return {
    params: storeData.onChangeReducer.params,
  }
}
export default connect(mapStateToProps, null)(RerunParser)
