
import {
  ApolloClient,
  createHttpLink, gql, InMemoryCache,
  NormalizedCacheObject
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import AuthStore from '../../stores/AuthStore';
import config from '../../config'
import { GRAPHQL_BASE_URL } from '../../api/api-constants'

export interface GetOrCreateUser_getOrCreateUser {
    __typename: 'GetOrCreateUser';
    userUuid: string | null;
  }

  export interface GetOrCreateUser {
    getOrCreateUser: GetOrCreateUser_getOrCreateUser | null;
  }

export const GET_OR_CREATE_USER_MUTATION = gql`
  mutation GetOrCreateUser {
    getOrCreateUser {
      userUuid
    }
  }
`;

export const getApi = (name: string)=>{
  return `${config.api.backend}${name}`
}

class Api{
    private readonly authStore: AuthStore;

    public readonly graphQLClient: ApolloClient<NormalizedCacheObject>;

/**
   * Get or create a new user
   */
 getOrCreateUser() {
    return this.graphQLClient.mutate<GetOrCreateUser>({
      mutation: GET_OR_CREATE_USER_MUTATION,
    });
  }

  constructor(authStore: AuthStore) {
    this.authStore = authStore;

    const httpLink = createHttpLink({
      uri: config.api.backend + GRAPHQL_BASE_URL
    });

    const authLink = setContext(async (_, { headers }) => {
      return {
        headers: {
          ...headers,
          ...(await this.authStore.getAuthorizationHeaders()),
        },
      };
    });

    // Initialize Apollo GraphQL Client with a request interceptor to automatically use the right Authorization token
    this.graphQLClient = new ApolloClient({
      link: authLink.concat(httpLink),
      cache: new InMemoryCache(),
    });
  }
}

export interface IStatus {
  session_id: string
  is_started: boolean
  is_finished: boolean
  is_success: boolean
  is_error: boolean
  status_code: string
  status_description: string
  result: string
}

export default Api;
