import { navigate } from '@reach/router';
import { Auth, Hub } from 'aws-amplify';
import pick from 'lodash-es/pick';
import { action, observable } from 'mobx';
import { RouterStore } from 'mobx-react-router';
import { api } from '../contexts';

type User = {
  email: string;
  username: string;
  picture?: string;
  name?: string;
  groups: string[];
};

type FederatedProvider = 'Google' | 'Facebook' | 'LoginWithAmazon';

class AuthStore {
  @observable isLoading: boolean = false;

  @observable isAuthenticated: boolean = false;

  @observable user: User = {
    email: '',
    username: '',
    groups: [],
  };

  private routingStore: RouterStore;

  get isAdmin() {
    return this.isAuthenticated && this.user.groups.includes('admin');
  }

  /**
   * Set a user.
   *
   * @param user
   */
  @action
  setUser(user: User) {
    Object.assign(this.user, user);
  }

  /**
   * Attempt to log a user in using an email-password combination.
   *
   * @param email
   * @param password
   */
  @action
  login(email: string, password: string) {}

  /**
   * Log a user in via a federated identity provider. (Eg. Google or facebook social authentication)
   *
   * @param provider the name of the provider to use.
   */
  @action
  async federatedLogin(provider: FederatedProvider) {
    this.isLoading = true;
    await Auth.federatedSignIn({ provider: provider as any });
  }

  /**
   * Attempt to register a new user into the user pool.
   *
   * @param email the email address of the user
   * @param password the password of the user.
   */
  @action
  register(email: string, password: string) {}

  /**
   * Log the user out and reset all sessions.
   */
  @action
  async logout() {
    this.isAuthenticated = false;
    this.setUser({ email: '', username: '', groups: [] });
    await Auth.signOut();
  }

  /**
   * Initiate all required details after user authentication.
   */
  @action
  private async onAuthentication() {
    const { attributes } = await Auth.currentAuthenticatedUser();
    this.isAuthenticated = true;
    const tokenPayload: any = await this.getTokenPayload();
    const userProps = ['username', 'email', 'name', 'picture'];
    const userAttributes = pick(attributes);

    for (const prop of userProps) {
      if (tokenPayload.hasOwnProperty(prop)) {
        userAttributes[prop] = tokenPayload[prop];
      }
    }

    userAttributes.groups = tokenPayload['cognito:groups'] || [];

    await api.getOrCreateUser();
    this.setUser(userAttributes as User);
    navigate('/');
  }

  @action
  async restore() {
   try {
    await Auth.currentAuthenticatedUser();
    await Auth.currentSession();
    await this.onAuthentication();
   } catch(e){
     //ignore
   }
  }


  async getTokenPayload(): Promise<any> {
    return (await Auth.currentSession()).getIdToken().payload;
  }

  /**
   * Get the access token to use for API Requests
   */
  async getAccessToken(): Promise<string|null> {
    try {
      return (await Auth.currentSession()).getIdToken().getJwtToken();
    } catch(e){
      console.error(e)
      return null;
    }
  }

  async getAuthorizationHeaders(): Promise<{ [key: string]: string| null}> {
    return {
      Authorization: await this.getAccessToken(),
      'auth-username': this.user.email
    };
  }

  /**
   * Initiate the auth store and start listening to authentication events from Amplify's Hub.
   */
  constructor(routingStore: RouterStore) {
    this.routingStore = routingStore;

    Hub.listen('auth', ({ payload: { event, data } }) => {
      switch (event) {
        case 'signIn':
        this.onAuthentication().catch(console.error);
          break;
        case 'signOut':
          break;
      }
    });
  }
}

export default AuthStore;
