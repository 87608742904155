import * as React from 'react'
import styled from 'styled-components'
import gql from 'graphql-tag'
import { Query } from 'react-apollo'
import { Link, RouteComponentProps } from '@reach/router'

const { Icon } = require('evergreen-ui')

import { colors } from '../../utils/colors'
import Logo from '../Logo/Logo'
import { extractFileDataFromS3Key } from '../../utils/s3'

interface IProps extends RouteComponentProps {
  fileId: string
}

class FileDetailLogoHeader extends React.Component<IProps> {
  public render() {
    const { fileId } = this.props
    return (
      <Logo>
        <Query
          query={GET_FILE_DETAIL_HEADER}
          variables={{ fileId }}
          fetchPolicy={'cache-and-network'}
        >
          {({ loading, error, data }) => {
            if (error || loading || !data) {
              return null
            }
            const clientId = data.files ? data.files.mUser.userId : ''
            const bankCode = data.files ? data.files.bankCode : ''
            const fileName = data.files.inputFile
              ? extractFileDataFromS3Key(data.files.inputFile).filename
              : extractFileDataFromS3Key(data.files.originalFile).filename

            return (
              <Container>
                <Link to={`/search/client/${clientId}`}>{clientId}</Link>
                <Icon icon={'chevron-right'} color={colors.white} size={16} opacity={0.8} />
                <Link to={`/search/bank/${bankCode}`}>{bankCode}</Link>
                {this.shouldShowFileNameLink() && (
                  <>
                    <Icon icon={'chevron-right'} color={colors.white} size={16} opacity={0.8} />
                    <Link to={`/files/${fileId}`}>{fileName.substring(0, 20)}…</Link>
                  </>
                )}
              </Container>
            )
          }}
        </Query>
      </Logo>
    )
  }

  private shouldShowFileNameLink() {
    return (
      this.props.path === 'files/:fileId/history' ||
      this.props.path === 'files/:fileId/history/:historyId'
    )
  }
}

const Container = styled.div`
  display: flex;
  align-items: center;
  a {
    text-decoration: none;
    color: ${colors.white};
    text-transform: uppercase;
    letter-spacing: 2px;
    padding: 0.3rem 0.5rem;
    cursor: pointer;
    opacity: 0.9;
    text-align: center;
    border-radius: 5px;
    transition: opacity 0.2s ease-out;
    :hover {
      background: rgba(0, 0, 0, 0.8);
      opacity: 1;
    }
  }
`

const GET_FILE_DETAIL_HEADER = gql`
  query GetFileDetailHeader($fileId: ID!) {
    files(id: $fileId) {
      id
      mUser {
        id
        userId
      }
      inputFile
      bankCode
      originalFile
    }
  }
`

export default FileDetailLogoHeader
