import { Table, Spinner, Tooltip, Button } from 'evergreen-ui'
import * as React from 'react'
import styled from 'styled-components'
import { TupleType } from 'typescript'
import { existingConfigs, syncConfigsFromExcelToDb } from '../../api/existing-configs-api'
import { isSuperAdmin, getTokenWithRestoreSession } from '../../api/auth-api'
import WithAuthorization from '../../components/WithAuthorization/WithAuthorization'

interface Dictionary<T> {
  [Key: string]: T
}

interface IBankConfigInfo {
  BankCode: string
  BankFullName: string
  ConfigPath: string
  SubTypes: string[]
  Titles: string[]
  SubTypeInfoMap: Dictionary<TupleType>
  ErrorMsg: string
}

interface IState {
  configInfo: IBankConfigInfo[]
  hideCommentColumn:boolean
  hideInfoColumn: boolean
  fetchInProgress: boolean
  syncInProgress: boolean
}

class ExistingConfigPage extends React.Component<any, IState> {

  public state = {
    configInfo: [],
    hideCommentColumn: false,
    hideInfoColumn: false,
    fetchInProgress: false,
    syncInProgress: false
  }

  public async componentDidMount() {
    this.setState({
      fetchInProgress: true
    })
    await getTokenWithRestoreSession() //restore session incase opening this page in a seperate tab
    existingConfigs()
      .then((result: any) => {
        if (result && result.data) {
          let hasComments = false
          let hasInfo = false
          for (let config of result.data) {
            if (config.ErrorMsg) {
              hasInfo = true
            }
            for (let key in config.SubTypeInfoMap) {
              if (config.SubTypeInfoMap[key][1]) {
                hasComments = true
              }
            }
          }
            this.setState({
              configInfo: result.data,
              hideCommentColumn: !hasComments,
              hideInfoColumn: !hasInfo
            })
          }
        })
      .finally(() => {
        this.setState({
          fetchInProgress: false,
        })
      })
  }

  private displaySubItems = (items: string[]) => {
    const sub_items_ui = items.map((item: any) => {
      return <li>{item}</li>
    })
    return <ul>{sub_items_ui}</ul>
  }

  private displayError = (item: IBankConfigInfo) => {
    if(item.ErrorMsg){
      return (
        <Table.TextCell  textProps={{color: 'red', fontWeight:'bold'}}>
          {item.ErrorMsg}
        </Table.TextCell>
      )       
    }else{
      return (
        <Table.TextCell>
          {'-'}
        </Table.TextCell>
      )
    }    
  }

  private displaySubItemTitle = (element: any) => {
    return (
      <Table.TextCell style={{ lineHeight: '1.8' }}>
        {Object.keys(element.SubTypeInfoMap).map(subType => {
          let title = element.SubTypeInfoMap[subType][0]
          return (
            <>
              {title || '-'}
              <br />
            </>
          )
        })}
      </Table.TextCell>
    )
  }
  private displaySubItemComment = (element: any) => {
    return (
      <Table.TextCell style={{ lineHeight: '1.8' }}>
        {Object.keys(element.SubTypeInfoMap).map(subType => {
          let comment = element.SubTypeInfoMap[subType][1]
          return (
            <>
              {comment || '-'}
              <br />
            </>
          )
        })}
      </Table.TextCell>
    )
  }
  private displaySubItemType = (element: any) => {
    return (
      <Table.TextCell  style={{ lineHeight: '1.8' }} flexBasis={200} flexShrink={0} flexGrow={0} >
        {Object.keys(element.SubTypeInfoMap).map(subType => {
          return (
            <>
              {subType || '-'}
              <br />
            </>
          )
        })}
      </Table.TextCell>
    )
  }
  private syncBankConfig = ()=> {
    this.setState({
      syncInProgress: true
    })
    syncConfigsFromExcelToDb().finally(() => {
      this.componentDidMount()
      this.setState({
        syncInProgress: false
      })
    })
  }

  private displayConfigs = () => {
    const configInfo: IBankConfigInfo[] = this.state.configInfo
    const  hideCommentColumn = this.state.hideCommentColumn
    const  hideInfoColumn = this.state.hideInfoColumn
    const  inProgress = this.state.fetchInProgress
    const  showSyncButton = isSuperAdmin()
    if (inProgress) {
      return <Spinner marginX="auto" marginY={120} size={72} />
    } else {
      return (
        <FlexContainer style={{ background: 'white' }}>
          <Table style={{ width: '100%' }}   >
            <Table.Body>
              <Table.Row height="auto" style={{ display: (showSyncButton ? 'inherit' : 'none') }}>
                <Table.TextCell style={{ padding: '4px'}} textProps={{ textAlign:'center' }}  >
                  <Button onClick={this.syncBankConfig} appearance="primary" isLoading={this.state.syncInProgress} iconBefore="refresh">
                  Refresh Config List
                  </Button>
                </Table.TextCell>
              </Table.Row>
              <Table.Row height="auto" style={{ padding: '4px' }}>
                <Table.TextCell>
                  <Table style={{ width: '100%' }}>
                    <Table.Head>
                      <Table.TextHeaderCell textProps={{ fontSize: 14, fontWeight: 'bold' }} >Full Bank Name</Table.TextHeaderCell>
                      <Table.TextHeaderCell textProps={{ fontSize: 14, fontWeight: 'bold' }} flexBasis={95} flexShrink={0} flexGrow={0}>
                        Config File
                      </Table.TextHeaderCell>
                      <Table.TextHeaderCell textProps={{ fontSize: 14, fontWeight: 'bold' }} flexBasis={200} flexShrink={0} flexGrow={0} >
                        Subtype
                      </Table.TextHeaderCell>
                      <Table.TextHeaderCell textProps={{ fontSize: 14, fontWeight: 'bold' }}>Title of Statement (as seen in the PDF)</Table.TextHeaderCell>
                      <Table.TextHeaderCell textProps={{ fontSize: 14, fontWeight: 'bold' }} style={{ display: (hideCommentColumn ? 'none' : 'inherit') }} >Comments</Table.TextHeaderCell>
                      {/* <Table.TextHeaderCell textProps={{ fontSize: 14, fontWeight: 'bold' }} style={{ display: (hideInfoColumn ? 'none' : 'inherit') }} >Info</Table.TextHeaderCell> */}
                    </Table.Head>
                    <Table.Body>
                      {configInfo.map((element: any) => {
                        let tableRow = <Table.Row height="auto" style={{ padding: '4px' }} borderBottom="3px solid #E4E7EB" intent={element.ErrorMsg ? 'danger' : 'none'} isHighlighted={(!!element.ErrorMsg)}>
                          <Table.TextCell textProps={{ fontWeight: 'bold' }} >{element.BankFullName}</Table.TextCell>
                          <Table.TextCell flexBasis={95} flexShrink={0} flexGrow={0}>
                            <a href={element.ConfigPath}>{element.BankCode}</a>
                          </Table.TextCell>
                          {this.displaySubItemType(element)}
                          {this.displaySubItemTitle(element)}
                          {hideCommentColumn ? '' : this.displaySubItemComment(element)}
                          {/* {hideInfoColumn?'':this.displayError(element)} */}
                        </Table.Row>

                        if (element.ErrorMsg) {
                          return (<Tooltip content={element.ErrorMsg}>
                            {tableRow}
                          </Tooltip>)
                        } else {
                          return (<>{tableRow}</>)
                        }
                      })}
                    </Table.Body>
                  </Table>
                </Table.TextCell>
              </Table.Row>
            </Table.Body>
          </Table>
        </FlexContainer>
      )
    }
  }
  public render() {
    return (
      <>
        <Container>{this.displayConfigs()}</Container>
      </>
    )
  }
}

export default WithAuthorization(ExistingConfigPage)

const Container = styled.div`
  overflow: auto;
  font-size: 0.9rem;
  line-height: 1.2rem;
  position: relative;
  z-index: 2;
  border-radius: 0 0 5px 5px;
  font-family: monospace, monospace;
`

const FlexContainer = styled.div`
  display: flex;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  padding: 20px;
  margin-bottom: 10px;
  .control-label {
    font-weight: bold;
  }
`
