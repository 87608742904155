import { Link } from '@reach/router'
import dayjs from 'dayjs'
import * as React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import styled from 'styled-components'
import { downloadFile } from '../../api/file-downloader-api'
import { OnClickAction } from '../../components/BulkOperation/toggleAction'
import { colors } from '../../utils/colors'
import {cleanInputFile} from '../../utils/FileUtils'
import { extractFileDataFromS3Key } from '../../utils/s3'
import { FilesStatus,FilesConversionStatus } from '../../schemaTypes'
import BankStatusComp from '../BankStatus/BankStatus'
import FileStatus from '../FileStatus/FileStatus'
import RerunParser from '../RerunParser/RerunParser'
import { canopySimulator,downloadFile as ulDownloadFile } from '../../api/canopy-simulator'
import { OncheckAction } from './oncheckAction'
const { Text, Icon,Tooltip, Button, Heading, Checkbox, Spinner, Pill, toaster } = require('evergreen-ui')

export interface IState {
  bulkUploadData: any[]
  checked?: boolean
  temp: any
  isPdf: boolean
  isShowPdf: boolean
  isExcel: boolean
  isCanopy: boolean
  isConversionRunning:boolean
}
export const initialParamsState: IState = {
  bulkUploadData: [],
  checked: false,
  temp: null,
  isPdf: true,
  isShowPdf: true,
  isExcel: true,
  isCanopy: true,
  isConversionRunning:false
}
class FileItem extends React.Component<any, IState> {
  public state = initialParamsState
  private async showPdf(Id: string, file: string, e: any) {
    e.preventDefault()
    this.setState({
      isShowPdf: false,
    })
    const fileName = file + '.' + 'pdf'
    downloadFile(Id, fileName, 'input_pdf', true).then((result: any) => {
      if (result) {
        this.setState({
          isShowPdf: true,
        })
      } else {
        this.setState({
          isShowPdf: true,
        })
      }
    })
  }

  private async downloadPdf(Id: string, file: string, e: any) {
    e.preventDefault()
    this.setState({
      isPdf: false,
    })
    const fileName = file + '.' + 'pdf'
    downloadFile(Id, fileName, 'input_pdf').then((result: any) => {
      if (result) {
        this.setState({
          isPdf: true,
        })
      } else {
        this.setState({
          isPdf: true,
        })
      }
    })
  }

  private async downloadExcel(Id: string, file: string, e: any) {
    this.setState({
      isExcel: false,
    })
    const fileName = file + '.' + 'xlsx'
    downloadFile(Id, fileName, 'output_excel').then((result: any) => {
      if (result) {
        this.setState({
          isExcel: true,
        })
      } else {
        this.setState({
          isExcel: true,
        })
      }
    })
  }
  private downloadCanopyUl = async (Id: string, file: string, e: any) => {
    this.setState({
      isCanopy: false,
    })
    const fileName = file + '.' + 'xlsx'
    downloadFile(Id, fileName, 'canopy_ul').then((result: any) => {
      if (result) {
        this.setState({
          isCanopy: true,
        })
      } else {
        this.setState({
          isCanopy: true,
        })
      }
    })
  }
  private addBulkData(data: any, e: any) {
    this.setState({
      checked: e.target.checked,
    })
    this.props.OncheckAction(data)
  }
  private displayDownloadIcon = () => {
    const {
      bankCode,
      fileUuid,
      inputFile,
      params,
      parsingStatus,
      subtype,
      pageRange,
      originalFile,
    } = this.props
    const filename = inputFile !== null ? inputFile : originalFile
    return (
      <>
        <div className="fileOpenImage" onClick={() => this.showPdf(fileUuid, filename, event)}>
          {this.state.isShowPdf ? (
            <img src="/assets/fileopen.png" alt="pdf" height="30px" width="30px" />
          ) : (
            <Spinner className="Spinner" />
          )}
        </div>
        <div className="pdfImage" onClick={() => this.downloadPdf(fileUuid, filename, event)}>
          {this.state.isPdf ? (
            <img src="/assets/pdf.png" alt="pdf" height="30px" width="30px" />
          ) : (
            <Spinner className="Spinner" />
          )}
        </div>
        <div className="excelImage" onClick={() => this.downloadExcel(fileUuid, filename, event)}>
          {this.state.isExcel ? (
            <img src="/assets/excel.png" alt="excel" height="40px" width="38px" />
          ) : (
            <Spinner className="Spinner" />
          )}
        </div>
        <div
          className="canopyImage"
          onClick={(e: any) => this.downloadCanopyUl(fileUuid, filename, event)}
        >
          {this.state.isCanopy ? (
            <img src="/assets/canopyul.jpg" alt="excel" height="40px" width="38px" />
          ) : (
            <Spinner className="Spinner" />
          )}
        </div>
        <div className="rerunParser">
          <RerunParser
            //@ts-ignore
            fileId={fileUuid}
            fileStatus={parsingStatus}
            param={params}
            page="home"
            bankName={bankCode}
            subType={subtype}
            pageRange={pageRange}
          />
        </div>
        {this.displayRerunConversion()} 
      </>
    )
  }

  private displayRerunConversion(){
    const {
      bankCode,
      fileUuid,
      parsingStatus,
      conversionStatus,
      subtype,
    } = this.props
    if(parsingStatus == FilesStatus.EX_SUC){
      return  <div className="rerunConversion">
          <Tooltip content="Rerun Conversion">
            <Button
              isLoading={this.state.isConversionRunning || this.isfileConversionInProgress(conversionStatus)}
              onClick={() => this.rerunConversion(fileUuid, bankCode, subtype)}
            >
              <img src="/assets/transform.png" alt="excel" height="20px" width="20px" />
            </Button>
          </Tooltip>
        </div>
    }
    return ""
  }
  private isfileConversionInProgress =(conversionStatus:FilesConversionStatus): boolean =>{
    return (conversionStatus === FilesConversionStatus.CV_TBP || conversionStatus === FilesConversionStatus.CV_PRO )
  } 

  private rerunConversion(fileUuid:string, bankCode:string, subtype:string){
    this.setState({
      isConversionRunning: true
    })
    canopySimulator(undefined, undefined, bankCode, subtype, fileUuid)
    .then(async (res: any) => {
      const { canopy_ul_file, log } = res.body['data']
      await ulDownloadFile(canopy_ul_file)
    })
    .catch((e: any) => {
      const error =
        e.response && e.response.body && e.response.body.error
          ? e.response.body.error
          : 'Unknown Error'
      toaster.danger(error)
    }).finally(()=>{
      this.setState({
        isConversionRunning: false
      })
    })
  }

  private displayBank() {
    const { bankCode } = this.props
    if (bankCode != null) {
      return <Text>{bankCode.toUpperCase()}</Text>
    } else {
      return <Text>N/A</Text>
    }
  }
  private displaySubtype() {
    const { subtype } = this.props
    if (subtype != null) {
      return <Text>{subtype.toUpperCase()}</Text>
    } else {
      return <Text>N/A</Text>
    }
  }
  private displayStatus() {
    const { parsingStatus, bankStatus, bankPredictedBy } = this.props
    return (
      <StatusContainer>
        <div className="flexbox">
          <div style={{ width: '55px' }}>Bank</div>
          <span style={{ marginLeft: '10px' }}>
            <BankStatusComp status={bankStatus} />
          </span>
        </div>
        <div className="flexbox">
          <div style={{ width: '55px' }}>Parsing</div>
          <span style={{ marginLeft: '10px' }}>
            <FileStatus status={parsingStatus} />
          </span>
        </div>
        {bankPredictedBy && (
          <div className="flexbox">
            <div style={{ width: '55px' }}>Model</div>
            <span style={{ marginLeft: '10px' }}>
              <Pill style={{ textTransform: 'capitalize' }}>{bankPredictedBy}</Pill>
            </span>
          </div>
        )}
      </StatusContainer>
    )
  }

  private displayFileItem() {
    const { inputFile, mUser, id, updatedAt, originalFile } = this.props
    if (inputFile !== null) {
      const { filename } = extractFileDataFromS3Key(inputFile)
      const file = cleanInputFile(inputFile)
      return (
        <>
          <FlexContainer style={{ background: 'white' }}>
            <div className="checkbox-container">
              <Checkbox
                checked={this.state.checked}
                onChange={() => this.addBulkData(this.props, event)}
              />
            </div>
            <div className="common-container">
              <Link to={`/files/${id}`} style={{ textDecoration: 'none' }}>
                <div className="file-name-container">
                  <Heading size={500}>{file}</Heading>
                </div>
                <div className="common-name-container">
                  <div className="profile-container">
                    <Icon icon={'user'} color={'muted'} />
                    <span className="bank-name">
                      <Text>{mUser.userId.toUpperCase()}</Text>
                    </span>
                  </div>
                  <div className="bank-container">
                    <img src="/assets/bank.svg" alt="" height="16px" style={{ fill: 'pink' }} />
                    <span className="bank-name">{this.displayBank()}</span>
                  </div>
                  <div className="subtype-container">
                    <Icon icon="git-branch" color="muted" />
                    <span className="bank-name">{this.displaySubtype()}</span>
                  </div>
                </div>
              </Link>
            </div>
            <div className="status-container">{this.displayStatus()}</div>
            <div className="download-container">{this.displayDownloadIcon()}</div>
          </FlexContainer>
          <DateContainer>
            <span>Last modified:</span>
            {dayjs(updatedAt).format('DD MMM YYYY, HH:mm')}
          </DateContainer>
        </>
      )
    } else {
      const { filename } = extractFileDataFromS3Key(originalFile)
      const file = filename.split(/[/]+/).pop()
      return (
        <>
          <FlexContainer style={{ background: '#f5f5f5' }}>
            <div className="checkbox-container">
              <Checkbox
                checked={this.state.checked}
                onChange={() => this.addBulkData(this.props, event)}
              />
            </div>
            <div className="common-container">
              <Link to={`/files/${id}`} style={{ textDecoration: 'none' }}>
                <div className="file-name-container">
                  <Heading size={500}>{file}</Heading>
                </div>
                <div className="common-name-container">
                  <div className="profile-container">
                    <Icon icon={'user'} color={'muted'} />
                    <span className="bank-name">
                      <Text>{mUser.userId.toUpperCase()}</Text>
                    </span>
                  </div>
                  <div className="bank-container">
                    <img src="/assets/bank.svg" alt="" height="16px" style={{ fill: 'pink' }} />
                    <span className="bank-name">{this.displayBank()}</span>
                  </div>
                  <div className="subtype-container">
                    <Icon icon="git-branch" color="muted" />
                    <span className="bank-name">{this.displaySubtype()}</span>
                  </div>
                </div>
              </Link>
            </div>
            <div className="status-container">{this.displayStatus()}</div>
            <div className="download-container">{this.displayDownloadIcon()}</div>
          </FlexContainer>
          <DateContainer>
            <span>Last modified:</span>
            {dayjs(updatedAt).format('DD MMM YYYY, HH:mm')}
          </DateContainer>
        </>
      )
    }
  }
  public componentDidMount() {
    this.props.OnClickAction([])
  }
  public componentWillReceiveProps = () => {
    this.setState({ checked: !this.props.selectAll })
  }
  public render() {
    return this.displayFileItem()
  }
}
const mapDispatch = (dispatch: any) => {
  return bindActionCreators(
    {
      OncheckAction,
      OnClickAction,
    },
    dispatch,
  )
}
export default connect(null, mapDispatch)(FileItem)
const FlexContainer = styled.div`
  display: flex;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  padding: 20px;
  margin-bottom: 10px;
  .common-container {
    width: 55%;
    .file-name-container {
      width: 90%;
      margin-bottom: 10px;
      word-break: break-word;
    }
    .common-name-container {
      display: flex;
      .profile-container {
        width: 40%;
        .bank-name {
          padding-left: 5px;
        }
      }
      .subtype-container {
        width: 35%;
        .bank-name {
          padding-left: 5px;
        }
      }
      .bank-container {
        width: 20%;
        .bank-name {
          padding-left: 5px;
        }
      }
    }
 
  }
  .download-container {
    width: 20%;
    display : flex
    .pdfImage {
      padding: 15px 10px 10px 10px;
      cursor: pointer;
    }
    .fileOpenImage {
      padding: 15px 10px 10px 10px;
      cursor: pointer;
    }
    .excelImage {
      padding: 10px
      cursor: pointer;
    }
    .canopyImage{
      padding: 10px
      cursor: pointer;
    }
    .rerunParser{
      padding: 10px
      cursor: pointer;
    }
    .rerunConversion{
      padding: 10px
      cursor: pointer;
    }
  }
  .checkbox-container {
    width: 4%;
  }
  .status-container {
    width: 20%;
  }
  .date-container {
    position: absolute;
    right: 1.5%;
    padding-top: 4%;
    font-size: 10px;
    color: grey;
  }
`
const StatusContainer = styled.div`
font-size: 14px;
color: ${colors.black}
.flexbox{
  display: flex
  margin-top: 10px;
}
`
const DateContainer = styled.div`
position: relative;
padding-left: 80vw;
bottom: 3.5vh;
font-size: 10px;
color: grey;
}
`
