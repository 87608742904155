import * as React from 'react'
import { RouteComponentProps } from '@reach/router'
import gql from 'graphql-tag'
import { Query } from 'react-apollo'
import styled from 'styled-components'
import ParamsLogContainer from '../../components/ParserLog/ParserLog'
import WithAuthorization from '../../components/WithAuthorization/WithAuthorization'
import FileDetailLogoHeader from '../../components/FileDetailLogoHeader/FileDetailLogoHeader'
import CustomCard from '../../components/CustomCard/CustomCard'
import Stacktrace from '../../components/Stacktrace/Stacktrace'
import CanopyUlLog from '../../components/CanopyUlLog/canopyUlLog'
import { GetDetailedHistory_allHistory_edges_node } from '../../schemaTypes'
import FileStatusStripe from '../../components/FileStatusStripe/FileStatusStripe'
import FileHistoryDetailedItem from '../../components/FileHistoryDetailedItem/FileHistoryDetailedItem'
import ParamsCardContainer from '../../components/Params/ParamsCardContainer'
interface IProps extends RouteComponentProps {
  historyId?: string
}
class FileHistoryItemPage extends React.Component<IProps> {
  public render() {
    const { historyId } = this.props
    return (
      <main>
        <Query query={GET_DETAILED_HISTORY} variables={{ historyId }}>
          {({ loading, error, data }) => {
            if (loading || error) {
              return <span>blah</span>
            }
            if (!data) {
              return <span>no data found</span>
            }
            const history: GetDetailedHistory_allHistory_edges_node = data.allHistory.edges[0].node
            const taskId = data.allHistory.edges[0].node['taskId']
            return (
              <Container>
                <CustomCard type={'allFiles'}>
                  <FileStatusStripe status={history.parsingStatus} />
                  <FileHistoryDetailedItem {...history} />
                </CustomCard>
                <ParamsCardContainer params={history.params} />
                <ParamsLogContainer params={taskId} />
                <Stacktrace logs={history.description} />
                <CanopyUlLog logs={history.canopyUlLog} />
              </Container>
            )
          }}
        </Query>
      </main>
    )
  }
}
const Container = styled.div`
  display: grid;
  grid-gap: 24px;
`
const GET_DETAILED_HISTORY = gql`
  query GetDetailedHistory($historyId: String!) {
    allHistory(historyUuid: $historyId) {
      edges {
        node {
          id
          historyUuid
          taskId
          parsingStatus
          createdAt
          updatedAt
          processingAt
          finishedAt
          description
          canopyUlLog
          params
          isOcrPdf
          isPdfDecrypted
          isPdfRepaired
          isPdfRotated
          processedPdfFile
          processedPdfFileHtml
          mFile{
            fileUuid
          }
        }
      }
    }
  }
`
export default WithAuthorization(FileHistoryItemPage, FileDetailLogoHeader)
