import { Paragraph } from 'evergreen-ui'
import * as React from 'react'
import styled from 'styled-components'
import _ from 'lodash-es';
const { Dialog, Button, Checkbox, withTheme, Heading } = require('evergreen-ui')
import  {FilesStatus}  from '../../schemaTypes'
import {extractNameFromFile, downloadURI} from '../../utils/FileUtils'
import { replaceParsedOutput } from '../../api/canopy-simulator'

const default_state = {
  isReplaceDialogOpen: false,
  runConversion: true, 
  isLoading: false,
  parsedFile: undefined,
  error: '',
}

function CustomHeader() {
  return (
    <section>
      <Heading size={500} marginTop="default">
       Replace Parsed Output
      </Heading>
      <Paragraph size={300} marginTop="default">
        Are you sure you want to Replace parsed output:
      </Paragraph>
    </section>
  )
}

class ReplaceParsedOutput extends React.Component<any, any> {
  public static defaultProps = {
    buttonText: 'Replace Parsed Output',
    buttonTheme: 'default',
  }
  public state = _.cloneDeep(default_state)

  public render(): React.ReactNode {
    const { parsedFile } = this.state
    const fileLabel = extractNameFromFile(parsedFile, 'excel')
    if (this.props.fileStatus != FilesStatus.EX_SUC){
      return null
    }
    return (
      <>
        <Dialog
          contentContainerProps={{
            padding: 10,
          }}
          header={CustomHeader()}
          isShown={this.state.isReplaceDialogOpen}
          onCloseComplete={this.closeDialog}
          confirmLabel={'Replace Parsed Output'}
          hasFooter={false}
          hasHeader={true}
          title="Replace Parsed Output"
          topOffset={'6vmin'}
          preventBodyScrolling={true}
        >
          <Containers>
            <section className="upload-section">
              <span>Parsed Excel Output</span>
              <input
                className="inputfile"
                type="file"
                id="parsedOutput"
                name="parsedOutput"
                accept=".xls, .xlsx"
                onChange={(e: any) => {
                  this.setState({ parsedFile: e.target.files[0] })
                }}
              />
              <label className={this.props.theme.getButtonClassName()} htmlFor="parsedOutput">
                {fileLabel}
              </label>
            </section>
            <div
              style={{
                padding: '5px 10px',
              }}
            >
                <Checkbox
                label="Run Conversion"
                checked={this.state.runConversion}
                onChange={(e: any) => this.setState({ runConversion: e.target.checked })}
                />
            </div>
            
          </Containers>
          {this.state.error && <StyledAlert>{this.state.error}</StyledAlert>}
          <Button
            style={{
              margin: '5px 0',
              float: 'right',
            }}
            isLoading={this.state.isLoading}
            disabled={Boolean(!(this.state.parsedFile))}
            appearance="primary"
            type={'submit'}
            onClick={this.onConfirmUpload}
          >
            Replace
          </Button>
          <Button
            appearance="minimal"
            onClick={this.closeDialog}
            style={{
              margin: '5px 0',
              float: 'right',
            }}
          >
            Cancel
          </Button>
        </Dialog>
        <Button className="icon" onClick={this.showDialog}>
          {this.displayParser()}
        </Button>
      </>
    )
  }

  private onConfirmUpload = () => {
    const { parsedFile, runConversion } = this.state
    this.setState({ isLoading: true, error: '' })
    replaceParsedOutput(parsedFile, runConversion, this.props.fileId)
      .then((res: any) => {
        this.closeDialog()
        const  excel_output  = res.body['excel_output']
        if (excel_output) {
            downloadURI(excel_output, "Canopy_ul")
        }
      })
      .catch((e: any) => {
        const error =
          e.response && e.response.body && e.response.body.error
            ? e.response.body.error
            : 'Unknown Error'
        this.setState({ error: error, isLoading: false })
      })
  }

  private displayParser() {
    const { buttonText } = this.props
    return <span> {buttonText} </span>
  }

  private showDialog = () => this.setState({ isReplaceDialogOpen: true })
  private closeDialog = () => this.setState(_.cloneDeep(default_state))
}

const Containers = styled.span`
  .errorMessage{
    color: red;
    font-size: 10px
    position: relative
    top: -20px;
  }
  section {
    margin-right: 20px;
    span {
      margin-right: 10px;
    }
  }
  .upload-section {
    padding: 1rem;
  }
  .inputfile {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    position: absolute;
    z-index: -1;
  }
  .inputfile + label {
    padding: 0.5rem;
    border-radius: 2px;
    width: 150px;
    overflow: hidden;
  }
  .inputfile + label {
    cursor: pointer;
  }
`

const StyledAlert = styled.div`
  background-color: #fff2f0;
  border: 1px solid #ffccc7;
  margin: 20px 0;
  padding: 10px;
`

export default withTheme(ReplaceParsedOutput)
