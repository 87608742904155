import { Link } from '@reach/router'
import dayjs from 'dayjs'
import * as React from 'react'
import { CircularProgressbar } from 'react-circular-progressbar'
import 'react-circular-progressbar/dist/styles.css'
import styled from 'styled-components'
import { HistoryStatus } from '../../schemaTypes'
import { colors } from '../../utils/colors'
import CancelTask from '../CancelRerunParser/CancelRerunTask'
import { fileStatusEnum } from '../FileStatus/file-status.utils'
import FileStatus from '../FileStatus/FileStatus'
import { downloadFile } from '../../api/file-downloader-api'
import { extractFileDataFromS3Key } from '../../utils/s3'
const { Heading, Text, Badge, Button, Checkbox } = require('evergreen-ui')
interface IFileHistoryDetailedItemStatusContainerProps {
  startTime: any | null
  endTime?: string | null
  title: string
}

interface IState {
  pdfDownloadInProgress: boolean
  htmlDownloadInProgress: boolean
  excludeImage: boolean
}

const initialState = {
  pdfDownloadInProgress: false,
  htmlDownloadInProgress: false,
  excludeImage: false,
}

const FileHistoryDetailedItemStatusContainer: React.FunctionComponent<IFileHistoryDetailedItemStatusContainerProps> = ({
  startTime,
  endTime,
  title,
}: IFileHistoryDetailedItemStatusContainerProps) => {
  return (
    <>
      <div className="item-grid">
        <Text className="extraction-status">{title}</Text>
        <Text className="extraction-time">
          {startTime && <time>{dayjs(startTime).format(`DD MMM YYYY , HH:mm`)}</time>}
        </Text>
      </div>
      {startTime && endTime && (
        <div className="time-diff">
          <div className="line" />
          <Text className="time-diff-text">
            {convertTimestamp(dayjs(endTime).diff(startTime, 'second', false))}
          </Text>
        </div>
      )}
    </>
  )
  function convertTimestamp(diff: number) {
    const units = ['h', 'm', 's']
    return (
      units.reduce(
        (obj, unit, index) => {
          const time = Math.trunc(obj.diff / Math.pow(60, units.length - index - 1))
          if (time === 0) {
            return { diff: obj.diff, timestamp: obj.timestamp }
          }
          return {
            diff: obj.diff / 60,
            timestamp: `${obj.timestamp} ${time}${unit}`,
          }
        },
        {
          diff,
          timestamp: '',
        },
      ).timestamp || '0s'
    )
  }
}

class FileHistoryDetailedItem extends React.Component<any> {
  public state = initialState

  public render() {
    const {
      parsingStatus,
      createdAt,
      processingAt,
      finishedAt,
      historyUuid,
      taskId,
      meta,
    } = this.props
    return (
      <Container key={historyUuid}>
        {this.displayProcessedFileInfo()}
        <div className="flex-container">
          <section>
            <Link to={`${location.pathname}/${historyUuid}`} style={{ textDecoration: 'none' }}>
              <div className="header">
                <Heading is={'h2'}>{dayjs(createdAt).format('DD MMM YYYY, HH:mm')}</Heading>
                <FileStatus status={parsingStatus} />
              </div>
              <FileHistoryDetailedItemStatusContainer
                startTime={createdAt}
                endTime={processingAt}
                title={'To be processed'}
              />
              <FileHistoryDetailedItemStatusContainer
                startTime={processingAt}
                endTime={finishedAt}
                title={'Processing'}
              />
              {this.shouldShowEndState(parsingStatus) && (
                <FileHistoryDetailedItemStatusContainer
                  startTime={finishedAt}
                  //@ts-ignore
                  title={fileStatusEnum[parsingStatus]}
                />
              )}
            </Link>
          </section>
          <section>
            <div className="progressBar">{this.displayProgress(meta, parsingStatus)}</div>
          </section>
          <section>
            <div className="cancelTask">{this.displayCancelTask(parsingStatus, taskId)}</div>
          </section>
        </div>
      </Container>
    )
  }

  private shouldShowEndState = (status: HistoryStatus) => {
    return (
      status === HistoryStatus[HistoryStatus.EX_SUC] ||
      status === HistoryStatus[HistoryStatus.EX_FAI]
    )
  }

  private displayCancelTask = (status: string, taskId: string) => {
    if (status === 'EX_PRO' || status === 'EX_TBP') {
      return <CancelTask taskId={taskId} />
    } else {
      return null
    }
  }

  private displayProgress = (meta: any, status: string) => {
    if (status === 'EX_PRO') {
      try {
        const Meta = JSON.parse(meta)
        return (
          <CircularProgressbar
            value={Meta['progress']['current']}
            text={`${Meta['progress']['current']}%`}
          />
        )
      } catch (e) {
        return <CircularProgressbar value={0} text={`N/A`} />
      }
    } else {
      return null
    }
  }

  private getFilename = (filepath: string, type: 'html' | 'pdf') =>
    `${extractFileDataFromS3Key(filepath).filename}.${type}`

  private showPdf = async (e: any) => {
    e.preventDefault()
    this.setState({ pdfDownloadInProgress: true })
    await downloadFile(this.props.mFile.fileUuid, this.getFilename(this.props.processedPdfFile, 'pdf'), 'input_processed_pdf', true, this.props.historyUuid)
    this.setState({ pdfDownloadInProgress: false })
  }

  private showHtml = async (e: any) => {
    e.preventDefault()
    this.setState({ htmlDownloadInProgress: true })
    await downloadFile(this.props.mFile.fileUuid, this.getFilename(this.props.processedPdfFileHtml, 'pdf'), 'input_processed_pdf_html', true, this.props.historyUuid, this.state.excludeImage)
    this.setState({ htmlDownloadInProgress: false })
  }

  private displayProcessedFileInfo = () => {
    const fileTransformBadgeList = []
    if (this.props.isOcrPdf) {
      fileTransformBadgeList.push(<Badge color='orange'>OCR USED</Badge>)
    }
    if (this.props.isPdfDecrypted) {
      fileTransformBadgeList.push(<Badge color='orange'>PDF DECRYPTED</Badge>)
    }
    if (this.props.isPdfRepaired) {
      fileTransformBadgeList.push(<Badge color='orange'>PDF REPAIRED</Badge>)
    }
    if (this.props.isPdfRotated) {
      fileTransformBadgeList.push(<Badge color='orange'>PDF ROTATED</Badge>)
    }
    if (fileTransformBadgeList.length > 0 && this.props.processedPdfFile) {
      fileTransformBadgeList.splice(0, 0,
        <Button title="Transformed Input PDF(This was the final PDF used for parsing)" isLoading={this.state.pdfDownloadInProgress} onClick={this.showPdf}>Show PDF</Button>
      )
    }
    if (this.props.processedPdfFileHtml) {

      fileTransformBadgeList.splice(0, 0,
        <>
          <Checkbox label="Exclude Images" checked={this.state.excludeImage} onChange={(e: any) => this.setState({ excludeImage: e.target.checked })} />
          <Button title="PDF data as read by parser" isLoading={this.state.htmlDownloadInProgress} onClick={this.showHtml}>Show Parser PDF View</Button>
        </>
      )
    }
    if (fileTransformBadgeList.length > 0) {
      return (
        <PdfFileInfoContainer className={'border-gray'} style={{ borderStyle: 'solid', borderRadius: 10, marginTop: 10, marginBottom: 20 }} >
          <legend className={'legend'}>Pdf File Info</legend>
          {fileTransformBadgeList}
        </PdfFileInfoContainer>
      )
    }
    else {
      return null

    }
  }
}



const PdfFileInfoContainer: any = styled.fieldset`
display: flex;
width: 50%;
.border-gray{
  border-width: 2px;
  border-style: solid;
  border-color: #D3D3D3;
  border-radius: 10px;
  padding:10px;
  margin-top: 10px;
  margin-bottom: 20px;
}
.legend {
  width: initial;
  border-bottom: 0;
  margin-bottom: 0;
  font-weight: bold;
  text-align: center;
  color: #286090;
}
button {
  height: 18px;
  margin-right: 5px;
}
strong{
  margin-right: 5px;
}
label{
  margin-top: 0px;
  margin-bottom: 0px;
  margin-right: 5px;
}
`


const Container: any = styled.div`
  .flex-container {
    display: flex;
    section {
      width: 33%;
      .progressBar {
        width: 25%;
        padding-top: 10px;
      }
      .cancelTask {
        padding-top: 20px;
      }
    }
  }
  .header {
    display: flex;
    margin-bottom: 0.5rem;
    align-items: baseline;
    > h2 {
      margin: 0 1rem 0 0;
    }
  }
  .item-grid {
    display: flex;
    align-items: center;
  }
  .extraction-status {
    width: 10rem;
    text-transform: capitalize;
  }
  .extraction-time {
    margin-right: 2rem;
  }
  .line {
    margin-left: 1rem;
    margin-right: 0.5rem;
    height: 3rem;
    width: 2px;
    background: ${colors.hoverBackground};
  }
  .time-diff {
    display: flex;
    align-items: center;
    color: ${colors.darkBlack};
  }
  .time-diff-text {
    font-style: italic;
    opacity: 0.6;
  }
`

export default FileHistoryDetailedItem
