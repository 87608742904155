import * as React from 'react'
import styled from 'styled-components'
import TitleCard from '../TitleCard/TitleCard'
import WithAuthorization from '../../components/WithAuthorization/WithAuthorization'
import { colors } from '../../utils/colors'

// @ts-ignore
const hasClipboardApi = navigator.clipboard

const { Button, toaster } = require('evergreen-ui')
const toasterOptions = { duration: 2, id: 'unique-toaster' }

class CanopySimulatorLog extends React.Component<any> {
  public render() {
    const logs = this.props.location.state.log
    return (
      <TitleCard
        title={'Canopy UL 2.0 Simulator'}
        icon={'console'}
        actionComponent={
          <div>
            {hasClipboardApi && (
              <Button
                iconBefore={'clipboard'}
                appearance="minimal"
                onClick={() => {
                  // @ts-ignore
                  navigator.clipboard
                    .writeText(logs)
                    .then(() => toaster.success('Copied!', toasterOptions))
                    .catch(() => toaster.danger('Failed to Copy', toasterOptions))
                }}
              >
                Copy
              </Button>
            )}
          </div>
        }
      >
        <Container>
          <pre>
            <code>{logs}</code>
          </pre>
        </Container>
      </TitleCard>
    )
  }
}

export default WithAuthorization(CanopySimulatorLog)
const Container = styled.div`
  background-color: ${colors.darkBlack};
  overflow: auto;
  padding: 1rem;
  margin: -12px -24px -24px -24px;
  font-size: 0.9rem;
  line-height: 1.2rem;
  position: relative;
  z-index: 2;
  border-radius: 0 0 5px 5px;
  color: rgba(255, 255, 255, 0.9);
`
