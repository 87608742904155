import * as React from 'react'
import WithAuthorization from '../WithAuthorization/WithAuthorization'
import GetAllFiles from '../../pages/Stats/getAllFile'
import GetBankDetails from '../../pages/Stats/getAllBanks'
const { Card } = require('evergreen-ui')
import { colors } from '../../utils/colors'
import { Helmet } from 'react-helmet'
class Statictics extends React.Component<any> {
  public render() {
    return (
      <>
        <GetAllFiles />

        <Card width="50%" padding={10} marginTop={16} elevation={4} background={colors.white}>
          <GetBankDetails />
        </Card>
      </>
    )
  }
}

export default WithAuthorization(Statictics)
