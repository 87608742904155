import * as React from 'react'
import styled from 'styled-components'
import { FilePond } from 'react-filepond'
const { Button } = require('evergreen-ui')
import { navigate } from '@reach/router'
class EditExistingBorder extends React.Component {
  private handleOnCLick = () => {
  }

  public render() {
    return (
      <React.Fragment>
        <CardHeader>
          <FilePond
            maxFiles={1}
            labelIdle="Upload documents for parsing"
            allowMultiple={true}
            onupdatefiles={(fileItems: any) => {
              this.setState({
                uploadPdfFile: fileItems.map((fileItem: any) => fileItem.file),
              })
            }}
          />
          <FilePond
            maxFiles={1}
            labelIdle="Upload corresponding configuartion file"
            allowMultiple={true}
            onupdatefiles={(fileItems: any) => {
              this.setState({
                uploadPdfFile: fileItems.map((fileItem: any) => fileItem.file),
              })
            }}
          />
          <Button marginTop={5} appearance="primary" onClick={this.handleOnCLick}>
            Edit Borders Parameters
          </Button>
        </CardHeader>
      </React.Fragment>
    )
  }
}
const CardHeader = styled.section`
  width: 50%;
  justify-content: space-between;
  margin: 80px auto;
  .filepond--root,
  .filepond--root .filepond--drop-label {
    cursor: pointer;
    height: 100px;
  }
`
export default EditExistingBorder
