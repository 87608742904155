import { getApi } from '../utils/api/Api'
import { FilePath } from './api-constants'
import { getAuthorizationHeaders } from './auth-api'
const axios = require('axios')
type fileType = 'input_pdf'| 'input_processed_pdf' | 'output_excel' | 'output_json' | 'canopy_ul'
export const multipleDownloadFile = async (fileuuid: string, type: fileType) => {
  var bodyFormData = new FormData()
  bodyFormData.set('file_pk', fileuuid)
  bodyFormData.set('response_file', type)
  return await axios({
    method: 'post',
    url: getApi(FilePath.downloadFile),
    headers: {
      'Content-Type': 'multipart/form-data',
      ...await getAuthorizationHeaders()
    },
    data: bodyFormData,
    responseType: 'blob',
  })
    .then((res: any) => {
      return res
    })
    .catch((e: any) => {
      throw e
    })
}
axios.interceptors.response.use(
  (response: any) => {
    return response
  },
  (error: any) => {
    if (
      error.request.responseType === 'blob' &&
      error.response.data instanceof Blob &&
      error.response.data.type &&
      error.response.data.type.toLowerCase().indexOf('json') != -1
    ) {
      return new Promise((resolve, reject) => {
        let reader = new FileReader()
        reader.onload = () => {
          error.response.data = reader.result
          resolve(Promise.reject(error))
        }
        reader.onerror = () => {
          reject(error)
        }
        reader.readAsText(error.response.data)
      })
    }
    return Promise.reject(error)
  },
)
