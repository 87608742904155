import { createBrowserHistory } from 'history'
import { RouterStore, syncHistoryWithStore } from 'mobx-react-router'
import AuthStore from '../stores/AuthStore'
import Api from '../utils/api/Api'

const routingStore = new RouterStore()
const authStore = new AuthStore(routingStore)

const browserHistory = createBrowserHistory()
const history = syncHistoryWithStore(browserHistory, routingStore)

const api = new Api(authStore)

export { history, api, authStore }
