import * as React from 'react'
import styled from 'styled-components'

const { Icon } = require('evergreen-ui')

class DemoPages extends React.Component<any> {
  public state = {
    bankDetal: [],
    isSortByName: true,
    isSortByCount: true,
  }

  public sorting(sortingType: string, sortByName: string, outerKeyNAme: any, innerKeyNAme: any) {
    if (sortingType === 'asc' && sortByName === 'name') {
      this.state.bankDetal.sort((a: any, b: any) => {
        if (a[outerKeyNAme].toLowerCase() > b[outerKeyNAme].toLowerCase()) {
          return 1
        } else if (a['bankCode'].toLowerCase() < b['bankCode'].toLowerCase()) {
          return -1
        } else {
          return 0
        }
      })
      this.state.bankDetal.map((element: any) => {
        if (element['subtypeStats'].length > 0) {
          return element['subtypeStats'].sort((a: any, b: any) => {
            if (a[innerKeyNAme].toLowerCase() > b[innerKeyNAme].toLowerCase()) {
              return 1
            } else if (a[innerKeyNAme].toLowerCase() < b[innerKeyNAme].toLowerCase()) {
              return -1
            } else {
              return 0
            }
          })
        }
      })
    } else if (sortingType === 'desc' && sortByName === 'name') {
      this.state.bankDetal.sort((a: any, b: any) => {
        if (a[outerKeyNAme].toLowerCase() < b[outerKeyNAme].toLowerCase()) {
          return 1
        } else if (a[outerKeyNAme].toLowerCase() > b[outerKeyNAme].toLowerCase()) {
          return -1
        } else {
          return 0
        }
      })
      this.state.bankDetal.map((element: any) => {
        if (element['subtypeStats'].length > 0) {
          return element['subtypeStats'].sort((a: any, b: any) => {
            if (a[innerKeyNAme].toLowerCase() < b[innerKeyNAme].toLowerCase()) {
              return 1
            } else if (a[innerKeyNAme].toLowerCase() > b[innerKeyNAme].toLowerCase()) {
              return -1
            } else {
              return 0
            }
          })
        }
      })
    } else if (sortingType === 'asc' && sortByName === 'count') {
      this.state.bankDetal.sort((a: any, b: any) => {
        if (a[outerKeyNAme] > b[outerKeyNAme]) {
          return 1
        } else if (a['count'] < b['count']) {
          return -1
        } else {
          return 0
        }
      })
      this.state.bankDetal.map((element: any) => {
        if (element['subtypeStats'].length > 0) {
          return element['subtypeStats'].sort((a: any, b: any) => {
            if (a[innerKeyNAme] > b[innerKeyNAme]) {
              return 1
            } else if (a[innerKeyNAme] < b[innerKeyNAme]) {
              return -1
            } else {
              return 0
            }
          })
        }
      })
    } else if (sortingType === 'desc' && sortByName === 'count') {
      this.state.bankDetal.sort((a: any, b: any) => {
        if (a[outerKeyNAme] < b[outerKeyNAme]) {
          return 1
        } else if (a[outerKeyNAme] > b[outerKeyNAme]) {
          return -1
        } else {
          return 0
        }
      })
      this.state.bankDetal.map((element: any) => {
        if (element['subtypeStats'].length > 0) {
          return element['subtypeStats'].sort((a: any, b: any) => {
            if (a[innerKeyNAme] < b[innerKeyNAme]) {
              return 1
            } else if (a[innerKeyNAme] > b[innerKeyNAme]) {
              return -1
            } else {
              return 0
            }
          })
        }
      })
    }
    this.setState({
      bankDetal: this.state.bankDetal,
    })
  }

  public sortByName = () => {
    this.setState({
      isSortByName: !this.state.isSortByName,
    })
    this.state.isSortByName
      ? this.sorting('desc', 'name', 'bankCode', 'subtype')
      : this.sorting('asc', 'name', 'bankCode', 'subtype')
  }

  public sortByCount = () => {
    this.setState({
      isSortByCount: !this.state.isSortByCount,
    })
    this.state.isSortByCount
      ? this.sorting('desc', 'count', 'count', 'count')
      : this.sorting('asc', 'count', 'count', 'count')
  }
  public displayPopOver = () => {
    return (
      <>
        <section className="NameContainer">
          <span>
            Name
            <Icon icon="arrows-vertical" size={12} marginLeft={8} onClick={this.sortByName} />
          </span>
          <span style={{ textAlign: 'right' }}>
            Count
            <Icon icon="arrows-vertical" size={12} marginLeft={8} onClick={this.sortByCount} />
          </span>
        </section>
      </>
    )
  }

  componentDidMount() {
    this.setState({
      bankDetal: this.props.bankDetails,
    })
  }

  public render() {
    return (
      <Container>
        {this.displayPopOver()}
        {this.state.bankDetal.map((bank: any) => {
          const { bankCode, count, subtypeStats } = bank
          return (
            <div>
              <section className="bankContainer">
                <span>{bankCode}</span>
                <span style={{ textAlign: 'right' }}>{count}</span>
              </section>
              {subtypeStats.map((subTypes: any) => {
                const { subtype, count } = subTypes
                return (
                  <section className="subtypeContainer">
                    <span>{subtype}</span>
                    <span style={{ textAlign: 'right' }}>{count}</span>
                  </section>
                )
              })}
            </div>
          )
        })}
      </Container>
    )
  }
}

export default DemoPages

const Container = styled.div`
    .bankContainer {
    padding: 15px;
    font-weight: bold;
    display: flex;
    font-size: 18px;
    span{
    width: 50%;
    padding-right: 10px
    }
    }
    .subtypeContainer{
    padding: 15px;
    display: flex;
    background-color: #F8F8F8;
    span{
    width: 50%;
    padding-right: 10px
    }
    }
    .NameContainer {
        padding: 15px;
        font-weight: bold;
        display: flex;
        span{
        width: 50%;
        padding-right: 10px
        cursor: pointer;
        }
        }

    `
