import * as React from 'react'
import { RouteComponentProps } from '@reach/router'
import styled from 'styled-components'
const { Button,Select,Tooltip } = require('evergreen-ui')
import { Link } from '@reach/router'
import { FilesStatus, BankStatus } from '../../schemaTypes'
import MainFilter, { FilterFields} from '../../components/Filters/MainFilter'
import { AllFilterEnum } from '../../components/FileStatus/file-status.utils'
import AllFiles from '../../components/AllFiles/AllFiles'
import WithAuthorization from '../../components/WithAuthorization/WithAuthorization'
import UploadNewFile from '../../components/UploadNewFile/UploadNewFile'
import BulkOperation from '../../components/BulkOperation/BulkOperation'

interface IState {
  filterFileStatus: FilesStatus | undefined
  filterBankStatus: BankStatus | undefined
  bulkDownloadData: any[]
  checked: boolean
  isShowPdf: boolean
  isPdf: boolean
  isExcel: boolean
  filterValue: FilterFields| undefined
  pageItemCount: number
}

class FilesPage extends React.Component<RouteComponentProps, any> {
  public state = {
    filterFileStatus: undefined,
    filterBankStatus: undefined,
    downloadType: undefined,
    bulkDownloadData: [],
    checked: true,
    isShowPdf: true,
    isPdf: true,
    isExcel: true,
    filterValue: new FilterFields(),
    pageItemCount:25
  }

  private filter = (filterValue: FilterFields) => {
    let filterFileStatus = undefined
    let filterBankStatus = undefined
    if (
      filterValue.jobStatus  === 'BS_SUB' ||
      filterValue.jobStatus  === 'BS_RUN' ||
      filterValue.jobStatus  === 'BS_NR' ||
      filterValue.jobStatus  === 'BS_PEN' ||
      filterValue.jobStatus  === 'BS_BNK'
    ) {
      filterBankStatus = filterValue.jobStatus
    } else if (filterValue.jobStatus !== AllFilterEnum.ALL) {
      filterFileStatus = filterValue.jobStatus
    }
    this.setState({
      filterFileStatus: filterFileStatus,
      filterBankStatus: filterBankStatus,
      filterValue: filterValue,
    })
  }

  private onChangePageItemCount = (event: any) => {
    const selected = event.target.value;
    this.setState({ pageItemCount: selected })
  }

  public render() {
    return (
      <main>
        <div>
          <CardHeader>
            <section>
              
              
              <Tooltip content="Job Count/Page">
              <Select width={60}  flex={false}
                onChange={this.onChangePageItemCount} 
                defaultValue={this.state.pageItemCount}>
                  <option value={10}>10</option>
                  <option value={25}>25</option>
                  <option value={50}>50</option>
                  <option value={100}>100</option>
              </Select>
              </Tooltip>
              <span className="space"></span>
              <MainFilter onFilterChange={this.filter} displayInline={true} />
            </section>

            <BulkOperation />
            <UploadNewFile />
            <Link to={`/files/multipleupload`} style={{ textDecoration: 'none' }}>
              <Button appearance="primary" style={{ marginRight: 10 }}>
                Multiple Upload
              </Button>
            </Link>
            
          </CardHeader>
          <AllFiles
            fileStatus={this.state.filterFileStatus}
            bankStatus={this.state.filterBankStatus}
            bankCode={this.state.filterValue.bankCode}
            clientId={this.state.filterValue.clientId}
            fileContains={this.state.filterValue.fileName}
            subtype={this.state.filterValue.subType}
            taskId={this.state.filterValue.stashboardTaskId}
            stashboardJobId={this.state.filterValue.stashboardJobId}
            parentUserId={this.state.filterValue.parentId}
            updatedAtGte={this.state.filterValue.updatedAtGte}
            updatedAtLte={this.state.filterValue.updatedAtLte}
            pageItemCount={this.state.pageItemCount}
          />
        </div>
      </main>
    )
  }
}

const CardHeader = styled.section`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 24px;
  .space{
  margin: 4px;
  }
`

export default WithAuthorization(FilesPage)
