import { Link } from '@reach/router'
import * as React from 'react'
import styled from 'styled-components'
import PdfAnonymiser from '../PdfAnonymiser/PdfAnonymiser'
import OcrConverter from '../OcrConverter/OcrConverter'
import TkHeaders from '../tkheader/uploadfile'
const { Popover, Menu, Heading } = require('evergreen-ui')

const MenuList = () => {
  const [shouldToolPopoverCloseOnExternalClick, setShouldToolPopoverCloseOnExternalClick] = React.useState(true)
  return (<Container>
    <section className="menu">
      <Link to={'/'} style={{ textDecoration: 'none', color: 'white' }}>
        <Heading is={'h1'} size={600} color={'#116AB8'}>
          Dashboard
        </Heading>
      </Link>
    </section>
    <section className="menu">
      <Link to={'/statistics/'} style={{ textDecoration: 'none', color: 'white' }}>
        <Heading is={'h1'} size={600} color={'#116AB8'}>
          Statistics
        </Heading>
      </Link>
    </section>
    <section className="menu">
      <TkHeaders />
    </section>
    <section className="menu">
      <Link to={'/newconfig/'} style={{ textDecoration: 'none', color: 'white' }}>
        <Heading is={'h1'} size={600} color={'#116AB8'}>
          New Config
        </Heading>
      </Link>
    </section>
    <section className="menu">
      <Popover
        position={'BOTTOM_LEFT'}
        shouldCloseOnExternalClick={shouldToolPopoverCloseOnExternalClick}
        content={
          <Menu>
            <Menu.Group>
              <Menu.Item>
                <Link to={'/canopySimulator/'} style={{ textDecoration: 'none', color: 'white' }}>
                  <Heading is={'h1'} size={600} color={'#116AB8'}>
                    Canopy UL 2.0 Simulator
                  </Heading>
                </Link>
              </Menu.Item>
              <Menu.Item>
                <PdfAnonymiser setParentCloseOnExternalClick={setShouldToolPopoverCloseOnExternalClick} />
              </Menu.Item>
              <Menu.Item>
                <OcrConverter  setParentCloseOnExternalClick={setShouldToolPopoverCloseOnExternalClick} />
              </Menu.Item>
              <Menu.Item>
              <Link to={'/bankconfigs/'} style={{ textDecoration: 'none', color: 'white' }}>
                  <Heading is={'h1'} size={600} color={'#116AB8'}>
                    Bank Configs
                  </Heading>
                </Link>
              </Menu.Item>
            </Menu.Group>
          </Menu>
        }
      >
        <Heading is={'h1'} size={600} color={'#116AB8'} id="hdTools">
          Tools
        </Heading>
      </Popover>
    </section>
  </Container>)
}
export default MenuList
const Container = styled.div`
   {
    display: flex;
    width: 90%;
    justify-content: center;
    .menu {
      margin-right: 4%;
      cursor: pointer;
    }
    .menu::after {
      content: '';
      display: block;
      width: 0;
      height: 2px;
      background: #4caf50;
      transition: width 0.3s;
    }
    .menu:hover::after {
      width: 100%;
      //transition: width .3s;
    }
  }
`
