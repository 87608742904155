import * as React from 'react'
import styled from 'styled-components'
import { FilePond } from 'react-filepond'
const { Button, TextInput, toaster } = require('evergreen-ui')
import { navigate } from '@reach/router'
import ParserParameter from '../ParserParameter/ParserParameter'
import { OnSuccessAction } from '../ParserParameter/OnSuccessAction'
import { OnCreateBorder } from './onCreateBorder';
import { tkheadersUploadFile } from '../../api/tkheaders-pdf-to-image'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
class CreateNewBoder extends React.Component<any, any> {
  state = {
    page: null,
    input_pdf: [],
    isLoader: false,
  }

  private initialState = () => {
    this.setState({
      page: null,
      input_pdf: [],
      isLoader: false,
    })
  }

  private uploadFile = async () => {
    localStorage.removeItem('draw-app')
    const { page, input_pdf } = this.state
    var params: any[] = []
    var result: any = {}
    this.setState({
      isLoader: true,
    })
    const selectedPage = page ? (+page) : 1; // +page is same as Number(page), used for converting string to number
    if (input_pdf.length > 0 && selectedPage > 0) {
      params = this.props.params
      var input_file = input_pdf[0]
      for (var i = 0; i < params.length; i++) {
        if (params[i].type === 'str') {
          result[params[i].key] = params[i].value.trim()
        } else if (params[i].type === 'int' || params[i].type === 'float') {
          const temp = +params[i].value
          result[params[i].key] = temp
        } else {
          result[params[i].key] = params[i].value
        }
      }
      tkheadersUploadFile(page, input_file, result)
        .then((result: any) => {
          if (result.length > 0) {
            this.props.OnSuccessAction('')
            this.props.OnCreateBorder(result)
            var borderData = this.props.borderData
            if (borderData && borderData.page) {
              var pages = borderData.page;
              if (selectedPage > 1) {
                // selectedPage > 1 means user provided  page number greater than 1, then selected page need to set into this.props.borderData.page (used in NewTableConfig.tsx)
                // selectedPage = 1 means either user provided page number 1 or did not provide page number
                // this.props.borderData.page default will be array of all page numbers present in PDF if user does not provide specific page ,e.g. [1,2,3] for PDF with 3 pages
                // this.props.borderData.page default will be [1] if user has provided a page number
                borderData.page = [selectedPage]
              }
            }
            navigate(`/newtableconfig/`, {
              state: {
                height: result[0]['height'],
                image: result[0]['image'],
                width: result[0]['width'],
                fileName: input_pdf,
                page: selectedPage
              },
            })
          } else {
            toaster.danger('Inavalid page number')
            this.setState({
              isLoader: false,
            })
          }
        })
        .catch((e: any) => {
          try {
            const errorResponse =
              e.response && e.response.data
                ? JSON.parse(e.response.data)
                : null
            const error = errorResponse && errorResponse.error ? errorResponse.error : 'Something went wrong'
            const status = e.response && e.response.status ? e.response.status : 0
            this.setState({
              isLoader: false,
            })
            toaster.danger(error)
            if (status === 401) {
              toaster.danger(error)
              navigate('/login')
            }
          } catch (e) {
            toaster.danger("Something went wrong")
          }

        })
    } else {
      this.setState({
        isLoader: false,
      })
      if (input_pdf.length == 0) toaster.warning('Input PDF can not be empty')
      if ((!selectedPage) || selectedPage < 1) toaster.warning('Page number should be valid number greater than zero or empty')
    }
  }

  public render() {
    return (
      <React.Fragment>
        <CardHeader>
          <FilePond
            maxFiles={1}
            labelIdle="Upload documents to begin drawing border. File extension
                          excepted: .pdf, .jpg"
            allowMultiple={true}
            onupdatefiles={(fileItems: any) => {
              this.setState({
                input_pdf: fileItems.map((fileItem: any) => fileItem.file),
              })
            }}
          />
          <section className="flex-container">
            <span>Page </span>
            <TextInput
              required
              onChange={(e: any) => this.setState({ page: e.target.value.trim() })}
              name="page"
              placeholder="Page number"
              width={285}
            />
          </section>
          <section style={{ marginBottom: '2rem' }}>
            <ParserParameter />
          </section>
          <Button
            marginTop={5}
            appearance="primary"
            isLoading={this.state.isLoader}
            onClick={this.uploadFile}
          >
            Create Borders
          </Button>
        </CardHeader>
      </React.Fragment>
    )
  }
}
const CardHeader = styled.section`
  width: 50%;
  justify-content: space-between;
  margin: 80px auto;
  .filepond--root,
  .filepond--root .filepond--drop-label {
    cursor: pointer;
    height: 100px;
  }
  .flex-container {
    display: flex;
    width: 100%;
    padding: 10px 20px;
    span {
      width: 27%;
    }
  }
`
const mapStateToProps = (storeData: any) => {
  return {
    params: storeData.onChangeReducer.params,
    borderData: storeData.createNewBorder
  }
}

const mapDispatch = (dispatch: any) => {
  return bindActionCreators(
    {
      OnSuccessAction: OnSuccessAction,
      OnCreateBorder: OnCreateBorder
    },
    dispatch)
}

export default connect(mapStateToProps, mapDispatch)(CreateNewBoder)
