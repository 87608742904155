import { GetFileDetail_files_historySet_edges } from '../../schemaTypes'
const FILE_HISTORY_LIST_LIMIT_NONE = -1
export const getOrderedHistory = (
  histories: GetFileDetail_files_historySet_edges[],
  limit: number = FILE_HISTORY_LIST_LIMIT_NONE,
) => {
  const orderedHistories = histories.sort((a, b) => {
    function getUnixTimeStamp(time: string) {
      return Math.round(new Date(time).getTime() / 1000)
    }
    if (!b.node || !a.node) {
      return 0
    }
    return getUnixTimeStamp(b.node.createdAt) - getUnixTimeStamp(a.node.createdAt)
  })
  if (limit !== FILE_HISTORY_LIST_LIMIT_NONE) {
    return orderedHistories.slice(0, limit)
  }
  return orderedHistories
}
