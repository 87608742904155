import * as React from 'react'
import styled from 'styled-components'
import { extractFileDataFromS3Key } from '../../utils/s3'
import {cleanInputFile} from '../../utils/FileUtils'
class TaskItem extends React.Component<any> {
  private displayFileName = () => {
    const { inputFile, originalFile } = this.props.item
    if (inputFile !== null) {
      return <span>{cleanInputFile(inputFile)}</span>
    } else {
      const { filename } = extractFileDataFromS3Key(originalFile)
      const file = filename.split(/[/]+/).pop()
      return <span>{file}</span>
    }
  }
  public render() {
    return (
      <Container>
        <div className="fileName">{this.displayFileName()}</div>
        <div>Not started</div>
      </Container>
    )
  }
}

const Container = styled.div`
  display: flex;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  padding: 20px;
  background-color: white;
  margin-bottom: 10px;
  .fileName {
    width: 50%;
  }
`

export default TaskItem
