export const INITAL_STATE = {
  params: '',
}
export const onChangeReducer = (state = INITAL_STATE, action: any) => {
  switch (action.type) {
    case 'onChange':
      const obj = Object.assign({}, state, {
        params: action.paramParameters,
      })
      return obj
    case 'onSuccess':
      const successobj = Object.assign({}, state, {
        params: action.paramParameters,
      })
      return successobj
    default:
      return state
  }
}
