import * as React from 'react'
import styled from 'styled-components'
import { cleanInputFile, FILE_NOT_AVAILABLE } from '../../utils/FileUtils'
import { getTextColor } from '../../utils/colors'
import { FilesStatus } from '../../schemaTypes'
class TaskItem extends React.Component<any> {
    public displayFileName(item:any)  {
        const { outputExcel, parsingStatus } = item
        let isAvailable = false
        let displayValue = FILE_NOT_AVAILABLE
        if (parsingStatus == FilesStatus.EX_SUC) {
            displayValue = cleanInputFile(outputExcel)
            isAvailable = true
        }
        return <span style={{ color: getTextColor(!isAvailable) }} >{displayValue}</span>
    }
    public render() {
        return (
            <Container>
                <div className="fileName">{this.displayFileName(this.props.item)}</div>
                <div>Not started</div>
            </Container>
        )
    }
}



const Container = styled.div`
  display: flex;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  padding: 20px;
  background-color: white;
  margin-bottom: 10px;
  .fileName {
    width: 50%;
  }
`

export default TaskItem
