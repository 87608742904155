import * as React from 'react'
import { navigate } from '@reach/router'
const {
  Button,
  withTheme,
  SideSheet,
  Heading,
  Switch,
  toaster,
  Tooltip,
  Icon,
  TextInput,
  SelectMenu,
} = require('evergreen-ui')
import styled from 'styled-components'
import { downloadAnonymisePdf, checkAnonymisePdfStatus } from '../../api/anonymise-pdf'
import { PdfAnonymizer } from '../../schemaTypes'
import { colors } from '../../utils/colors'
import { isAuthenticated } from '../../api/auth-api'


const OcrLanguage = [
  'Arabic',
  'Bulgarian',
  'ChinesePRC',
  'ChineseTaiwan',
  'Czech',
  'Danish',
  'Dutch',
  'English',
  'French',
  'German',
  'Indonesian',
  'Italian',
  'Japanese',
  'Korean',
  'KoreanHangul',
  'Malay',
  'Russian',
  'Spanish',
  'Vietnamese',
]
class PdfAnonymiser extends React.Component<any, any> {
  public state = {
    isShown: false,
    input_file: undefined,
    isLoader: false,
    remove_images: false,
    use_ocr: false,
    case_sensitive: false,
    use_regex: false,
    selectedLanguage: ['English'],
    findReplaceUI: [],
    textToRemove: [],
    textToReplace: [],
    pages: '',
    status: '',
    error: '',
  }

  private onButtonClick = () => {
    if (!isAuthenticated()) {
      navigate('/login')
    }else{
      this.setState({ isShown: true })
    }
    this.props.setParentCloseOnExternalClick(false)
  }

  private onCloseSheet = () => {
    this.setState({ isShown: false })  
    this.resetParent()
  }

  private resetParent= () => {
    this.props.setParentCloseOnExternalClick(true)
    var hdTools = document.getElementById("hdTools")
    if(hdTools) hdTools.click() // to hide Tool Popover
  }

  private handleSwitch = (e: any, key: string) => {
    this.setState({ [key]: e.target.checked })
  }

  private handlePageNumber = (e: any) => {
    this.setState({
      pages: e.target.value.trim(),
    })
  }

  private handleFindInput = (e: any, key: number) => {
    const newState = this.state.textToRemove.map((obj: any) =>
      obj.id === key ? { ...obj, text_to_remove: e.target.value } : obj,
    )

    this.setState({
      textToRemove: newState,
    })
  }

  private handleReplaceInput = (e: any, key: number) => {
    const newState = this.state.textToReplace.map((obj: any) =>
      obj.id === key ? { ...obj, replace_with: e.target.value } : obj,
    )

    this.setState({
      textToReplace: newState,
    })
  }

  private removeFindReplace = (uniqueKey: any) => {
    const replaceUiData = this.state.findReplaceUI.filter((item: any) => {
      return item.key != uniqueKey
    })
    const findData = this.state.textToRemove.filter((item: any) => {
      return item.id != uniqueKey
    })
    const replaceData = this.state.textToReplace.filter((item: any) => {
      return item.id != uniqueKey
    })
    this.setState({
      findReplaceUI: replaceUiData,
      textToRemove: findData,
      textToReplace: replaceData,
    })
  }

  private addFindReplce = () => {
    const uniqueKey = Math.random()
    const tempFind = {
      id: uniqueKey,
      text_to_remove: '',
    }
    const tempReplace = {
      id: uniqueKey,
      replace_with: '',
    }
    const temp = (
      <FindReplace key={uniqueKey}>
        <TextInput
          name="text-input-name"
          onChange={(e: any) => this.handleFindInput(e, uniqueKey)}
          placeholder="Find..."
        />
        <div className="icon">
          <Icon icon="arrows-horizontal" color="muted" />
        </div>
        <TextInput
          name="text-input-name"
          onChange={(e: any) => this.handleReplaceInput(e, uniqueKey)}
          placeholder="Replace..."
        />
        <div className="icon" style={{ cursor: 'pointer' }}>
          <Icon icon="cross" color="danger" onClick={() => this.removeFindReplace(uniqueKey)} />
        </div>
      </FindReplace>
    )
    this.setState({
      findReplaceUI: [...this.state.findReplaceUI, temp],
      textToRemove: [...this.state.textToRemove, tempFind],
      textToReplace: [...this.state.textToReplace, tempReplace],
    })
  }

  private formatFindReplaceData = () => {
    const text_to_remove = this.state.textToRemove.map((obj: any) => {
      return obj.text_to_remove
    })
    const replace_with = this.state.textToReplace.map((obj: any) => {
      return obj.replace_with
    })
    return {
      text_to_remove,
      replace_with,
    }
  }

  private downloadURI(uri: any, name: any) {
    var link: any = document.createElement('a')
    link.download = name
    link.href = uri
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  private checkDownloadStatus = (requestId: string) => {
    const timer = setInterval(() => {
      if (
        this.state.status == PdfAnonymizer.PENDING ||
        this.state.status == PdfAnonymizer.RUNNING
      ) {
        checkAnonymisePdfStatus(requestId)
          .then((res: any) => {
            this.setState({
              status: res.status,
            })
            if (
              res.status == PdfAnonymizer.ABORTED ||
              res.status == PdfAnonymizer.FAILED ||
              res.status == PdfAnonymizer.TIMED_OUT
            ) {
              clearInterval(timer)
              this.setState({
                error: res.error,
              })
            } else if (res.status == PdfAnonymizer.SUCCEEDED) {
              clearInterval(timer)
              this.downloadURI(res.data.redacted_file, 'PdfAnonymizer')
              this.resetParent()
            }
          })
          .catch(() => {
            clearInterval(timer)
            this.setState({
              error: 'Something went wrong',
              status: PdfAnonymizer.INVALID,
            })
          })
      }
    }, 5000)

    return () => {
      // clearInterval(timer)
    }
  }

  private uploadFile = () => {
    const { text_to_remove, replace_with } = this.formatFindReplaceData()
    const regex = /[0-9,-]+/
    let isPage = true
    const {
      input_file,
      remove_images,
      use_ocr,
      case_sensitive,
      use_regex,
      selectedLanguage,
      pages,
    } = this.state
    if (this.state.pages) {
      if (!regex.test(this.state.pages)) {
        isPage = false
      }
    }
    if (input_file && text_to_remove.length > 0 && isPage) {
      this.setState({ isLoader: true })

      downloadAnonymisePdf(
        input_file,
        text_to_remove,
        replace_with,
        remove_images,
        use_ocr,
        case_sensitive,
        use_regex,
        selectedLanguage,
        pages,
      )
        .then((res: any) => {
          this.setState({
            input_file: undefined,
            isLoader: false,
            findReplaceUI: [],
            textToRemove: [],
            textToReplace: [],
            remove_images: false,
            use_ocr: false,
            case_sensitive: false,
            use_regex: false,
            selectedLanguage: ['English'],
            pages: '',
            status: res.status,
          })
          this.checkDownloadStatus(res.request_id)
        })
        .catch((e: any) => {
          this.setState({ isLoader: false })
          const error =
            e.response && e.response.data ? JSON.parse(e.response.data) : 'Unable to download file'
          toaster.danger(error['error'])
        })
    } else {
      !input_file ? toaster.danger('Input file is empty') : null
      text_to_remove.length == 0
        ? toaster.danger('Please input at least one entry to remove/replace')
        : null
      this.state.pages && !regex.test(this.state.pages)
        ? toaster.danger('Enter page number number in proper format ')
        : null
    }
  }

  private onChangeLanguage = (Item: any) => {
    const selected = [...this.state.selectedLanguage, Item.value]
    this.setState({
      selectedLanguage: selected,
    })
  }

  private OnChangeLAnguageDeselect = (Item: any) => {
    const selected: any[] = [...this.state.selectedLanguage]
    const deselectedItemIndex = selected.indexOf(Item.value)
    selected.splice(deselectedItemIndex, 1)
    this.setState({
      selectedLanguage: selected,
    })
  }

  private displayStatus = () => {
    if (
      this.state.status === PdfAnonymizer.RUNNING ||
      this.state.status === PdfAnonymizer.PENDING
    ) {
      return (
        <>
          <h1 style={{ textAlign: 'center' }}>PDF Anonymizer</h1>
          <StyledAnalysisContainer>
            <div className="sk-folding-cube">
              <div className="sk-cube1 sk-cube" />
              <div className="sk-cube2 sk-cube" />
              <div className="sk-cube4 sk-cube" />
              <div className="sk-cube3 sk-cube" />
            </div>
          </StyledAnalysisContainer>
          <StyledInfo>
            <b>Status:</b> {this.state.status}
          </StyledInfo>
        </>
      )
    } else if (
      this.state.status == PdfAnonymizer.ABORTED ||
      this.state.status == PdfAnonymizer.FAILED ||
      this.state.status == PdfAnonymizer.INVALID ||
      this.state.status == PdfAnonymizer.TIMED_OUT
    ) {
      return (
        <>
          <Icon icon="cross" color="danger" size={98} marginTop={56} marginLeft={156} />
          <StyledAlert> {this.state.error}</StyledAlert>
        </>
      )
    }
  }

  private displaySlideSheet = () => {
    const { isShown } = this.state
    return (
      <SideSheet width={450} isShown={isShown} onCloseComplete={this.onCloseSheet}>
        {!this.state.status ? this.displayUploadSection() : this.displayStatus()}
      </SideSheet>
    )
  }
  private displayDownloadProgress = () => {}
  private extractNameFromFile = (file: any | undefined, type: 'pdf' | 'config') => {
    if (!file) {
      if (type === 'pdf') {
        return 'Choose a PDF'
      }
      if (type === 'config') {
        return 'Choose  config'
      } else {
        return 'error'
      }
    }
    let name = file.name
    const MAX_LABEL_WORD_COUNT = 20
    if (name.length > MAX_LABEL_WORD_COUNT) {
      const startCount = MAX_LABEL_WORD_COUNT / 3
      const endCount = (MAX_LABEL_WORD_COUNT * 2) / 3
      name = name.substring(0, startCount) + '…' + name.substring(name.length - endCount)
    }
    return name
  }
  private displayUploadSection = () => {
    const regex = /[0-9,-]+/
    const { input_file } = this.state
    const pdfLabel = this.extractNameFromFile(input_file, 'pdf')
    const options = OcrLanguage.map(label => ({ label, value: label }))
    return (
      <>
        <Container>
          <section className="flex-container">
            <span>PDF*</span>
            <input
              className="inputfile"
              type="file"
              id="pdf"
              accept=".pdf"
              onChange={(e: any) => {
                this.setState({ input_file: e.target.files[0] })
              }}
            />
            <label
              className={this.props.theme.getButtonClassName()}
              htmlFor="pdf"
              style={{ overflow: 'hidden' }}
            >
              {pdfLabel}
            </label>
          </section>
          <section className="flex-container">
            <span>
              Languages
              <Tooltip content=" Enter ocr language to select from list.">
                <Icon
                  icon="info-sign"
                  size={10}
                  marginLeft={5}
                  color="muted"
                  style={{ cursor: 'pointer' }}
                />
              </Tooltip>
            </span>
            <SelectMenu
              isMultiSelect
              hasFilter={false}
              title="Select multiple languages"
              options={options}
              selected={this.state.selectedLanguage}
              onSelect={this.onChangeLanguage}
              onDeselect={this.OnChangeLAnguageDeselect}
            >
              <Button style={{ width: '50%' }}> Select Languages</Button>
            </SelectMenu>
          </section>
          <section className="flex-container">
            <span>
              Remove images
              <Tooltip content="Remove all image from PDF">
                <Icon
                  icon="info-sign"
                  size={10}
                  marginLeft={5}
                  color="muted"
                  style={{ cursor: 'pointer' }}
                />
              </Tooltip>
            </span>
            <Switch onChange={(e: any) => this.handleSwitch(e, 'remove_images')} />
          </section>
          <section className="flex-container">
            <span>
              Case sensitive
              <Tooltip content="Indicates whether the target text should be treated as case sensitive">
                <Icon
                  icon="info-sign"
                  size={10}
                  marginLeft={5}
                  color="muted"
                  style={{ cursor: 'pointer' }}
                />
              </Tooltip>
            </span>
            <Switch onChange={(e: any) => this.handleSwitch(e, 'case_sensitive')} />
          </section>
          <section className="flex-container">
            <span>
              Treat as regex
              <Tooltip content=" Indicates whether the target text should be treated as a regular expression">
                <Icon
                  icon="info-sign"
                  size={10}
                  marginLeft={5}
                  color="muted"
                  style={{ cursor: 'pointer' }}
                />
              </Tooltip>
            </span>

            <Switch
              checked={this.state.use_regex}
              onChange={(e: any) => this.handleSwitch(e, 'use_regex')}
            />
          </section>
          <section className="flex-container">
            <span>
              Use OCR
              <Tooltip content=" Run OCR on the PDF">
                <Icon
                  icon="info-sign"
                  size={10}
                  marginLeft={5}
                  color="muted"
                  style={{ cursor: 'pointer' }}
                />
              </Tooltip>
            </span>
            <Switch onChange={(e: any) => this.handleSwitch(e, 'use_ocr')} />
          </section>
          <section className="flex-container">
            <span>Pages</span>
            <TextInput
              width={220}
              value={this.state.pages}
              placeholder="Enter page number"
              onChange={this.handlePageNumber}
            />
          </section>
          <section className="flex-container">
            <span></span>
            {this.state.pages && !regex.test(this.state.pages) ? (
              <div style={{ color: 'red', fontSize: 12, marginTop: -10 }}>
                Enter page number in proper format
              </div>
            ) : null}
          </section>
          <section className="flex-container">
            <Button marginRight={12} iconBefore="add" onClick={() => this.addFindReplce()}>
              Find/Replace
            </Button>
          </section>
          <section>{this.state.findReplaceUI}</section>
        </Container>

        <BottomButton
          isLoading={this.state.isLoader}
          appearance="primary"
          onClick={() => this.uploadFile()}
        >
          Upload
        </BottomButton>
      </>
    )
  }
  public render() {
    return (
      <React.Fragment>
        {this.displaySlideSheet()}
        <Heading is={'h1'} size={600} color={'#116AB8'} onClick={this.onButtonClick}>
          PDF Anonymiser
        </Heading>
      </React.Fragment>
    )
  }
}

export default withTheme(PdfAnonymiser)

const Container = styled.li`
  .note {
    margin-top: -10px;
    padding-left: 20px;
    margin-bottom: 20px;
    font-size: 14px;
    b {
      padding-right: 10px;
    }
  }
   {
    text-overflow: ellipsis;
    list-style: none;
    margin-bottom: 20px;
    padding-top: 20px;

    .flex-container {
      display: flex;
      width: 100%;
      padding: 10px 20px;
      span {
        width: 40%;
      }
    }

    section {
      width: 20%;
      margin: 10px 0px;
    }

    .upload-file-section {
      padding: 1rem;
    }
    .inputfile {
      width: 0.1px;
      height: 0.1px;
      opacity: 0;
      overflow: hidden;
      position: absolute;
      z-index: -1;
    }
    .inputfile + label {
      padding: 0.5rem;
      font-size: 13.5px;
      border-radius: 2px;
      width: 185px;
      cursor: pointer;
    }

    .inputfile + label {
      cursor: pointer;
    }
    .upload-section {
      overflow: hidden;
    }
  }
`

const StyledAlert = styled.div`
  background-color: #fff2f0;
  border: 1px solid #ffccc7;
  margin: 20px 0;
  padding: 10px;
  margin: 5px;
`
const StyledInfo = styled.div`
  background-color: #e6f7ff;
  border: 1px solid #91d5ff;
  margin: 20px 0;
  padding: 10px;
  margin: 15px;
`

const BottomButton = styled(Button)`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  border-radius: 5px 5px 0 0;
  justify-content: center;
`

const FindReplace = styled.div`
  display: flex;
  padding: 10px 20px;
  .icon {
    margin: 5px 5px 0 5px;
  }
`

const StyledAnalysisContainer = styled.div`
  .sk-folding-cube {
    margin: 100px auto 50px auto;
    width: 40px;
    height: 40px;
    position: relative;
    -webkit-transform: rotateZ(45deg);
    transform: rotateZ(45deg);
  }

  .sk-folding-cube .sk-cube {
    float: left;
    width: 50%;
    height: 50%;
    position: relative;
    -webkit-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1);
  }
  .sk-folding-cube .sk-cube:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: ${colors.blue};
    -webkit-animation: sk-foldCubeAngle 2.4s infinite linear both;
    animation: sk-foldCubeAngle 2.4s infinite linear both;
    -webkit-transform-origin: 100% 100%;
    -ms-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
  }
  .sk-folding-cube .sk-cube2 {
    -webkit-transform: scale(1.1) rotateZ(90deg);
    transform: scale(1.1) rotateZ(90deg);
  }
  .sk-folding-cube .sk-cube3 {
    -webkit-transform: scale(1.1) rotateZ(180deg);
    transform: scale(1.1) rotateZ(180deg);
  }
  .sk-folding-cube .sk-cube4 {
    -webkit-transform: scale(1.1) rotateZ(270deg);
    transform: scale(1.1) rotateZ(270deg);
  }
  .sk-folding-cube .sk-cube2:before {
    -webkit-animation-delay: 0.3s;
    animation-delay: 0.3s;
  }
  .sk-folding-cube .sk-cube3:before {
    -webkit-animation-delay: 0.6s;
    animation-delay: 0.6s;
  }
  .sk-folding-cube .sk-cube4:before {
    -webkit-animation-delay: 0.9s;
    animation-delay: 0.9s;
  }
  @-webkit-keyframes sk-foldCubeAngle {
    0%,
    10% {
      -webkit-transform: perspective(140px) rotateX(-180deg);
      transform: perspective(140px) rotateX(-180deg);
      opacity: 0;
    }
    25%,
    75% {
      -webkit-transform: perspective(140px) rotateX(0deg);
      transform: perspective(140px) rotateX(0deg);
      opacity: 1;
    }
    90%,
    100% {
      -webkit-transform: perspective(140px) rotateY(180deg);
      transform: perspective(140px) rotateY(180deg);
      opacity: 0;
    }
  }

  @keyframes sk-foldCubeAngle {
    0%,
    10% {
      -webkit-transform: perspective(140px) rotateX(-180deg);
      transform: perspective(140px) rotateX(-180deg);
      opacity: 0;
    }
    25%,
    75% {
      -webkit-transform: perspective(140px) rotateX(0deg);
      transform: perspective(140px) rotateX(0deg);
      opacity: 1;
    }
    90%,
    100% {
      -webkit-transform: perspective(140px) rotateY(180deg);
      transform: perspective(140px) rotateY(180deg);
      opacity: 0;
    }
  }
`