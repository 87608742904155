import AmazonOutlined from '@ant-design/icons/AmazonOutlined'
import FacebookOutlined from '@ant-design/icons/FacebookOutlined'
import GoogleOutlined from '@ant-design/icons/GoogleOutlined'
import LoginOutlined from '@ant-design/icons/LoginOutlined'
import { useLocation } from '@reach/router'
import { Auth } from 'aws-amplify'
import React, { useState } from 'react'
import styled from 'styled-components'
import { authStore } from '../../contexts'
import { useQuery } from '../../hooks/use-query'

const { Button } = require('evergreen-ui')

function SocialLogin(props: any) {
  const Container = styled.div`
    button.login-with-google {
      background: #4285f4;
      border: none;

      &:hover {
        background: #3e75dc;
      }
    }

    button.login-with-facebook {
      background: #4267b2;
      border: none;

      &:hover {
        background: #394c8e;
      }
    }

    button.login-with-amazon {
      background: #f5c645;
      border: 1px solid #b38b22;
      color: black;

      &:hover {
        background: #d1a53d;
      }
    }

    .social-auth-buttons {
      margin-bottom: 24px;
    }
  `
  const query = useQuery()
  //const history = useHistory()
  const location = useLocation()

  // OAuth redirects have a code parameter in the URL
  const isOAuthRedirect = !!query.get('code')
  // Login page always starts in the loading state
  const [oauthLoading, setOauthLoading] = useState(true)

  const [loginLoadingState, setLoginLoadingState] = useState({
    'Google': false,
    'Facebook': false,
    'LoginWithAmazon': false,
    'ubs-brazil': false
  }
  )

  const federatedLogin = async (provider: any) => {
    setLoginLoadingState(prevState => ({
      ...prevState,
      [provider]: true
    }))
    await Auth.federatedSignIn({ provider: provider as any })
  }

  return (
    <Container>
      <div className="social-auth-buttons">
        <Button
          marginY={8}
          appearance="primary"
          width={'100%'}
          //@ts-ignore
          iconBefore={<FacebookOutlined />}
          className="login-with-facebook"
          isLoading={loginLoadingState.Facebook}
          style={{ justifyContent: 'center' }}
          onClick={() => federatedLogin('Facebook')}
        >
          Login with Facebook
        </Button>
        <Button
          marginY={8}
          width={'100%'}
          appearance="primary"
          //@ts-ignore
          iconBefore={<AmazonOutlined />}
          className="login-with-amazon"
          isLoading={loginLoadingState.LoginWithAmazon}
          style={{ justifyContent: 'center' }}
          onClick={() => federatedLogin('LoginWithAmazon')}
        >
          Login with Amazon
        </Button>
        <Button
          marginY={8}
          width={'100%'}
          appearance="primary"
          //@ts-ignore
          iconBefore={<GoogleOutlined />}
          className="login-with-google"
          isLoading={loginLoadingState.Google}
          style={{ justifyContent: 'center' }}
          onClick={() => federatedLogin('Google')}
        >
          Login with Google
        </Button>
        <Button
          marginY={8}
          width={'100%'}
          appearance="primary"
          //@ts-ignore
          iconBefore={<LoginOutlined />}
          className="login-with-ubs-brazil"
          isLoading={loginLoadingState['ubs-brazil']}
          style={{ justifyContent: 'center' }}
          onClick={() => federatedLogin('ubs-brazil')}
        >
          Login with CS Brazil Account
        </Button>
      </div>
    </Container>
  )
}

export default SocialLogin
