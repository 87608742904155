import * as request from 'superagent'
import { paramsObjectFromServerType } from '../components/RerunParser/RerunParserForm'
import { getApi } from '../utils/api/Api'
import { tkheaders } from './api-constants'
import { getAuthorizationHeaders } from './auth-api'

export const uploadFile = async (
  bank_name: string,
  page: number,
  config_input: any,
  input_file: any,
  params: paramsObjectFromServerType | undefined,
) => {
  return request
    .post(getApi(tkheaders.uploadFile))
    .set(await getAuthorizationHeaders())
    .field('bank_name', bank_name)
    .field('page', page)
    .field('params', JSON.stringify(params))
    .attach('input_pdf', input_file)
    .attach('config_input', config_input)
    .then(response => {
      const { headers, image, log } = response.body['data']
      return {
        headers,
        image,
        log,
      }
    })
    .catch(e => {
      throw e
    })
}
