import * as React from 'react'
import WithAuthorization from '../../components/WithAuthorization/WithAuthorization'
import NewTableConfig from '../../components/NewConfig/NewTableConfig'

class TkHeadersConfig extends React.Component<any, any> {

  public render() {
    return (
      <main>
        <NewTableConfig state={this.props.location.state}/>
      </main>
    )
  }
}

export default WithAuthorization(TkHeadersConfig)
