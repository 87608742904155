import * as React from 'react'
import WithAuthorization from '../../components/WithAuthorization/WithAuthorization'
import DisplayRestartCoversionTask from '../../components/BulkTaskConversionRestart/BulkTaskConversionRestart'

class BulkTaskConversionRestart extends React.Component<any> {
  public render() {
    return <DisplayRestartCoversionTask />
  }
}


export default WithAuthorization(BulkTaskConversionRestart)