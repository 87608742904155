import * as React from 'react'
const moment = require('moment')
const { Badge } = require('evergreen-ui')

interface IProps {
  statementDate: Date | null
}
const dateFomate = (statementDate: any) => {
  if (statementDate) {
    return <span>{moment(statementDate).format('MMMM YYYY ')}</span>
  } else {
    return <span style={{ textTransform: 'lowercase' }}>n/a</span>
  }
}

const StatementDate: React.FunctionComponent<IProps> = ({ statementDate }: IProps) => (
  <Badge style={{ textTransform: 'capitalize', marginLeft: '10px' }}>
    <span style={{ fontWeight: 400 }}>statement Date:</span> {dateFomate(statementDate)}
  </Badge>
)

export default StatementDate
