import * as React from 'react'
import WithAuthorization from '../../components/WithAuthorization/WithAuthorization'
const { Button, TextInput, withTheme } = require('evergreen-ui')
import CustomCard from '../../components/CustomCard/CustomCard'
import styled from 'styled-components'
import { FilePond } from 'react-filepond'
import 'filepond/dist/filepond.min.css'
import { uploadNewFile } from '../../api/upload-file-api'
import ResponseItem from './ResponseFileUpload'
import UploadParserParameter from './UploadParserParameter'
import { connect } from 'react-redux'
const { toaster } = require('evergreen-ui')
interface IState {
  bankName: string
  subtype: string
  password: string
  config: File | undefined
  loadingApi: boolean
  displayData: any[]
  isShown: boolean
  uploadPdfFile: any[]
  responseFileData: any[]
  isUpdate: boolean
  isNumberValid: boolean
}

class UploadMultipleFile extends React.Component<any, any> {
  public state = {
    bankName: '',
    subtype: '',
    password: '',
    pdf: undefined,
    config: undefined,
    loadingApi: false,
    displayData: [],
    uploadPdfFile: [],
    responseFileData: [],
    isShown: false,
    isUpdate: true,
    isNumberValid: true,
  }

  private onClickDialogBox() {
    this.setState({
      isShown: false,
    })
  }

  private uploadFile = async () => {
    var page_range = 'all'
    var params: any[] = []
    var result: any = {}
    const { config, bankName, subtype, isNumberValid, password } = this.state

    params = this.props.params

    for (var i = 0; i < params.length; i++) {
      if (params[i].type === 'str') {
        result[params[i].key] = params[i].value.trim()
      } else if (params[i].type === 'int' || params[i].type === 'float') {
        const temp = +params[i].value
        result[params[i].key] = temp
      } else {
        result[params[i].key] = params[i].value
      }
    }
    if (isNumberValid) {
      this.setState({ isUpdate: false })
      var tempData: any[] = []
      this.state.uploadPdfFile.forEach((element, index) => {
        uploadNewFile(
          bankName,
          subtype,
          password,
          element,
          result,
          config,
          page_range,
        ).then((res: any) => {
          const newInput = {
            pdf: element,
            config: config,
            bankName: bankName,
            subtype: subtype,
            params: params,
            status: res.taskId || res.fileId ? 'Success' : 'Failure',
            isSuccess: res.taskId || res.fileId ? true: false
          }

          tempData = this.state.responseFileData

          tempData.push(newInput)

          this.setState({
            responseFileData: tempData,
          })
        }).catch((e: any) => {
          const error =
            e.response && e.response.body && e.response.body.error
              ? e.response.body.error
              : 'Something went wrong'

          const newInput = {
            pdf: element,
            config: config,
            bankName: bankName,
            subtype: subtype,
            params: params,
            status: error,
            isSuccess: false
          }

          tempData = this.state.responseFileData

          tempData.push(newInput)

          this.setState({
            responseFileData: tempData,
          })
        })
      })
    } else {
      !isNumberValid ? alert('Number should be in proper format') : null
    }
  }

  private DispalyUploadButton() {
    if (this.state.uploadPdfFile && this.state.uploadPdfFile.length > 0 && this.state.isUpdate) {
      return (
        <Button
          appearance="primary"
          style={{ marginLeft: '35vw', marginTop: '6vh', padding: '0px 50px' }}
          onClick={this.uploadFile}
        >
          Upload Files
        </Button>
      )
    } else {
      return null
    }
  }

  private DispalyDataRender() {
    const { config } = this.state

    const configLabel = this.extractNameFromFile(config, 'config')
    if (this.state.isUpdate) {
      return (
        <>
          <Container>
            <section className="upload-file-section">
              <input
                className="inputfile"
                type="file"
                id="config"
                accept=".xls, .xlsx"
                onChange={(e: any) => {
                  this.setState({ config: e.target.files[0] })
                }}
              />
              <label className={this.props.theme.getButtonClassName()} htmlFor="config">
                {configLabel}
              </label>
            </section>
            <section className="upload-section">
              <TextInput
                required
                onChange={(e: any) => this.setState({ bankName: e.target.value.trim() })}
                name="bankName"
                placeholder="Bank name"
                width={200}
              />
            </section>
            <section className="upload-section">
              <TextInput
                required
                onChange={(e: any) => this.setState({ subtype: e.target.value.trim() })}
                name="subtype"
                placeholder="subtype"
                width={200}
              />
            </section>
            <section className="upload-section">
              <TextInput
                required
                onChange={(e: any) => this.setState({ password: e.target.value })}
                name="Password"
                placeholder="password"
                width={200}
              />
            </section>
            <UploadParserParameter subtype={this.state.subtype} bank={this.state.bankName} />
          </Container>
          <section style={{ width: '80%', margin: '20px 0 0 100px', cursor: 'pointer' }}>
            <FilePond
              labelIdle="Choose PDFs/Drag and Drop PDFs *"
              allowMultiple={true}
              onupdatefiles={(fileItems: any) => {
                this.setState({
                  uploadPdfFile: fileItems.map((fileItem: any) => fileItem.file),
                })
              }}
            />
          </section>
          <div style={{ color: 'grey', textAlign: 'center' }}>
            <b>Note:</b> "Multiple uploads should all be of the same Bank Format/Name and Sub Type"
          </div>
        </>
      )
    } else {
      if (this.state.responseFileData.length > 0) {
        return this.state.responseFileData.map(data => {
          return <ResponseItem item={data} />
        })
      } else {
        return <ResponseItem item={'spinner'} />
      }
    }
  }

  private extractNameFromFile = (file: any | undefined, type: 'pdf' | 'config') => {
    if (!file) {
      if (type === 'pdf') {
        return 'Choose a PDF'
      }
      if (type === 'config') {
        return 'Choose  config'
      } else {
        return 'error'
      }
    }

    let name = file.name
    const MAX_LABEL_WORD_COUNT = 20
    if (name.length > MAX_LABEL_WORD_COUNT) {
      const startCount = MAX_LABEL_WORD_COUNT / 3
      const endCount = (MAX_LABEL_WORD_COUNT * 2) / 3
      name = name.substring(0, startCount) + '…' + name.substring(name.length - endCount)
    }
    return name
  }

  public render() {
    return (
      <main>
        <CustomCard>
          {this.DispalyDataRender()}
          {this.DispalyUploadButton()}
        </CustomCard>
      </main>
    )
  }
}

const mapStateToProps = (storeData: any) => {
  return {
    params: storeData.onChangeReducer.params,
  }
}

const WrappedMultipleUpload = withTheme(UploadMultipleFile)

export default connect(mapStateToProps, null)(WrappedMultipleUpload)

const Container = styled.li`
   {
    display: flex;
    align-items: center;
    text-overflow: ellipsis;
    list-style: none;
    section {
      width: 20%;
    }

    .upload-file-section {
      padding: 1rem;
    }
    .inputfile {
      width: 0.1px;
      height: 0.1px;
      opacity: 0;
      overflow: hidden;
      position: absolute;
      z-index: -1;
    }
    .inputfile + label {
      padding: 0.5rem 2.5rem;
      font-size: 13.5px;
      border-radius: 2px;
      width: 100%;
    }

    .inputfile + label {
      cursor: pointer;
    }
    .upload-section {
      overflow: hidden;
    }
    .selectRange {
      display: flex;
      margin-left: 20px;
    }
  }
`
