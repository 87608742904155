import * as React from 'react'
import styled from 'styled-components'
import { colors } from '../../utils/colors'
import { getParserParameter } from '../../api/parser-params-api'
const { TextInputField, Heading, Switch, Text, Button } = require('evergreen-ui')
import { OnChangeAction } from './OnChangeAction'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { navigate } from '@reach/router'
class ParserParameter extends React.Component<any> {
  public state = {
    params: [],
    paramsParameter: [],
    showParamSection: false,
    lastFetchedBank: null,
    lastFetchedSubtype: null
  }

  private handleListChange(e: any, index: number) {
    let recipesCopy = JSON.parse(JSON.stringify(this.state.params))
    recipesCopy[index]['value'] = e.target.value.split(',')
    this.setState(
      {
        params: recipesCopy,
      },
      () => {
        this.props.OnChangeAction(this.state.params)
      },
    )
  }

  private handleNumberChange(e: any, index: number, isFloat: boolean) {
    const regex = isFloat ? (/^[+-]?(\d*\.)?\d*$/) : (/^([+-]?([1-9]\d*)?|0)$/)
    let recipesCopy = JSON.parse(JSON.stringify(this.state.params))
    if ((e.target.value == "") || regex.test(e.target.value)) {
      const value = e.target.value
      recipesCopy[index]['value'] = value
      this.setState(
        {
          params: recipesCopy,
        },
        () => {
          this.props.OnChangeAction(this.state.params)
        },
      )
    }
  }

  private handleStringChange(e: any, index: number) {
    let recipesCopy = JSON.parse(JSON.stringify(this.state.params))
    recipesCopy[index]['value'] = e.target.value
    this.setState(
      {
        params: recipesCopy,
      },
      () => {
        this.props.OnChangeAction(this.state.params)
      },
    )
  }

  private toggleSwitch = (e: any, index: number) => {
    let recipesCopy = JSON.parse(JSON.stringify(this.state.params))
    recipesCopy[index]['value'] = e.target.checked
    this.setState(
      {
        params: recipesCopy,
      },
      () => {
        this.props.OnChangeAction(this.state.params)
      },
    )
  }

  private getParserParameter = () => {
    const { paramsParameter, bank, subtype } = this.props
    this.setState(
      {
        showParamSection: !this.state.showParamSection,
      }
    )
    if (bank !== this.state.lastFetchedBank || subtype !== this.state.lastFetchedSubtype) {
      getParserParameter(bank, subtype)
        .then((result: any) => {
          if (paramsParameter) {
            Object.entries(JSON.parse(paramsParameter)).map(([key, value]) => {
              const index = result.findIndex((item: any) => item.key == key)
              if (index >= 0) {
                result[index]['value'] = value
              }
            })
          }
          this.props.OnChangeAction(result)
          this.setState({
            params: result,
          })
        })
        .catch((e: any) => {
          const error =
            e.response && e.response.body && e.response.body.error
              ? e.response.body.error
              : 'Unknown Error'

          const status = e.response && e.response.status ? e.response.status : e.response.statusCode
          if (status === 401) {
            alert(error)
            navigate('/login')
          } else {
            alert(error)
          }
        })
      this.setState({
        lastFetchedBank: bank,
        lastFetchedSubtype: subtype
      })
    }
  }

  private displayButton = () => {
    return (
      <ButtonContainer>
        <Button onClick={this.getParserParameter}>{(this.state.showParamSection ? 'Hide Parser Parameter' : 'Call Parser Parameter')}</Button>
      </ButtonContainer>
    )
  }

  private displayParams() {
    if (this.state.params.length > 0) {
      return (
        <Container style={{ display: (this.state.showParamSection ? 'inherit' : 'none') }} >
          <Heading is={'h1'} marginBottom="default">
            Select parser parameters
          </Heading>
          {this.state.params.map((element: any, index: any) => {
            if (element['type'] === 'bool') {
              return (
                <section className="inner-field two-col-grid">
                  <Text>{element['key']}</Text>
                  <Switch
                    checked={element['value']}
                    marginBottom={16}
                    onChange={(e: any) => this.toggleSwitch(e, index)}
                  />
                </section>
              )
            } else if (element['type'] === 'int') {
              return (
                <section className="parser-field">
                  <TextInputField
                    value={element['value']}
                    label={element['key']}
                    onChange={(e: any) => this.handleNumberChange(e, index, false)}
                  />
                </section>
              )
            } else if (element['type'] === 'float') {
              return (
                <section className="parser-field">
                  <TextInputField
                    value={element['value']}
                    label={element['key']}
                    onChange={(e: any) => this.handleNumberChange(e, index, true)}
                  />
                </section>
              )
            } else if (element['type'] === 'list') {
              return (
                <section className="parser-field">
                  <TextInputField
                    value={element['value']}
                    label={element['key']}
                    onChange={(e: any) => this.handleListChange(e, index)}
                  />
                </section>
              )
            } else {
              return (
                <section className="parser-field">
                  <TextInputField
                    value={element['value']}
                    label={element['key']}
                    onChange={(e: any) => this.handleStringChange(e, index)}
                  />
                </section>
              )
            }
          })}
        </Container>
      )
    }
    return ''
  }

  public render() {
    return <>
      {this.displayButton()}
      {this.displayParams()}
    </>
  }
}
const mapDispatch = (dispatch: any) => {
  return bindActionCreators(
    {
      OnChangeAction: OnChangeAction,
    },
    dispatch,
  )
}
export default connect(null, mapDispatch)(ParserParameter)
const legendBorderColor = `rgba(85,86,88,0.1)`
const legendColor = `rgb(85,86,88)`
const Container = styled.div`
  position: relative;
  overflow: hidden;
  font-size: 1rem;
  button{
    width:100%;
    justify-content: center;
    border-radius: 0 0 5px 5px;
  }
  .parser-field{
      padding: 0 1rem;
  }
 
  h1 {
    padding: 1rem;
    text-transform: capitalize;
    text-align: center;
    cursor: pointer;
    background: ${colors.hoverBackground};
  }
  .inner-field {
      padding:0.5rem 1rem;
      font-size: 1rem;
  }
  .two-col-grid{
    display: grid;
    grid-template-columns: 1fr auto;
    grid-gap: 0.5rem 0;
  }
  label {
    font-weight: 400;
  }
    .errorMessage{
      font-size: 10px;
      color: red;
      position: relative;
      bottom: 20px;
    }
  }
`

const ButtonContainer = styled.div`
  padding: 10px 10px 10px 30%;
  button {
    width: 60%;
    justify-content: center;
  }
`
