import * as React from 'react'
import styled from 'styled-components'
import LogoHeader from '../LogoHeader/LogoHeader'
import UserAvatar from '../UserAvatar/UserAvatar'
import { colors } from '../../utils/colors'
import Menu from '../menu/menu'

const Container = styled.div`
  .header-container {
    position: fixed;
    margin: auto auto 1rem auto;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 60px;
    z-index: 9;
    background: ${colors.darkBlack};
  }
  .menu-container {
    position: fixed;
    margin: 60px auto 1rem auto;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60px;
    background: ${colors.white};
    padding-left: 1.5rem;
    z-index: 9;
    box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.14),
      0 1px 18px 0 rgba(0, 0, 0, 0.12);
  }
  .props-container {
    position: relative;
    top: 150px;
    width: 95%;
    left: 30px;
  }
`

const WithAuthorization: any = (
  MainComponent: React.ComponentType,
  HeaderComponent: React.ComponentType = LogoHeader,
) => {
  class App extends React.Component {
    public render() {
      return (
        <Container>
          <section className="header-container">
            <HeaderComponent {...this.props} />
            <UserAvatar />
          </section>
          <section className="menu-container">
            <Menu />
          </section>
          <section className="props-container">
            <MainComponent {...this.props} />
          </section>
        </Container>
      )
    }
  }
  return App
}
export default WithAuthorization
