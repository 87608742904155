import * as React from 'react'
import styled from 'styled-components'
import { ApolloError } from 'apollo-client'

const { Text } = require('evergreen-ui')

interface IProps {
  error: ApolloError
}

const QueryError: React.FunctionComponent<IProps> = ({ error: { message } }: IProps) => (
  <Container>
    <Text size={600}>{message}</Text>
  </Container>
)

const Container = styled.div`
  width: 100%;
  text-align: center;
  padding: 2rem;
  font-style: oblique;
`

export default QueryError
