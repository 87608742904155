import { navigate } from '@reach/router'
import * as React from 'react'
import { FilePond } from 'react-filepond'
import styled from 'styled-components'
import { canopySimulator, downloadFile } from '../../api/canopy-simulator'
import CustomCard from '../../components/CustomCard/CustomCard'
const { withTheme, Button, TextInput, Paragraph } = require('evergreen-ui')

export const extractNameFromFile = (file: any | undefined, type: 'config') => {
  if (!file) {
    if (type === 'config') {
      return 'Choose  EXCEL'
    } else {
      return 'error'
    }
  }

  let name = file.name
  const MAX_LABEL_WORD_COUNT = 20
  if (name.length > MAX_LABEL_WORD_COUNT) {
    const startCount = MAX_LABEL_WORD_COUNT / 3
    const endCount = (MAX_LABEL_WORD_COUNT * 2) / 3
    name = name.substring(0, startCount) + '…' + name.substring(name.length - endCount)
  }
  return name
}
class FilesUpload extends React.Component<any> {
  public state = {
    recipe_file: undefined,
    bank_name: '',
    subtype: '',
    isLoader: false,
    uploadExcelFile: [],
    error: '',
  }

  public render() {
    return (
      <main>
        <CustomCard>
          {this.displayDialog()}
          {this.DispalyUploadButton()}
        </CustomCard>
      </main>
    )
  }

  public DispalyUploadButton() {
    const { recipe_file, bank_name, subtype, uploadExcelFile } = this.state
    if (
      (uploadExcelFile.length > 0 && recipe_file) ||
      (uploadExcelFile.length > 0 && bank_name && subtype)
    ) {
      return (
        <Button
          appearance="primary"
          style={{ marginLeft: '35vw', marginTop: '6vh', padding: '0px 50px' }}
          onClick={this.onConfirmUpload}
          isLoading={this.state.isLoader}
        >
          Upload Files
        </Button>
      )
    } else {
      return null
    }
  }

  public displayDialog() {
    const { recipe_file } = this.state
    const recipeLabel = extractNameFromFile(recipe_file, 'config')
    return (
      <section>
        <Containers>
          <section className="upload-section">
            <span>Recipe</span>
            <input
              className="inputfile"
              type="file"
              id="recipe"
              name="recipe"
              accept=".xls, .xlsx"
              onChange={(e: any) => {
                this.setState({ recipe_file: e.target.files[0] })
              }}
            />
            <label className={this.props.theme.getButtonClassName()} htmlFor="recipe">
              {recipeLabel}
            </label>
          </section>
          <section className="upload-file-section">
            <span>Bank Name</span>
            <TextInput
              name="text-input-name"
              placeholder="Bank Name"
              className="input-field"
              onChange={(e: any) => this.handleChange(e, 'bank_name')}
            />
          </section>
          <section className="upload-file-section">
            <span>Subtype</span>
            <TextInput
              name="text-input-name"
              placeholder="Subtype"
              className="input-field"
              onChange={(e: any) => this.handleChange(e, 'subtype')}
            />
          </section>
        </Containers>
        <section style={{ width: '80%', margin: '20px 0 0 100px', cursor: 'pointer' }}>
          <FilePond
            labelIdle="Choose excel/Drag and Drop Excel *"
            allowMultiple={true}
            onupdatefiles={(fileItems: any) => {
              this.setState({
                uploadExcelFile: fileItems.map((fileItem: any) => fileItem.file),
              })
            }}
          />
        </section>
        <section style={{ textAlign: 'center' }}>
          <Paragraph size={300} marginLeft={13}>
            <b>NOTE:</b> Either <b>Recipe</b> or both <b>Bank Name</b> and <b>Subtype</b>
            need to be provided. If <b>Recipe</b> is provided, <b>Bank Name</b> and <b>Subtype</b>{' '}
            are ignored.
          </Paragraph>
        </section>
        {this.state.error && <StyledAlert>{this.state.error}</StyledAlert>}
      </section>
    )
  }

  private handleChange = (e: any, key: string) => {
    this.setState({ [key]: e.target.value.trim() })
  }

  private onConfirmUpload = async () => {
    const { recipe_file, bank_name, subtype, uploadExcelFile } = this.state
    this.setState({ isLoader: true, error: '' })
    canopySimulator(uploadExcelFile, recipe_file, bank_name, subtype)
      .then(async (res: any) => {
        const { canopy_ul_file, log } = res.body['data']
        await downloadFile(canopy_ul_file)
        navigate(`/canopysimulatorlog/`, { state: { log: log } })
      })
      .catch((e: any) => {
        const error =
          e.response && e.response.body && e.response.body.error
            ? e.response.body.error
            : 'Unknown Error'
        this.setState({ error: error, isLoader: false })
      })
  }
}

export default withTheme(FilesUpload)

const Containers = styled.li`
   {
    display: flex;
    align-items: center;
    text-overflow: ellipsis;
    list-style: none;
    padding-left: 5%;
    section {
      margin-right: 20px;
      span {
        margin-right: 10px;
      }
    }
    .upload-section {
      padding: 1rem;
    }
    .inputfile {
      width: 0.1px;
      height: 0.1px;
      opacity: 0;
      position: absolute;
      z-index: -1;
    }
    .inputfile + label {
      padding: 0.5rem;
      border-radius: 2px;
      width: 150px;
      overflow: hidden;
    }
    .inputfile + label {
      cursor: pointer;
    }
  }
`

const StyledAlert = styled.div`
  background-color: #fff2f0;
  border: 1px solid #ffccc7;
  margin: 20px 0;
  padding: 10px;
`
