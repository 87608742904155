import * as React from 'react'
import styled from 'styled-components'
import { colors } from '../../utils/colors'
import TitleCard from '../TitleCard/TitleCard'

const { Button, toaster } = require('evergreen-ui')

interface IProps {
  logs: string | null
  style?: React.CSSProperties
}

// @ts-ignore
const hasClipboardApi = navigator.clipboard

const toasterOptions = { duration: 2, id: 'unique-toaster' }

const Stacktrace: React.FunctionComponent<IProps> = ({ logs, style }: IProps) => {
  if (!logs) {
    return null
  }
  var stackTraceLog = logs.split('\n')
  return (
    <TitleCard
      title={'Stacktrace'}
      icon={'console'}
      style={style}
      actionComponent={
        <div>
          {hasClipboardApi && (
            <Button
              iconBefore={'clipboard'}
              appearance="minimal"
              onClick={() => {
                // @ts-ignore
                navigator.clipboard
                  .writeText(logs)
                  .then(() => toaster.success('Copied!', toasterOptions))
                  .catch(() => toaster.danger('Failed to Copy', toasterOptions))
              }}
            >
              Copy
            </Button>
          )}
        </div>
      }
    >
      <Container>
        {stackTraceLog.map((item: any, index: number) => {
          return (
            <div style={{ display: 'flex', width: '100%' }}>
              <div style={{ width: '40px' }}>{index + 1}.</div>
              <div style={{ lineHeight: 1.6 }}>{item}</div>
            </div>
          )
        })}
      </Container>
    </TitleCard>
  )
}

export default Stacktrace

const Container = styled.div`
  background-color: ${colors.darkBlack};
  overflow: auto;
  padding: 1rem;
  margin: -12px -24px -24px -24px;
  font-size: 0.9rem;
  line-height: 1.2rem;
  position: relative;
  z-index: 2;
  border-radius: 0 0 5px 5px;
  color: rgba(255, 255, 255, 0.9);
  font-family: monospace, monospace;
`
