import { navigate } from '@reach/router'
import { InMemoryCache } from 'apollo-cache-inmemory'
import ApolloClient from 'apollo-client'
import { ApolloLink } from 'apollo-link'
import { onError } from 'apollo-link-error'
import { HttpLink } from 'apollo-link-http'
// tslint:disable-next-line:no-implicit-dependencies
import { ServerError } from 'apollo-link-http-common'
import { GRAPHQL_BASE_URL } from '../api/api-constants'
import { getAuthorizationHeaders, logoutApi } from '../api/auth-api'
import { getApi } from './api/Api'
const { toaster } = require('evergreen-ui')
class ApolloClientGenerator {
  public generate() {
    return new ApolloClient({
      cache: new InMemoryCache(),
      link: ApolloLink.from([this.errorHandlerMiddleware(), this.getHttpLink()]),
      connectToDevTools: true,
    })
  }

  private getHttpLink() {
    return new HttpLink({
      uri: GRAPHQL_BASE_URL,
      fetch: async (uri: any, options: any) => {
        options.headers = options.headers || {};
        options.headers = {
          ...options.headers,
          ...await getAuthorizationHeaders()
        }
        return fetch(getApi(uri), options);
      }
    })
  }

  private errorHandlerMiddleware() {
    return onError(({ graphQLErrors, networkError}) => {
      if (networkError && (networkError as ServerError).statusCode !== 401) {
        if ((networkError as ServerError).statusCode !== 200) {
          if (graphQLErrors) {
            graphQLErrors.map(({ message, locations, path }) => {
              toaster.danger(message)
              console.log(
                `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
              )
            })
          }
        }
      } else if(networkError && (networkError as ServerError).statusCode === 401){
        logoutApi()
        toaster.danger('User is not a canopy employee')
      }else if (graphQLErrors) {
        graphQLErrors.map(({ message, locations, path }) => {
          toaster.danger(message)
        })
      } else {
        // AuthUtils.getInstance().clearLocalStorage()
        navigate('/login')
      }
    })
  }

}
export default ApolloClientGenerator
