import { Router } from '@reach/router'
import { Auth } from 'aws-amplify'
import * as React from 'react'
import { ApolloProvider } from 'react-apollo'
import { Helmet } from 'react-helmet'
import { CheckValidSession } from './api/auth-api'
import AuthRoutes from './components/AuthRoutes/AuthRoutes'
import CanopySimulator from './components/CanopySimulator/canopySimulator'
import CanopySimulatorLog from './components/CanopySimulator/CanopySimulatorLog'
import Statictics from './components/Statistics/statistics'
import ImageProcessing from './components/tkheader/imagePrecessing'
import FilesUpload from './components/UploadMultipleFile/FilesUpload'
import config, { authWithSocialButtons } from './config'
import BankData from './pages/BankData/BankData'
import BankSubtype from './pages/BankSubtype/BankSubtype'
import BulkDownload from './pages/BulkDownload/BulkDownload'
import BulkTaskCancel from './pages/BulkTaskCancellation/BulkTaskCancellation'
import BulkTaskRestart from './pages/BulkTaskRestart/BulkTaskRestart'
import BulkTaskConversionRestart from './pages/BulkTaskConversionRestart/BulkTaskConversionRestart'
import ClientData from './pages/ClientData/ClientData'
import FileDetail from './pages/FileDetail/FileDetail'
import FileHistoryItemPage from './pages/FileHistoryItemPage/FileHistoryItemPage'
import FileHistoryPage from './pages/FileHistoryPage/FileHistoryPage'
import FileSearch from './pages/FileSearch/FileSearch'
import FilesPage from './pages/FilesPage/FilesPage'
import LoginPage from './pages/LoginPage/LoginPage'
import NewConfig from './pages/NewConfig/NewConfig'
import TkHeadersConfig from './pages/NewConfig/TableConfig'
import StashboardJobId from './pages/SashboardJobId/StashboardJobId'
import StashboardParentId from './pages/StashboardParentUserId/StashboardParentUserId'
import StashboardTaskId from './pages/StashboardTaskId/StashboardTaskId'
import SubtypeData from './pages/SubtypeData/SubtypeData'
import ApolloClientGenerator from './utils/apollo-client-generator'
import ExistingConfigs from './pages/Configs/ExistingConfigs'
const CustomApolloProvider: any = ApolloProvider

const redirectTarget = `${window.location.protocol}//${window.location.host}/login/`


if (authWithSocialButtons) {
  // Configure Cognito Auth SDK Globally.
  Auth.configure({
    // Amazon Cognito Region
    region: config.aws.region,

    // Amazon Cognito User Pool ID
    userPoolId: config.aws.cognito.userPoolId,

    // Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: config.aws.cognito.clientId,

    oauth: {
      domain: config.aws.cognito.oauthDomain,
      scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
      redirectSignIn: redirectTarget,
      redirectSignOut: `${window.location.protocol}//${window.location.host}`,
      responseType: 'code',
    },
  })
}

function App() {
  if (window.performance) {
    if (performance.navigation.type == 1) {
      CheckValidSession()
    }
  }
  return (
    <>
      <Helmet>
        <title>Canopy Parser</title>
      </Helmet>
      <Router>
        <CustomApolloProvider
          client={new ApolloClientGenerator().generate()}
          default={true}
          shouldBatch={true}
        >
          <AuthRoutes path={'/'}>
            <LoginPage path={'/login'} />
            <FilesPage path={'/'} />
            <FileDetail path={'files/:fileId'} />
            <ClientData path={'search/client/:clientId'} />
            <FileSearch path={'search/fileName/:fileContains'} />
            <SubtypeData path={'search/subtype/:subtype'} />
            <BankSubtype path={'search/banksubtype/:bank_subtype'} />
            <StashboardTaskId path={'search/taskId/:taskId'} />
            <StashboardJobId path={'search/stashboardJobId/:stashboardJobId'} />
            <StashboardParentId path={'search/parentUserId/:parentUserId'} />
            <FileHistoryPage path={'files/:fileId/history'} />
            <FileHistoryItemPage path={'files/:fileId/history/:historyId'} />
            <BankData path={'search/bank/:bankCode'} />
            <FilesUpload path={'files/multipleupload'} />
            <Statictics path={'statistics'} />
            <ImageProcessing path={'tkheaders'} />
            <CanopySimulator path={'canopySimulator'} />
            <CanopySimulatorLog path={'canopysimulatorlog'} />
            <BulkTaskCancel path={'bulktaskcancellation'} />
            <BulkTaskRestart path={'bulktaskrestart'} />
            <BulkTaskConversionRestart path={'bulktaskconversionrestart'} />
            <BulkDownload path={'bulkdownload'} />
            <NewConfig path={'newconfig'} />
            <TkHeadersConfig path={'newtableconfig'} />
          </AuthRoutes>
        </CustomApolloProvider>
        <ExistingConfigs path={'bankconfigs'} />
      </Router>
    </>
  )
}

export default App
