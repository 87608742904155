import * as React from 'react'

const { Badge } = require('evergreen-ui')

interface IProps {
  subtype: number | null
}

const Subtype: React.FunctionComponent<IProps> = ({ subtype }: IProps) => (
  <Badge style={{ textTransform: 'capitalize' }}>
    <span style={{ fontWeight: 400 }}>Subtype:</span>{' '}
    <span style={{ textTransform: 'lowercase' }}>{subtype || 'n/a'}</span>
  </Badge>
)

export default Subtype
