import { getApi } from '../utils/api/Api'
import { cleanInputFile } from '../utils/FileUtils'
import { tkheaders } from './api-constants'
import { getAuthorizationHeaders } from './auth-api'

const axios = require('axios')

interface IFileInfo {
  fileBlob: Blob
  fileName: string
}

export const createBorderCore = async (borders: any, headers: any, input_pdf: File, pages: any,
  titles: any, params?: any, include_logs?:boolean): Promise<IFileInfo> => {
  var bodyFormData = new FormData()
  bodyFormData.append('borders', JSON.stringify(borders))
  bodyFormData.append('headers', JSON.stringify(headers))
  bodyFormData.append('pages', JSON.stringify(pages))
  bodyFormData.append('titles', JSON.stringify(titles))
  bodyFormData.append('params', JSON.stringify(params))
  bodyFormData.append('input_pdf', input_pdf as Blob)
  if (include_logs){
    bodyFormData.append('include_logs', JSON.stringify(include_logs))
  }
  return axios({
    method: 'post',
    url: getApi(tkheaders.tkheadersConfig),
    headers: {
      'Content-Type': 'multipart/form-data',
      ...await getAuthorizationHeaders()
    },
    data: bodyFormData,
    responseType: 'blob',
  })
    .then((res: any) => {
      var fileName = 'tkheaders'
      const file = decodeURI(res.headers['content-disposition'])
      const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
      const matches = filenameRegex.exec(file)
      if (matches != null && matches[1]) {
        fileName = matches[1].replace(/['"]/g, '')
      }
      const blob = new Blob([res.data], { type: res.headers['content-type'] })
      return { fileBlob: blob, fileName: fileName }
    })
    .catch((e: any) => {
      throw e
    })
}

export const createBorderDownload = async (borders: any, headers: any, input_pdf: File, pages: any,
  titles: any, params?: any, include_logs?:boolean): Promise<boolean> => {
  return createBorderCore(borders, headers, input_pdf, pages, titles, params, include_logs).then((fileInfo) => {
    const fileBlob = fileInfo.fileBlob
    const fileName = fileInfo.fileName
    const link = document.createElement('a')
    link.href = window.URL.createObjectURL(fileBlob)
    link.download = cleanInputFile(fileName)
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
    return true
  })
}


axios.interceptors.response.use(
  (response: any) => {
    return response
  },
  (error: any) => {
    if (
      error.request.responseType === 'blob' &&
      error.response.data instanceof Blob &&
      error.response.data.type &&
      error.response.data.type.toLowerCase().indexOf('json') != -1
    ) {
      return new Promise((resolve, reject) => {
        let reader = new FileReader()
        reader.onload = () => {
          error.response.data = reader.result
          resolve(Promise.reject(error))
        }

        reader.onerror = () => {
          reject(error)
        }

        reader.readAsText(error.response.data)
      })
    }

    return Promise.reject(error)
  },
)
