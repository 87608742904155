var path = require('path')
export const cleanInputFile = (inputFile: any) => {
  try {
    const filename = path.basename(inputFile)
    if (/^[A-Za-z]{7}_$/.test(filename.substring(0, 8))) {
      inputFile = path.join(path.dirname(inputFile), filename.substring(8))
    }
  } catch (e) {}
  return path.basename(inputFile)
}

export const extractNameFromFile = (file: any | undefined, type: 'pdf' | 'config'|'excel') => {
  if (!file) {
    if (type === 'pdf') {
      return 'Choose a PDF'
    }
    if (type === 'config') {
      return 'Choose a config'
    }
    if (type === 'excel') {
      return 'Choose a excel'
    } else {
      return 'error'
    }
  }

  let name = file.name
  const MAX_LABEL_WORD_COUNT = 20
  if (name.length > MAX_LABEL_WORD_COUNT) {
    const startCount = MAX_LABEL_WORD_COUNT / 3
    const endCount = (MAX_LABEL_WORD_COUNT * 2) / 3
    name = name.substring(0, startCount) + '…' + name.substring(name.length - endCount)
  }
  return name
}

export const downloadURI = (uri:string, name:string) =>  {
  var link: any = document.createElement('a')
  link.download = name
  link.href = uri
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

export const downloadBlob = (blob:Blob, name:string) =>  {
  var link: any = document.createElement('a')
  link.download = name
  link.href = window.URL.createObjectURL(blob)
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

export const convertBase64StringToBlob = (base64String:string, mimeType:string) =>  {
  const byteString = atob(base64String)
  const blobFuffer = new ArrayBuffer(byteString.length)
  const blobArray = new Uint8Array(blobFuffer)
  for (let i = 0; i < byteString.length; i += 1) {
    blobArray[i] = byteString.charCodeAt(i)
  }
  const blob = new Blob([blobFuffer], {
    type: mimeType,
  })
  return blob
}

export const FILE_NOT_AVAILABLE = "Not Available"