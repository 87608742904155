import * as React from 'react'
import gql from 'graphql-tag'
import { Query } from 'react-apollo'
import QueryError from '../../components/QueryError/QueryError'
const { Spinner } = require('evergreen-ui')
import styled from 'styled-components'

import BankPages from '../../components/Statistics/bankPage'

class GetBankDetails extends React.Component<any> {
  public render() {
    return (
      <Query query={GET_ALL_BANK}>
        {({ loading, error, data }) => {
          if (error) {
            return <QueryError error={error} />
          }
          if (loading) {
            return (
              <Loader>
                <Spinner marginX="auto" marginY={120} size={72} />
              </Loader>
            )
          }
          if (!data) {
            return (
              <div>
                <pre>{<code>{JSON.stringify(error, null, 2)}</code>}</pre>
              </div>
            )
          } else {
            const bankDetails = [...data['distinctBankCodes']]
            return <BankPages bankDetails={bankDetails} />
          }
        }}
      </Query>
    )
  }
}

const GET_ALL_BANK = gql`
  query {
    distinctBankCodes {
      bankCode
      count
      subtypeStats {
        subtype
        count
      }
    }
  }
`

const Loader = styled.div``

export default GetBankDetails
