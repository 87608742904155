import * as React from 'react'
const { Dialog, Button, Icon, toaster } = require('evergreen-ui')
import gql from 'graphql-tag'
import { Mutation } from 'react-apollo'

class CancelTask extends React.Component<any> {
  public state = {
    isShown: false,
    isLoading: false,
  }

  public onConfirmTask = (callMutation: any) => {
    this.setState({ isLoading: true })
    callMutation()
      .then((res: any) => {
        this.setState({
          isLoading: true,
          isShown: false,
        })
      })
      .catch((err: any) => {
        this.setState({
          isLoading: true,
          isShown: false,
        })
        toaster.danger('Something went wrong ')
      })
  }

  private generateArgumentsForCancelTAskJob = () => {
    const taskId = this.props.taskId

    return {
      taskId,
    }
  }

  public render() {
    return (
      <>
        <Mutation mutation={CancelTASKJOB} variables={this.generateArgumentsForCancelTAskJob()}>
          {(callCancelTaskJob: any) => (
            <>
              <Button onClick={() => this.setState({ isShown: true })}>
                <Icon icon="cross" />
              </Button>

              <Dialog
                isShown={this.state.isShown}
                title="Cancel TAsk"
                onCloseComplete={() =>
                  this.setState({
                    isShown: false,
                    isLoading: false,
                  })
                }
                isConfirmLoading={this.state.isLoading}
                onConfirm={() => this.onConfirmTask(callCancelTaskJob)}
                shouldCloseOnOverlayClick={false}
                confirmLabel={this.state.isLoading ? 'Loading...' : 'Yes'}
                cancelLabel="No"
              >
                <div style={{ fontSize: '20px' }}>Do you want to cancel this running task?</div>
              </Dialog>
            </>
          )}
        </Mutation>
      </>
    )
  }
}

const CancelTASKJOB = gql`
  mutation CancelMutation($taskId: String!) {
    cancelTask(taskId: $taskId) {
      success
      message
    }
  }
`

export default CancelTask
