import * as React from 'react'
import styled from 'styled-components'
import { colors } from '../../utils/colors'
const { Button, TextInput, Dialog, Paragraph, Text, Combobox, Autocomplete, Select } = require('evergreen-ui')
import { connect } from 'react-redux'
import ParserParameter from '../UploadMultipleFile/UploadParserParameter'
import { createBorderCore, createBorderDownload } from '../../api/tkheaders-config-api'
const { toaster } = require('evergreen-ui')
import { navigate } from '@reach/router'
import { onChangeCreateBorder } from './onChangeCreateBorder'
import { onTitleChange } from './onChangeTitle'
import { onDeleteTitle } from './onDeleteTitle'
import { bindActionCreators } from 'redux'
import { PreviewExtraction, IPropsDetail } from './PreviewExtraction'


function draw(ctx: any, location: any, height: number) {
  ctx.beginPath()
  ctx.moveTo(location.x, 0)
  ctx.lineTo(location.x, height)

  ctx.strokeStyle = 'red'
  ctx.stroke()
}


function usePersistentCanvas(image: any, height: number, width: number, setOfsetLeft: any, tkHeaderTable: any) {
  const url = image
  const canvasRef = React.createRef<HTMLCanvasElement>()
  React.useEffect(() => {
    const canvas = canvasRef.current as HTMLCanvasElement
    setOfsetLeft(canvas.offsetLeft + 30)
    let ctx: CanvasRenderingContext2D = canvas.getContext('2d')!
    ctx.clearRect(0, 0, 900, 1204)
    var img = new Image()
    img.src = `data:image/jpg;base64,${url}`
    img.onload = function () {
      ctx.drawImage(img, 0, 0)
      tkHeaderTable.forEach((location: any, index: number) => {
        draw(ctx, location, height);
      })
    }

  })
  return [canvasRef]
}

function NewTableConfig(props: any) {
  const { fileName } = props.state
  if (props.createBorderData.length > 0) {
    var { height, tkheaderImage, width } = props.createBorderData[0];
    const [isLoader, setLoader] = React.useState(false)
    const [isPreviewLoader, setPreviewLoader] = React.useState(false)
    const [tkHeadersTableRow, settkHeadersTableRow] = React.useState<any>([])
    const [tkHeaderTable, settkHeaderTable] = React.useState<any>([])
    const [ofsetLeft, setOfsetLeft] = React.useState(0)
    const [lineNumber, setLineNumber] = React.useState(-1)
    const [columnHeaderNumber, setcolumnHeaderNumber] = React.useState(0)
    const [isShown, setisShown] = React.useState(false)
    const [isShownTableModal, setIsShownTableModal] = React.useState(false)
    const [isShownDeleteModal, setisShownDeleteModal] = React.useState(false)
    const [isDeleteRow, setisDeleteRow] = React.useState(false)
    const [isError, setisError] = React.useState(false)
    const [title, setTitle] = React.useState('')
    const [deletetitle, setdeleteTitle] = React.useState('')
    const [selectTitle, setSelectTitle] = React.useState<any>([])
    const [imageHeight, setImageHight] = React.useState(height)
    const [imageWidth, setImageWidth] = React.useState(width)
    const [page, setpage] = React.useState(props.page[0])
    const [tkheaderConfigData, setTkheaderConfigData] = React.useState<any>([])
    const [image, setimage] = React.useState(tkheaderImage)
    const [isReload, setisReloade] = React.useState(false)
    const [canvasRef] = usePersistentCanvas(image, imageHeight, imageWidth, setOfsetLeft, tkHeaderTable)
    const [previewExtractionParams, setPreviewExtractionParams] = React.useState<IPropsDetail>({
      popupTitle: "NewConfig Preview",
      popupName: "NewConfigPreview",
      zipResponse: null,
      zipResponseTimestamp: (new Date()),
      onUnloadPopup: () => void 0,
      inputHeaders: null,
      configFilenameRegex: "config.xlsx",
      extractFilenameRegex: "output.xlsx",
      logFilenameRegex: "execution.log",
    })

    const handleCanvasClick = (e: any) => {
      e.preventDefault()
      const newLocation = { x: window.pageXOffset + e.clientX - ofsetLeft, y: 0 }
      const sottedArray = sortingArray([...tkHeaderTable, newLocation])
      settkHeaderTable([...sottedArray])
      onDataChange('tkHeadersCoordinates', page, sottedArray, title)
    }
    const handleClear = (e: any) => {
      e.preventDefault()
      settkHeaderTable([])
      setTkheaderConfigData([])
      onDataChange('tkHeadersCoordinates', page, [], title)
      onDataChange('tkheaderData', page, [], title)
    }

    const onDataChange = (type: string, page: string, data: any, tableTitle: any) => {
      props.onChangeCreateBorder(type, page, data, tableTitle);
    }

    const onTitleChange = ((e: any) => {
      setTitle(e.target.value.trim())
    })

    const onSelectDeletTable = (select: any) => {
      setdeleteTitle(select)
    }

    const getSelectedBorderData = () => {
      const { createBorderData: availableBorderDatas, page: availablePages } = props;
      // availablePages = 1  e.g.  when user selected a page in CreateNewBorder.tsx 
      // availablePages >= 1 (availablePages = all page numbers present in PDF file) e.g.  when user did not select any page in CreateNewBorder.tsx 
      const index = (availablePages.length > 1) ? (page - 1) : 0
      return availableBorderDatas[index]
    }

    const onConfirmDeleteTable = () => {
      if (title != deletetitle) {
        props.onDeleteTitle(page, deletetitle)
        setisShownDeleteModal(false)
        const selectedBorderData = getSelectedBorderData();
        const table = selectedBorderData['title'];
        settkHeaderTable(selectedBorderData['tkHeadersCoordinates'][title])
        settkHeadersTableRow(selectedBorderData['tkHeaderRow'][title])
        setTkheaderConfigData(selectedBorderData['tkheaderData'][title])
        setSelectTitle(table)

      } else {
        toaster.warning(
          'Selected table name is active in current page.'
        )
      }

    }

    const onSelectTable = (event: any) => {
      const selected = event.target.value
      setTitle(selected)
      const selectedBorderData = getSelectedBorderData();
      settkHeaderTable(selectedBorderData['tkHeadersCoordinates'][selected])
      settkHeadersTableRow(selectedBorderData['tkHeaderRow'][selected])
      setTkheaderConfigData(selectedBorderData['tkheaderData'][selected])
    }

    const onPageNumberChange = (selected: any) => {
      setpage(selected)
      setisReloade(false)
      const index = props.page.findIndex((item: any) => item == selected);
      const table = props.createBorderData[index]['title'];
      if (table.length > 0) {
        settkHeaderTable(props.createBorderData[index]['tkHeadersCoordinates'][table[0]])
        settkHeadersTableRow(props.createBorderData[index]['tkHeaderRow'][table[0]])
        setTkheaderConfigData(props.createBorderData[index]['tkheaderData'][table[0]])
        setimage(props.createBorderData[index]['tkheaderImage'])
        setImageHight(props.createBorderData[index]['height'])
        setImageWidth(props.createBorderData[index]['width'])
        setSelectTitle(table)
        setisReloade(true)
        setTitle(table[0])
      } else {
        settkHeaderTable([])
        settkHeadersTableRow([])
        setTkheaderConfigData([])
        setimage(props.createBorderData[index]['tkheaderImage'])
        setImageHight(props.createBorderData[index]['height'])
        setImageWidth(props.createBorderData[index]['width'])
        setSelectTitle(table)
      }
    }

    const handleTkHeaderDataChange = (e: any, rowNo: number, lineNo: number) => {
      const tempData: any = tkheaderConfigData
      var index = tempData.findIndex((item: any) => item.rowNo == rowNo && item.lineNo == lineNo);

      if (index == -1) {
        const temp = {
          'rowNo': rowNo,
          'lineNo': lineNo,
          'value': e.target.value
        }
        tempData.push(temp)
      } else {
        tempData[index]['value'] = e.target.value
      }
      setTkheaderConfigData([...tempData])
      onDataChange('tkheaderData', page, tempData, title)
    }

    const addRow = (e: any) => {
      e.preventDefault()
      const temp = {
        'tableRow': 'Column header',
      }
      const meta = {
        'tableRow': 'Meta header',
      }
      const data = [...tkHeadersTableRow]
      data.push(temp);
      data.push(meta)
      settkHeadersTableRow([...data])
      onDataChange('tkHeaderRow', page, data, title)

    }
    const handelLineChange = (e: any) => {
      e.preventDefault()
      setisError(false)
      const regex = /^[0-9]+$/
      if (regex.test(e.target.value)) {
        setLineNumber(e.target.value - 1)
      }
    }
    const handelColumnHeaderChange = (e: any) => {
      e.preventDefault()
      setisError(false)
      const regex = /^[0-9]+$/
      if (regex.test(e.target.value)) {
        const row = ((+ e.target.value) + (+ e.target.value))
        setcolumnHeaderNumber(row)
      }
    }

    const sortingArray = (sottedArray: any[]) => {
      sottedArray.sort((a: any, b: any) => {
        if (a['x'] > b['x']) {
          return 1
        } else if (a['x'] < b['x']) {
          return -1
        } else {
          return 0
        }
      })
      return sottedArray
    }
    const onConfirmDelete = () => {
      if (lineNumber >= 0 && lineNumber < tkHeaderTable.length) {
        const sottedArray = sortingArray([...tkHeaderTable])
        const item = sottedArray[lineNumber]
        const indexOf = tkHeaderTable.indexOf(item)
        const tempArray = [...tkHeaderTable]
        setisShown(false)
        tempArray.splice(indexOf, 1)
        settkHeaderTable(tempArray)
        onDataChange('tkHeadersCoordinates', page, tempArray, title)
      } else {
        setisError(true)
      }
    }
    const onConfirmDeleteColumnHeader = () => {
      if (columnHeaderNumber >= 4 && columnHeaderNumber < tkHeadersTableRow.length) {
        if (columnHeaderNumber % 2 == 0) {
          const tempArray = [...tkHeadersTableRow]
          setisShown(false)
          tempArray.splice(columnHeaderNumber, 2)
          const tempHeader = tkheaderConfigData.filter((item: any) => {
            return (item.rowNo != columnHeaderNumber && item.rowNo != columnHeaderNumber + 1)
          }).map((data: any) => {
            if (data.rowNo > columnHeaderNumber) {
              const temp = {
                lineNo: data.lineNo,
                rowNo: (data.rowNo - 2),
                value: data.value
              }
              return temp
            } else {
              return data
            }
          })
          setTkheaderConfigData([...tempHeader])
          onDataChange('tkheaderData', page, tempHeader, title)
          settkHeadersTableRow(tempArray)
          onDataChange('tkHeaderRow', page, tempArray, title)
          setisDeleteRow(false)
        } else {
          setisError(true)
        }

      } else {
        setisError(true)
      }
    }

    const oncloseAddTableModal = () => {
      setIsShownTableModal(false);
      setisReloade(true)
    }

    const onConfirmAddTable = () => {
      const tempTitle: any[] = [...selectTitle]
      tempTitle.push(title)
      setSelectTitle(tempTitle)
      props.onTitleChange(page, title)
      setIsShownTableModal(false)
      const selectedBorderData = getSelectedBorderData();
      settkHeadersTableRow(selectedBorderData['tkHeaderRow'][title])
      setTitle(title)
      settkHeaderTable(selectedBorderData['tkHeadersCoordinates'][title])
      settkHeadersTableRow(selectedBorderData['tkHeaderRow'][title])
      setTkheaderConfigData(selectedBorderData['tkheaderData'][title])
      setisReloade(true)
    }

    const returnParserParams = () => {
      var params: any[] = []
      var result: any = {}
      params = props.params
      for (var i = 0; i < params.length; i++) {
        if (params[i].type === 'str') {
          result[params[i].key] = params[i].value.trim()
        } else if (params[i].type === 'int' || params[i].type === 'float') {
          const temp = +params[i].value
          result[params[i].key] = temp
        } else {
          result[params[i].key] = params[i].value
        }
      }
      return result
    }

    const returnBorder = () => {
      let borders: any[] = []
      let pages: any[] = []
      let titles: any = []
      let headers: any = []
      props.createBorderData.forEach((element: any, index: any) => {
        if (Object.keys(element['tkHeadersCoordinates']).length > 0) {
          for (let title in element['tkHeadersCoordinates']) {
            pages.push(index + 1)
            titles.push(title)
            const titleArray = element['tkHeadersCoordinates'][title]
            let boundsArray: any[] = []
            titleArray.forEach((element: any) => {
              boundsArray.push(element['x'])
            });
            borders.push(boundsArray)
            const tkHeadersRow = element['tkHeaderRow'][title]
            const tkHeadersCoordinates = element['tkHeadersCoordinates'][title]
            const tkheaderData = element['tkheaderData'][title]
            let tkHeaderData: any[] = []
            for (let row = 2; row < tkHeadersRow.length; row++) {
              const tempTkHeader: any[] = []
              tkHeadersCoordinates.forEach((item: any) => {
                const temp = tkheaderData.filter((data: any) => {
                  return (data.rowNo == row && data.lineNo == item.x)
                })
                if (temp.length > 0) {
                  tempTkHeader.push(temp[0]['value'])
                } else {
                  tempTkHeader.push("")
                }
              });
              tkHeaderData.push(tempTkHeader)
            }
            headers.push(tkHeaderData)
          }
        }
      })
      if (pages.length === 1 && pages[0] !== page) {
        pages[0] = page
      }
      return { borders, pages, titles, headers }
    }


    const addTable = () => {
      setisReloade(false)
      setIsShownTableModal(true)
    }

    const deleteTable = () => {
      setisShownDeleteModal(true)
    }

    const onClickCreateBorder = () => {
      const { borders, pages, titles, headers } = returnBorder();
      const param = returnParserParams()
      if (fileName) {
        setLoader(true)
        createBorderDownload(borders, headers, fileName[0], pages, titles, param)
          .then(() => {
            navigate('/newconfig/');
            setLoader(false)
          }).catch((e: any) => {
            setLoader(false)
            const error =
              (e.response && e.response.data && typeof (e.response.data) == 'string') ? JSON.parse(e.response.data) : { 'error': 'Unable to download file' }
            toaster.danger(error['error'])
          })

      } else {
        !title ? toaster.danger("Please enter table name") : null
      }
    }

    const onClickPreviewParsing = () => {
      const { borders, pages, titles, headers } = returnBorder();
      const param = returnParserParams()
      if (fileName) {
        setPreviewLoader(true)
        createBorderCore(borders, headers, fileName[0], pages, titles, param, true)
          .then((data) => {
            if(previewExtractionParams.zipResponse){
              //close popup and reopen to make sure it is not hidden
              setPreviewExtractionParams(previousState => ({
                ...previousState,
                zipResponse: null
              }))
            }

            setPreviewExtractionParams(previousState => ({
              ...previousState,
              zipResponse: data.fileBlob,
              zipResponseTimestamp: (new Date()),
              onUnloadPopup: onUnloadPopup,
              inputHeaders: headers,
            }))
          }).catch((e: any) => {
            const error =
              (e.response && e.response.data && typeof (e.response.data) == 'string') ? JSON.parse(e.response.data) : { 'error': 'Unable to download file' }
            toaster.danger(error['error'])
          }).finally(() => {
            setPreviewLoader(false)
          })

      } else {
        !title ? toaster.danger("Please enter table name") : null
      }
    }

    const displayTable = () => {
      const length = tkHeaderTable.length
      var headerCount = 0
      var metaCount = 0
      return (
        <div style={{ marginTop: 25, marginBottom: 20 }}>
          <OuterContainer>
            <Description>
              {tkHeadersTableRow.map((item: any, index: number) => {
                if (item.tableRow != 'Column header' && item.tableRow != 'Meta header') {
                  return (
                    <span>
                      <Sign />
                      {item.tableRow}
                    </span>
                  )
                } else if (item.tableRow == 'Column header') {
                  headerCount++
                  return (
                    <span>
                      <Sign />
                      {item.tableRow}{headerCount}
                    </span>
                  )

                } else if (item.tableRow == 'Meta header') {
                  metaCount++
                  return (
                    <span>
                      <Sign />
                      {item.tableRow}{metaCount}
                    </span>
                  )

                }

              })}
            </Description>

            <Container
              style={{
                gridTemplateColumns: `repeat(${length},
                   minmax(70px,auto) 0px)`,
              }}
            >
              {tkHeadersTableRow.map((data: any, row: number) => {
                if (data.tableRow == 'Line start') {
                  return (
                    <>
                      {tkHeaderTable.map((data: any, index: number) => (
                        <>
                          <AlignRight>{index + 1}</AlignRight>
                          <Instances></Instances>
                        </>
                      ))}
                    </>
                  )
                }
                if (data.tableRow == 'Border Position') {
                  return (
                    <>
                      {tkHeaderTable.map((data: any) => (
                        <>
                          <AlignRight>{data.x}</AlignRight>
                          <Instances></Instances>
                        </>
                      ))}
                    </>
                  )
                }
                if (data.tableRow == 'Column header') {
                  return (
                    <>
                      {tkHeaderTable.map((data: any) => {
                        const temp = tkheaderConfigData.filter((item: any) => {
                          return (item.rowNo == row && item.lineNo == data.x)
                        })
                        var tempValue: any = ''
                        if (temp.length > 0) {
                          tempValue = temp[0]['value']
                        }
                        return (

                          <>
                            <AlignRight style={{ backgroundColor: 'white' }}>
                              <input type="text" value={tempValue}
                                onChange={(e: any) => handleTkHeaderDataChange(e, row, data.x)}></input>
                            </AlignRight>
                            <Instances style={{ backgroundColor: 'white' }}></Instances>
                          </>

                        )
                      })}
                    </>
                  )
                }
                if (data.tableRow == 'Meta header') {
                  return (
                    <>
                      {tkHeaderTable.map((data: any) => {
                        const temp = tkheaderConfigData.filter((item: any) => {
                          return (item.rowNo == row && item.lineNo == data.x)
                        })
                        var tempValue: any = ''
                        if (temp.length > 0) {
                          tempValue = temp[0]['value']
                        }
                        return (

                          <>
                            <AlignRight style={{ backgroundColor: 'white' }}>
                              <input type="text" value={tempValue}
                                onChange={(e: any) => handleTkHeaderDataChange(e, row, data.x)}></input>
                            </AlignRight>
                            <Instances style={{ backgroundColor: 'white' }}></Instances>
                          </>

                        )
                      })}
                    </>
                  )
                }
              })}
            </Container>
          </OuterContainer>
        </div>
      )
    }

    const deleteRowHeader = () => {
      return (
        <section>
          <Dialog
            isShown={isDeleteRow}
            title="Remove column header row"
            onConfirm={onConfirmDeleteColumnHeader}
            onCloseComplete={() => { setisDeleteRow(false); setisError(false) }}
            confirmLabel="Ok"
          >
            <section style={{ padding: '20px' }}>
              <Text size={500} marginRight={10} className="textContainer">Column Header Row No:</Text>
              <TextInput name="line" placeholder="Enter column header row no..." onChange={handelColumnHeaderChange} />
              {isError ? (
                <Paragraph color={'red'} size={200} marginTop="10px">
                  Please  Enter correct column header row number.
                </Paragraph>
              ) : null}
              <Paragraph size={100} marginTop="10px">
                <b style={{ marginRight: '10px' }}>Note:</b>Column header row only applies from row 2 onwards.
                The corresponding Meta header row will be removed as well.
              </Paragraph>
            </section>
          </Dialog>
        </section>
      )
    }

    const displayModal = () => {
      return (
        <Dialog
          isShown={isShownTableModal}
          title="Add Table"
          onCloseComplete={oncloseAddTableModal}
          onConfirm={onConfirmAddTable}
          confirmLabel="Add"
        >
          <section>
            <div className="flex-container">
              <Text size={500} marginRight={10} className="textContainer">Table Name:</Text>
              <TextInput
                name="text-input-name"
                value={title}
                onChange={onTitleChange}
                placeholder="Input table name *"
              />
            </div>
          </section>

        </Dialog>
      )
    }

    const deleteTableModal = () => {
      return (
        <Dialog
          isShown={isShownDeleteModal}
          title="Delete Table"
          onCloseComplete={() => setisShownDeleteModal(false)}
          onConfirm={onConfirmDeleteTable}
          confirmLabel="delete"
        >
          <div style={{ display: 'flex' }}>
            <Text size={500} marginRight={10} className="textContainer">Table:</Text>
            <Combobox
              openOnFocus
              items={selectTitle}
              onChange={onSelectDeletTable}
              placeholder="Table"
            />
          </div>
        </Dialog>
      )
    }


    const deleteByLine = () => {
      return (
        <section>
          <Dialog
            isShown={isShown}
            title="Delete by line"
            onConfirm={onConfirmDelete}
            onCloseComplete={() => setisShown(false)}
            confirmLabel="Ok"
          >
            <section style={{ padding: '20px' }}>
              <TextInput name="line" placeholder="Enter line no..." onChange={handelLineChange} />
              {isError ? (
                <Paragraph color={'red'} size={200} marginTop="10px">
                  Enter line number within Range{' '}
                </Paragraph>
              ) : null}
              <Paragraph size={100} marginTop="10px">
                <b>*</b> Lines start from 1
              </Paragraph>
              <Paragraph size={100} marginTop="10ox">
                <b>**</b> Enter line number less than {tkHeaderTable.length + 1}
              </Paragraph>
            </section>
          </Dialog>
        </section>
      )
    }
    const handleDelete = (e: any) => {
      e.preventDefault()
      setisShown(true)
    }

    const handleRowDelete = (e: any) => {
      e.preventDefault()
      setisDeleteRow(true)
    }

    const displayLineNo = () => {
      return (
        tkHeaderTable.map((item: any, index: number) => {
          const value = item.x - (index * 9)
          return (
            <div style={{ position: 'relative', left: `${value}px` }}>
              {index + 1}
            </div>
          )
        }))
    }

    const displayPopup = () => {
      if (previewExtractionParams && previewExtractionParams.zipResponse) {
        return (<PreviewExtraction params={previewExtractionParams} ></PreviewExtraction>)
      }
      else {
        return null
      }
    }

    const onUnloadPopup = () => {
      setPreviewExtractionParams(previousState => ({
        ...previousState,
        zipResponse: null,
        zipResponseTimestamp: null,
        inputHeaders: null,
      }))
    }

    const checkTableExist = () => {
      if (selectTitle.length > 0) {
        return (
          <React.Fragment>
            {displayTable()}
            <div style={{ display: 'flex' }}>
              <Button onClick={addRow} margin={16}>
                Add column header row
              </Button>
              <Button onClick={handleRowDelete} margin={16}>
                Remove column header row
              </Button>
              <Button onClick={handleClear} margin={16}>
                Delete All Borders
              </Button>
              <Button onClick={handleDelete} margin={16}>
                Delete By Line
              </Button>
              {deleteByLine()}
              {deleteRowHeader()}
              {displayModal()}
              {deleteTableModal()}
              <Button marginLeft={500} onClick={addTable} margin={16}>
                Add Table
              </Button>
              <Button marginLeft={500} onClick={deleteTable} margin={16} >
                Delete Table
              </Button>
              <Button marginLeft={500} onClick={onClickCreateBorder} margin={16} appearance="primary" isLoading={isLoader}>
                Create Table
              </Button>
              <Button marginLeft={500} onClick={onClickPreviewParsing} margin={16} appearance="primary" isLoading={isPreviewLoader}>
                Preview Config Parsing
              </Button>
            </div>
            <div style={{ marginBottom: '10px', marginTop: '20px', display: 'flex' }}>
              {displayLineNo()}
            </div>
            <canvas
              ref={canvasRef}
              width={imageWidth}
              height={imageHeight}
              onClick={handleCanvasClick}
              style={{ cursor: 'pointer' }}
            />
            {displayPopup()}

          </React.Fragment>
        )
      } else {
        return (
          <React.Fragment>
            <div>
              <Button marginLeft={500} onClick={addTable} margin={16}>
                Add Table
              </Button>
            </div>
            <canvas
              ref={canvasRef}
              width={imageWidth}
              height={imageHeight}
            />
            {displayModal()}
          </React.Fragment>
        )
      }
    }
    return (
      <React.Fragment>
        <CardHeader>
          <section>
            <div className="flex-container">
              <Text size={500} className="textContainer">FileName:</Text>
              <span>{fileName[0]['name']}</span>
            </div>
            <div className="flex-container">
              <Text size={500} className="textContainer">Page:</Text>
              <div className="autoComplete">
                <Autocomplete
                  title="Page"
                  popoverMinWidthnumber="150"
                  defaultSelectedItem={props.page[0]}
                  onChange={onPageNumberChange}
                  items={props.page}
                >
                  {(props: any) => {
                    const { getInputProps, getRef, inputValue, openMenu } = props
                    return (
                      <TextInput
                        placeholder="Page number"
                        style={{ width: '150px' }}
                        value={inputValue}
                        innerRef={getRef}
                        {...getInputProps({
                          onFocus: () => {
                            openMenu()
                          }
                        })}
                      />
                    )
                  }}
                </Autocomplete>
              </div>
              {(props.page.length > 1) && (<div style={{ marginTop: '5px' }}>/ {props.page.length}</div>)}
            </div>
            {isReload && (
              <div className="flex-container">
                <Text size={500} className="textContainer">Table:</Text>
                <div className="autoComplete">
                  <Select onChange={onSelectTable} defaultValue={title}>
                    {selectTitle.map((item: any) => (
                      <option value={item}>{item}</option>
                    ))}
                  </Select>
                </div>
              </div>
            )}

            <div className="flex-container">
              <Text size={500} className="textContainer">Params :</Text>
              <ParserParameter />
            </div>
            {checkTableExist()}
          </section>
        </CardHeader>
      </React.Fragment>
    )
  } else {
    return <span>Data  not avialble</span>
  }
}

const mapStateToProps = (storeData: any) => {
  return {
    params: storeData.onChangeReducer.params,
    createBorderData: storeData.createNewBorder.createBorderData,
    page: storeData.createNewBorder.page,
    title: storeData.createNewBorder.title
  }
}

const mapDispatch = (dispatch: any) => {
  return bindActionCreators(
    {
      onChangeCreateBorder: onChangeCreateBorder,
      onTitleChange: onTitleChange,
      onDeleteTitle: onDeleteTitle
    },
    dispatch,
  )
}

export default connect(mapStateToProps, mapDispatch)(NewTableConfig)



const CardHeader = styled.section`
  width: 90%;
  margin: 20px auto;
  .flex-container{
    display: flex;
    margin: 15px;
    .textContainer{
      width: 15%;
      font-weight: bold;
      color: black;
    }
    .autoComplete{
      width: 155px
    }
  }
`
const OuterContainer = styled.div`
  display: flex;
`


const Description = styled.div`
  display: grid;
  grid-template-columns: 180px;
  font-weight: bold;
`
const Sign = styled.span`
  display: inline-block;
  color: ${colors.black};
  width: 16px;
`

const Container = styled.div`
  display: grid;
  border: 1px solid ${colors.black};
  border-left: none;
  border-radius: 4px;
  overflow-y: hidden;
  overflow-x: scroll;
`
const Instances = styled.span`
  text-align: right;
  display: inline-block;
  border-bottom: 1px solid ${colors.black};
  padding: 6px 0 6px 0;
`
const AlignRight = styled.span`
  text-align: center;
  display: block;
  border-left: 1px solid ${colors.black};
  border-bottom: 1px solid ${colors.black};
  background-color: #E0E0E0;
  padding: 6px 0 6px 0;
  input {
    border: none; 
    background: none;
    margin-left: 10px;
    margin-right: 0;
    
  }
  input[type=text]:focus {
    border: none;
    outline: 0 none;
}
`

